import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { environment } from '@env/environment';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { StateStorageService } from './state-storage.service';

@Injectable({ providedIn: 'root' })
export class UserRouteAccessService implements CanActivate {
  constructor(private authService: AuthService, private stateStorageService: StateStorageService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.checkLogin(state.url);
  }

  checkLogin(url: string): Observable<boolean> {
    if (!environment.OAUTH_GUARD) {
      return of(true);
    }

    this.stateStorageService.storeStateUrl(url);

    return this.authService.identity(true).pipe(
      map(authStatus => {
        if (authStatus?.status === 'AUTHENTICATED') {
          return true;
        }

        this.authService.routeToLoginPage();
        return false;
      })
    );
  }
}
