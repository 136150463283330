// Custom icon static resources

import {
  AlertTwoTone,
  BackwardOutline,
  BulbOutline,
  CheckSquareOutline,
  ClearOutline,
  CloudUploadOutline,
  ExceptionOutline,
  FilterOutline,
  ForwardOutline,
  FundViewOutline,
  ImportOutline,
  InfoOutline,
  LinkOutline,
  MedicineBoxFill,
  MedicineBoxOutline,
  MinusSquareOutline,
  MoreOutline,
  NotificationOutline,
  PlusCircleOutline,
  PlusSquareOutline,
  PrinterOutline,
  ProfileOutline,
  ProjectOutline,
  ReloadOutline,
  SelectOutline,
  VideoCameraFill,
  VideoCameraOutline,
  ZoomInOutline,
  ZoomOutOutline,
  IdcardOutline,
  KeyOutline
} from '@ant-design/icons-angular/icons';

export const ICONS = [
  AlertTwoTone,
  BackwardOutline,
  BulbOutline,
  CheckSquareOutline,
  ClearOutline,
  CloudUploadOutline,
  ExceptionOutline,
  FilterOutline,
  ForwardOutline,
  FundViewOutline,
  ImportOutline,
  InfoOutline,
  LinkOutline,
  MedicineBoxFill,
  MedicineBoxOutline,
  MinusSquareOutline,
  MoreOutline,
  NotificationOutline,
  PlusCircleOutline,
  PlusSquareOutline,
  PrinterOutline,
  ProfileOutline,
  ProjectOutline,
  ReloadOutline,
  SelectOutline,
  VideoCameraFill,
  VideoCameraOutline,
  ZoomInOutline,
  ZoomOutOutline,
  IdcardOutline,
  KeyOutline
];
