import { Component, ElementRef, OnInit, Renderer2, ViewChild, ViewContainerRef } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { VERSION as VERSION_ALAIN } from '@delon/theme';
import { AuthService } from '@oauth';
import { UserIdleService } from 'angular-user-idle';
import { AuthenticationDetail } from 'build/api/api-gateway';
import { NzModalService } from 'ng-zorro-antd/modal';
import { VERSION as VERSION_ZORRO } from 'ng-zorro-antd/version';
import { filter } from 'rxjs/operators';
import { MSUserComponent } from './layout/ms/_widgets/user/user.component';
import { ScrollbarService } from './shared/components/scrollbar/scrollbar.service';
import { Biz360SessionExpiredDialogComponent } from './shared/components/session-expired-dialog';

@Component({
  selector: 'app-root',
  template: ` <router-outlet></router-outlet> `
})
export class AppComponent implements OnInit {
  @ViewChild(MSUserComponent) userComponent!: MSUserComponent;

  account!: AuthenticationDetail;

  constructor(
    el: ElementRef,
    renderer: Renderer2,
    private router: Router,
    private modalSrv: NzModalService,
    private authService: AuthService,
    private userIdleService: UserIdleService,
    private viewContainerRef: ViewContainerRef,
    private scrollbarService: ScrollbarService
  ) {
    renderer.setAttribute(el.nativeElement, 'ng-alain-version', VERSION_ALAIN.full);
    renderer.setAttribute(el.nativeElement, 'ng-zorro-version', VERSION_ZORRO.full);
  }

  ngOnInit(): void {
    this.router.events.pipe(filter(evt => evt instanceof NavigationEnd)).subscribe(() => {
      this.modalSrv.closeAll();
    });

    this.userIdleService.onTimerStart().subscribe();
    this.userIdleService.onTimeout().subscribe(() => {
      console.log('Time is up!');
      this.openSessionTimeoutDialog();
    });

    this.authService.getAuthenticationState().subscribe(authState => {
      this.account = authState;
      this.userIdleService.startWatching();
    });
  }

  openSessionTimeoutDialog(): void {
    this.modalSrv.create({
      nzTitle: 'Session Timeout Warning',
      nzContent: Biz360SessionExpiredDialogComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzComponentParams: null!,
      nzMaskClosable: false,
      nzClosable: false,
      nzOnOk: () => new Promise(resolve => setTimeout(resolve, 1000)),
      nzFooter: [
        {
          label: 'Signout',
          onClick: componentInstance => {
            this.signout();
          }
        },
        {
          label: 'Continue Session',
          onClick: componentInstance => {
            componentInstance?.continueSession();
          }
        }
      ]
    });
  }

  signout(): void {
    this.destroyModal();
    this.authService.BroadcastEvent('raiseLogoutEvent', {});
  }

  stop(): void {
    this.userIdleService.stopTimer();
  }

  stopWatching(): void {
    this.userIdleService.stopWatching();
  }

  startWatching(): void {
    this.userIdleService.startWatching();
  }

  restart(): void {
    this.userIdleService.resetTimer();
  }

  destroyModal(): void {
    this.modalSrv.closeAll();
  }
}
