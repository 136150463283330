import { Biz360Utils } from '../utils/biz360';

export class Attachment {
  name?: string;
  url?: string;
  modalType?: number;
}

export class Announcement extends Attachment {
  id: string;
  issuedDate: Date;
  title: string;
  body: string;
  attachments?: Attachment[];
  year?: number;
  month?: number;
  day?: number;
  time?: number;
  tags?: string[];
  active?: boolean;

  constructor(announcement?: any) {
    super();
    announcement = announcement || {};
    this.id = announcement.id || Biz360Utils.generateGUID();
    this.issuedDate = announcement.issuedDate || '';
    this.title = announcement.name || '';
    this.body = announcement.body || '';
    this.attachments = announcement.attachment || [];
    this.year = announcement.year || null;
    this.month = announcement.month || null;
    this.time = announcement.time || null;
    this.tags = announcement.tags || [];
    this.active = announcement.active || false;
  }
}
