import { NgModule } from '@angular/core';

import { SharedModule } from '@shared';
// single pages
import { CallbackComponent } from './callback/callback.component';
import { RouteRoutingModule } from './routes-routing.module';

const COMPONENTS = [
  // single pages
  CallbackComponent
];
const COMPONENTS_NOROUNT: any[] = [];

@NgModule({
  imports: [SharedModule, RouteRoutingModule],
  declarations: [...COMPONENTS, ...COMPONENTS_NOROUNT],
  entryComponents: COMPONENTS_NOROUNT
})
export class RoutesModule {}
