import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UserService } from '@core';
import { SettingsService } from '@delon/theme';
import { AuthService } from '@oauth';
import { AuthenticationDetail } from 'build/api/api-gateway';
import { Subject, Subscription } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { MSTopbarService } from '../../services/topbar.service';

@Component({
  selector: 'ms-user',
  templateUrl: './user.component.html',
  host: {
    '[class.alain-ms__topbar-item]': 'true',
    '[class.alain-ms__topbar-dd]': 'true',
    '[class.alain-ms__user]': 'true'
  },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MSUserComponent implements OnInit, OnDestroy {
  @ViewChild('logoutForm') logoutForm: any;

  private _l: any;

  mainLinks: any[] = [];
  subLinks: any[] = [];
  account?: AuthenticationDetail;
  signOutUrl?: string;
  // signOutUrlCsrf?: string;
  loginPageUrl?: string;
  xsrfToken: any;
  private unsubscribe$: Subject<any> = new Subject();
  eventSubscriber?: Subscription;

  @Input()
  set l(res: any) {
    this._l = res;
    this.mainLinks = (res.links as any[]).slice(0, 3);
    this.subLinks = (res.links as any[]).slice(3);
  }
  get l(): any {
    return this._l;
  }

  constructor(srv: MSTopbarService, public settings: SettingsService, public userSrv: UserService, private authService: AuthService) {
    this.l = srv.getNav('user');
  }

  ngOnInit(): void {
    this.loginPageUrl = this.authService.loginPageUrl();
    this.signOutUrl = this.authService.getAuthStatus()?.signOutUrl;
    this.authService.getAuthenticationState().pipe(
      takeUntil(this.unsubscribe$),
      map(account => (this.account = account))
    );
    this.account = this.authService.getUserIdentity();
    this.authService
      .getAuthenticationState()
      .pipe(
        takeUntil(this.unsubscribe$),
        map(account => (this.account = account))
      )
      .subscribe();

    if (!this.account) {
      this.account = this.authService.getUserIdentity();
    }

    this.xsrfToken = this.authService.getCookie('XSRF-TOKEN');

    this.authService
      .GetEvent('raiseLogoutEvent')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.logout();
      });
  }

  isAuthenticated(): boolean {
    return this.authService.isAuthenticated();
  }

  logout(): void {
    this.authService
      .identity(true)
      .pipe(
        takeUntil(this.unsubscribe$),
        map(authStatus => {
          console.log('authStatus', authStatus);
          if (authStatus?.status === 'AUTHENTICATED') {
            this.userSrv.logout();
            this.logoutForm.nativeElement.submit();
          }
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
