/* eslint-disable import/no-duplicates */
import { registerLocaleData } from '@angular/common';
import { HttpClientModule, HttpClientXsrfModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { default as ngLang } from '@angular/common/locales/en';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { I18NService, DefaultInterceptor, StartupService } from '@core';
import { SimpleInterceptor } from '@delon/auth';
import { DELON_LOCALE, en_US as delonLang, ALAIN_I18N_TOKEN } from '@delon/theme';
import { environment } from '@env/environment';
import { JsonSchemaModule } from '@shared';
import { UserIdleModule } from 'angular-user-idle';
import { ApiModule, Configuration, ConfigurationParameters } from 'build/api/api-gateway';
import { enUS as dateLang } from 'date-fns/locale';
import { NzSafeAny } from 'ng-zorro-antd/core/types';

// #region default language
// 参考：https://ng-alain.com/docs/i18n
// import { default as ngLang } from '@angular/common/locales/es';
// import { DELON_LOCALE, zh_CN as delonLang } from '@delon/theme';
// import { zhCN as dateLang } from 'date-fns/locale';
// import { NZ_DATE_LOCALE, NZ_I18N, zh_CN as zorroLang } from 'ng-zorro-antd/i18n';
// const LANG = {
//   abbr: 'zh',
//   ng: ngLang,
//   zorro: zorroLang,
//   date: dateLang,
//   delon: delonLang,
// };
import { en_US as zorroLang, NZ_DATE_LOCALE, NZ_I18N } from 'ng-zorro-antd/i18n';
const LANG = {
  abbr: 'en',
  ng: ngLang,
  zorro: zorroLang,
  date: dateLang,
  delon: delonLang
};

// register angular
registerLocaleData(LANG.ng, LANG.abbr);
const LANG_PROVIDES = [
  { provide: LOCALE_ID, useValue: LANG.abbr },
  { provide: NZ_I18N, useValue: LANG.zorro },
  { provide: NZ_DATE_LOCALE, useValue: LANG.date },
  { provide: DELON_LOCALE, useValue: LANG.delon }
];
// #endregion

// #region i18n services

const I18NSERVICE_PROVIDES = [{ provide: ALAIN_I18N_TOKEN, useClass: I18NService, multi: false }];

// #endregion

// #region global third module
const GLOBAL_THIRD_MODULES: NzSafeAny = [];

// #endregion

// #region JSON Schema form (using @delon/form)
const FORM_MODULES = [JsonSchemaModule];
// #endregion

// #region Http Interceptors

const INTERCEPTOR_PROVIDES: NzSafeAny = [
  // { provide: HTTP_INTERCEPTORS, useClass: SimpleInterceptor, multi: true },
  // { provide: HTTP_INTERCEPTORS, useClass: DefaultInterceptor, multi: true },
];
// #endregion

// #region Startup Service
export function StartupServiceFactory(startupService: StartupService): any {
  return () => startupService.load();
}
const APPINIT_PROVIDES = [
  StartupService,
  {
    provide: APP_INITIALIZER,
    useFactory: StartupServiceFactory,
    deps: [StartupService],
    multi: true
  }
];
// #endregion

import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { GlobalConfigModule } from './global-config.module';
import { LayoutModule } from './layout/layout.module';
import { OAuthModule } from './oauth/oauth.module';
import { RoutesModule } from './routes/routes.module';
import { SharedModule } from './shared/shared.module';
import { STWidgetModule } from './shared/st-widget/st-widget.module';

export function apiConfigFactory(service: string): () => Configuration {
  return () => {
    const path = service ? `${environment.API_BASE_PATH.replace('https:', '')}/${service}` : `${environment.API_BASE_PATH}`;
    const params: ConfigurationParameters = {
      basePath: path
    };
    // console.log('fsdfdsfdsfdsfdsfdsfds', params);

    return new Configuration(params);
  };
}
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpClientXsrfModule,
    GlobalConfigModule.forRoot(),
    CoreModule,
    SharedModule,
    OAuthModule,
    UserIdleModule.forRoot({
      idle: environment.USER_IDLE_SECONDS,
      timeout: environment.USER_TIMEOUT_SECONDS,
      ping: environment.USER_ACTIVITY_PING_SECONDS
    }),
    LayoutModule,
    RoutesModule,
    STWidgetModule,
    ...GLOBAL_THIRD_MODULES,
    ...FORM_MODULES,
    ApiModule.forRoot(apiConfigFactory(environment.SERVICE_NAME.API_GATEWAY))
  ],
  providers: [...LANG_PROVIDES, ...INTERCEPTOR_PROVIDES, ...I18NSERVICE_PROVIDES, ...APPINIT_PROVIDES],
  bootstrap: [AppComponent]
})
export class AppModule {}
