import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, Output } from '@angular/core';
import { InputBoolean, InputNumber } from '@delon/util';
import { NzButtonType } from 'ng-zorro-antd/button';
import { NzSizeLDSType } from 'ng-zorro-antd/core/types';

@Component({
  selector: 'captcha-btn',
  templateUrl: './captcha-btn.component.html',
  preserveWhitespaces: false,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CaptchaBtnComponent implements OnChanges, OnDestroy {
  private interval$: any;
  count = 0;

  @Input() @InputNumber() during = 60;

  @Input() type!: NzButtonType;

  @Input() size!: NzSizeLDSType;

  @Input() @InputBoolean() block = false;

  @Input() @InputBoolean() disabled = false;

  @Output() readonly send = new EventEmitter();

  constructor(private cdr: ChangeDetectorRef) {}

  _click(): void {
    this.count = +this.during;
    this.interval$ = setInterval(() => {
      this.count -= 1;
      if (this.count <= 0) {
        clearInterval(this.interval$);
      }
      this.cdr.detectChanges();
    }, 1000);

    this.send.emit();
  }

  ngOnChanges(): void {
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    if (this.interval$) {
      clearInterval(this.interval$);
    }
  }
}
