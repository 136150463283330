import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { Biz360SessionExpiredDialogComponent } from './session-expired-dialog.component';

@NgModule({
  imports: [CommonModule],
  declarations: [Biz360SessionExpiredDialogComponent],
  exports: [Biz360SessionExpiredDialogComponent]
})
export class Biz360SessionExpiredDialogModule {}
