import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

//#region Zorro modules
import { DelonFormModule } from '@delon/form';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
//#endregion

//#region Delone modules
//#endregion

//#region components
import { Biz360FilterSearchComponent } from './filter-search.component';
//#endregion

const THEMES_MODULES = [
  NzSelectModule,
  NzInputModule,
  NzButtonModule,
  NzToolTipModule,
  NzGridModule,
  NzInputNumberModule,
  NzDatePickerModule,
  NzTimePickerModule,
  NzIconModule,

  DelonFormModule
];

const COMPONENTS = [Biz360FilterSearchComponent];

@NgModule({
  imports: [CommonModule, FormsModule, ...THEMES_MODULES],
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS],
  providers: []
})
export class Biz360FilterSearchModule {}
