import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { ControlWidget, SFSchemaEnum } from '@delon/form';
import { EMPTY, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'sf-custom-tag',
  templateUrl: 'custom-tag.widget.html',
  preserveWhitespaces: false,
  changeDetection: ChangeDetectionStrategy.OnPush
})
// tslint:disable-next-line: component-class-suffix
export class CustomTagWidget extends ControlWidget implements OnInit, OnDestroy {
  static readonly KEY = 'custom-tag';

  inputValue: any;
  tagList: any[] = [];

  private subscription: Subscription[] = [];

  asyncData = () => (this.ui?.asyncData ? this.ui.asyncData() : EMPTY);

  ngOnInit(): void {
    if (this.formProperty?.formData) {
      this.inputValue = this.formProperty?.formData;
    }
    // console.log(this.inputValue, this.formProperty);

    this.subscription.push(
      this.asyncData()
        .pipe(
          tap((res: SFSchemaEnum[]) => {
            this.tagList = res as any[];

            this.detectChanges(true);
            // console.log(res);
          })
        )
        .subscribe()
    );
  }

  ngOnDestroy(): void {
    this.subscription.forEach(sub => sub.unsubscribe());
  }

  addTag(event?: any): void {
    if (this.inputValue) {
      const tagToAdd = this.inputValue;
      this.inputValue = null;
      this.tagList.push(tagToAdd);
      this.change(this.tagList);
    }
  }

  change(value: any): void {
    if (this.ui?.change) {
      this.ui.change(value);
    }
    this.setValue(value);
  }

  tagClose(event: string): void {
    const index = this.tagList.indexOf(event);
    console.log(event, index);

    if (index !== -1) {
      this.tagList.splice(index, 1);
    }

    this.change(this.tagList);
  }
}
