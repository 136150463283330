import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DelonFormModule } from '@delon/form';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { ScrollbarModule } from '../scrollbar';

import { Biz360SFModalComponent } from './sf-modal.component';

@NgModule({
  imports: [CommonModule, DelonFormModule, ScrollbarModule, NzIconModule, NzButtonModule, NzGridModule],
  declarations: [Biz360SFModalComponent],
  exports: [Biz360SFModalComponent],
  providers: []
})
export class Biz360SFModalModule {}
