export class Policy {
  id!: string;
  title!: string;
  subTitle?: string;
  scope!: { id: string; name: string };
  category!: { key: string; value: string };
  content?: { html?: string; attachments: Array<{ name: string; type: string; path: string }> };
  updatedOn!: Date;
}

export class Scope {
  id!: string;
  name!: string;
}

export class ScopeCategory {
  key!: string;
  value!: string;
}

export class Category {
  id!: string;
  scopeCategories!: ScopeCategory[];
}
