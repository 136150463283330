import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { STModule } from '@delon/abc/st';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { CustomColumnModalComponent } from './custom-column-modal.component';
import { CustomColumnComponent } from './custom-column.component';

const COMPONENTS = [CustomColumnComponent, CustomColumnModalComponent];

@NgModule({
  imports: [CommonModule, FormsModule, NzIconModule, NzCheckboxModule, NzModalModule, STModule, NzButtonModule, NzGridModule],
  declarations: COMPONENTS,
  exports: COMPONENTS
})
export class CustomColumnModule {}
