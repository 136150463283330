import * as moment from 'moment';

export const CLINICS = [
  {
    id: '5725a680b3249760ea21de52',
    clinicName: 'Royalguard',
    address: '933 8th Street Stamford, CT 06902',
    partner: false,
    contactNo: '+6561053456',
    postalCode: '500600',
    createdAt: moment('2019-01-30', 'YYYY-MM-DD').toDate(),
    updatedAt: moment('2019-01-30', 'YYYY-MM-DD').toDate(),
  },
  {
    id: '5725a680606588342058356d',
    clinicName: 'Wanderer',
    address: '906 Valley Road Michigan City, IN 46360',
    partner: true,
    contactNo: '+6561053456',
    postalCode: '500600',
    createdAt: moment('2019-02-13', 'YYYY-MM-DD').toDate(),
    updatedAt: moment('2019-02-13', 'YYYY-MM-DD').toDate(),
  },
  {
    id: '5725a68009e20d0a9e9acf2a',
    clinicName: 'Jackal',
    address: '183 River Street Passaic, NJ 07055',
    partner: true,
    contactNo: '+6561053456',
    postalCode: '500600',
    createdAt: moment('2019-03-03', 'YYYY-MM-DD').toDate(),
    updatedAt: moment('2019-03-03', 'YYYY-MM-DD').toDate(),
  },
  {
    id: '5725a6809fdd915739187ed5',
    clinicName: 'Knight',
    address: '143 Jones Street Eau Claire, WI 54701',
    partner: true,
    contactNo: '+6561053456',
    postalCode: '500600',
    createdAt: moment('2019-04-30', 'YYYY-MM-DD').toDate(),
    updatedAt: moment('2019-04-30', 'YYYY-MM-DD').toDate(),
  },
  {
    id: '5725a68007920cf75051da64',
    clinicName: 'Jester 1',
    address: '218 Pearl Street Brandon, FL 33510',
    partner: true,
    contactNo: '+6561053456',
    postalCode: '500600',
    createdAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
    updatedAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  },
  {
    id: '5725a68031fdbb1db2c1af47',
    clinicName: 'Jester 2',
    address: '329 Bridge Street Desoto, TX 75115',
    partner: true,
    contactNo: '+6561053456',
    postalCode: '500600',
    createdAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
    updatedAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  },
  {
    id: '5725a680bc670af746c435e2',
    clinicName: 'Jester 3',
    address: '956 6th Avenue North Bergen, NJ 0704',
    partner: true,
    contactNo: '+6561053456',
    postalCode: '500600',
    createdAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
    updatedAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  },
  {
    id: '5725a680e7eb988a58ddf303',
    clinicName: 'Jester 4',
    address: '664 York Street Cambridge, MA 02138',
    partner: false,
    contactNo: '+6561053456',
    postalCode: '500600',
    createdAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
    updatedAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  },
  {
    id: '5725a680dcb077889f758961',
    clinicName: 'Jester 5',
    address: '738 Route 11 Cornelius, NC 28031',
    partner: true,
    contactNo: '+6561053456',
    postalCode: '500600',
    createdAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
    updatedAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  },
  {
    id: '5725a6806acf030f9341e925',
    clinicName: 'Jester 6',
    address: '194 Washington Avenue Saint Petersburg, FL 33702',
    partner: true,
    contactNo: '+6561053456',
    postalCode: '500600',
    createdAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
    updatedAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  },
  {
    id: '5725a680ae1ae9a3c960d487',
    clinicName: 'Jester 7',
    address: '686 Roosevelt Avenue Oviedo, FL 32765',
    partner: true,
    contactNo: '+6561053456',
    postalCode: '500600',
    createdAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
    updatedAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  },
  {
    id: '5725a680b8d240c011dd224b',
    clinicName: 'Jester 8',
    address: '202 Hartford Road Lynchburg, VA 24502',
    partner: true,
    contactNo: '+6561053456',
    postalCode: '500600',
    createdAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
    updatedAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  },
  {
    id: '5725a68034cb3968e1f79eac',
    clinicName: 'Jester 9',
    address: '219 Woodland Road Valrico, FL 33594',
    partner: false,
    contactNo: '+6561053456',
    postalCode: '500600',
    createdAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
    updatedAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  },
  {
    id: '5725a6801146cce777df2a08',
    clinicName: 'Jester 10',
    address: '305 Willow Drive Superior, WI 54880',
    partner: true,
    contactNo: '+6561053456',
    postalCode: '500600',
    createdAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
    updatedAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  },
  // {
  //   id: '5725a6808a178bfd034d6ecf',
  //   clinicName: 'Jester',
  //   address: '148 Heather Lane Mcminnville, TN 37110',
  //   partner: false,
  //   contactNo: '+6561053456',
  //   postalCode: '500600',
  //   createdAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  //   updatedAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  // },
  // {
  //   id: '5725a680653c265f5c79b5a9',
  //   clinicName: 'Jester',
  //   address: '345 Laurel Lane Union City, NJ 07087',
  //   partner: true,
  //   contactNo: '+6561053456',
  //   postalCode: '500600',
  //   createdAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  //   updatedAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  // },
  // {
  //   id: '5725a680bbcec3cc32a8488a',
  //   clinicName: 'Jester',
  //   address: '572 Rose Street Summerfield, FL 34491',
  //   partner: true,
  //   contactNo: '+6561053456',
  //   postalCode: '500600',
  //   createdAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  //   updatedAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  // },
  // {
  //   id: '5725a6803d87f1b77e17b62b',
  //   clinicName: 'Jester',
  //   address: '527 Jefferson Court Conyers, GA 30012',
  //   partner: false,
  //   contactNo: '+6561053456',
  //   postalCode: '500600',
  //   createdAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  //   updatedAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  // },
  // {
  //   id: '5725a680e87cb319bd9bd673',
  //   clinicName: 'Jester',
  //   address: '297 Strawberry Lane Faribault, MN 55021',
  //   partner: true,
  //   contactNo: '+6561053456',
  //   postalCode: '500600',
  //   createdAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  //   updatedAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  // },
  // {
  //   id: '5725a6802d10e277a0f35775',
  //   clinicName: 'Jester',
  //   address: '928 Canterbury Court Pittsburgh, PA 15206',
  //   partner: true,
  //   contactNo: '+6561053456',
  //   postalCode: '500600',
  //   createdAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  //   updatedAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  // },
  // {
  //   id: '5725a680aef1e5cf26dd3d1f',
  //   clinicName: 'Jester',
  //   address: '904 Ridge Road Pickerington, OH 43147',
  //   partner: true,
  //   contactNo: '+6561053456',
  //   postalCode: '500600',
  //   createdAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  //   updatedAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  // },
  // {
  //   id: '5725a680cd7efa56a45aea5d',
  //   clinicName: 'Jester',
  //   address: '447 Charles Street Dorchester, MA 02125',
  //   partner: true,
  //   contactNo: '+6561053456',
  //   postalCode: '500600',
  //   createdAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  //   updatedAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  // },
  // {
  //   id: '5725a680fb65c91a82cb35e2',
  //   clinicName: 'Jester',
  //   address: '84 Valley View Road Norman, OK 73072',
  //   partner: true,
  //   contactNo: '+6561053456',
  //   postalCode: '500600',
  //   createdAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  //   updatedAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  // },
  // {
  //   id: '5725a68018c663044be49cbf',
  //   clinicName: 'Jester',
  //   address: '204 Clark Street Monsey, NY 10952',
  //   partner: true,
  //   contactNo: '+6561053456',
  //   postalCode: '500600',
  //   createdAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  //   updatedAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  // },
  // {
  //   id: '5725a6809413bf8a0a5272b1',
  //   clinicName: 'Jester',
  //   address: '261 Cleveland Street Riverside, NJ 08075',
  //   partner: true,
  //   contactNo: '+6561053456',
  //   postalCode: '500600',
  //   createdAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  //   updatedAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  // },
  // {
  //   id: '5725a6809413bf8a0a5272b2',
  //   clinicName: 'Jester',
  //   address: '904 Ridge Road Pickerington, OH 43147',
  //   partner: true,
  //   contactNo: '+6561053456',
  //   postalCode: '500600',
  //   createdAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  //   updatedAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  // },
  // {
  //   id: '5725a6809413bf8a0a5272b3',
  //   clinicName: 'Jester',
  //   address: '447 Charles Street Dorchester, MA 02125',
  //   partner: true,
  //   contactNo: '+6561053456',
  //   postalCode: '500600',
  //   createdAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  //   updatedAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  // },
  // {
  //   id: '5725a6809413bf8a0a5272b4',
  //   clinicName: 'Jester',
  //   address: '84 Valley View Road Norman, OK 73072',
  //   partner: true,
  //   contactNo: '+6561053456',
  //   postalCode: '500600',
  //   createdAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  //   updatedAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  // },
  // {
  //   id: '5725a6809413bf8a0a5272b5',
  //   clinicName: 'Jester',
  //   address: '204 Clark Street Monsey, NY 10952',
  //   partner: true,
  //   contactNo: '+6561053456',
  //   postalCode: '500600',
  //   createdAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  //   updatedAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  // },
  // {
  //   id: '5725a6809413bf8a0a5272b6',
  //   clinicName: 'Jester',
  //   address: '261 Cleveland Street Riverside, NJ 08075',
  //   partner: true,
  //   contactNo: '+6561053456',
  //   postalCode: '500600',
  //   createdAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  //   updatedAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  // },
  // {
  //   id: '6725a680cd7efa56a45aea5d',
  //   clinicName: 'Jester',
  //   address: '447 Charles Street Dorchester, MA 02125',
  //   partner: true,
  //   contactNo: '+6561053456',
  //   postalCode: '500600',
  //   createdAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  //   updatedAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  // },
  // {
  //   id: '6725a680fb65c91a82cb35e2',
  //   clinicName: 'Jester',
  //   address: '84 Valley View Road Norman, OK 73072',
  //   partner: true,
  //   contactNo: '+6561053456',
  //   postalCode: '500600',
  //   createdAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  //   updatedAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  // },
  // {
  //   id: '6725a68018c663044be49cbf',
  //   clinicName: 'Jester',
  //   address: '204 Clark Street Monsey, NY 10952',
  //   partner: true,
  //   contactNo: '+6561053456',
  //   postalCode: '500600',
  //   createdAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  //   updatedAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  // },
  // {
  //   id: '6725a6809413bf8a0a5272b1',
  //   clinicName: 'Jester',
  //   address: '261 Cleveland Street Riverside, NJ 08075',
  //   partner: true,
  //   contactNo: '+6561053456',
  //   postalCode: '500600',
  //   createdAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  //   updatedAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  // },
  // {
  //   id: '6725a6809413bf8a0a5272b2',
  //   clinicName: 'Jester',
  //   address: '904 Ridge Road Pickerington, OH 43147',
  //   partner: true,
  //   contactNo: '+6561053456',
  //   postalCode: '500600',
  //   createdAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  //   updatedAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  // },
  // {
  //   id: '6725a6809413bf8a0a5272b3',
  //   clinicName: 'Jester',
  //   address: '447 Charles Street Dorchester, MA 02125',
  //   partner: true,
  //   contactNo: '+6561053456',
  //   postalCode: '500600',
  //   createdAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  //   updatedAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  // },
  // {
  //   id: '6725a6809413bf8a0a5272b4',
  //   clinicName: 'Jester',
  //   address: '84 Valley View Road Norman, OK 73072',
  //   partner: true,
  //   contactNo: '+6561053456',
  //   postalCode: '500600',
  //   createdAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  //   updatedAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  // },
  // {
  //   id: '6725a6809413bf8a0a5272b5',
  //   clinicName: 'Jester',
  //   address: '204 Clark Street Monsey, NY 10952',
  //   partner: true,
  //   contactNo: '+6561053456',
  //   postalCode: '500600',
  //   createdAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  //   updatedAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  // },
  // {
  //   id: '6725a6809413bf8a0a5272b6',
  //   clinicName: 'Jester',
  //   address: '261 Cleveland Street Riverside, NJ 08075',
  //   partner: true,
  //   contactNo: '+6561053456',
  //   postalCode: '500600',
  //   createdAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  //   updatedAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  // },
  // {
  //   id: '7725a680cd7efa56a45aea5d',
  //   clinicName: 'Jester',
  //   address: '447 Charles Street Dorchester, MA 02125',
  //   partner: true,
  //   contactNo: '+6561053456',
  //   postalCode: '500600',
  //   createdAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  //   updatedAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  // },
  // {
  //   id: '7725a680fb65c91a82cb35e2',
  //   clinicName: 'Jester',
  //   address: '84 Valley View Road Norman, OK 73072',
  //   partner: true,
  //   contactNo: '+6561053456',
  //   postalCode: '500600',
  //   createdAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  //   updatedAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  // },
  // {
  //   id: '7725a68018c663044be49cbf',
  //   clinicName: 'Jester',
  //   address: '204 Clark Street Monsey, NY 10952',
  //   partner: true,
  //   contactNo: '+6561053456',
  //   postalCode: '500600',
  //   createdAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  //   updatedAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  // },
  // {
  //   id: '7725a6809413bf8a0a5272b1',
  //   clinicName: 'Jester',
  //   address: '261 Cleveland Street Riverside, NJ 08075',
  //   partner: true,
  //   contactNo: '+6561053456',
  //   postalCode: '500600',
  //   createdAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  //   updatedAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  // },
  // {
  //   id: '7725a6809413bf8a0a5272b2',
  //   clinicName: 'Jester',
  //   address: '904 Ridge Road Pickerington, OH 43147',
  //   partner: true,
  //   contactNo: '+6561053456',
  //   postalCode: '500600',
  //   createdAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  //   updatedAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  // },
  // {
  //   id: '7725a6809413bf8a0a5272b3',
  //   clinicName: 'Jester',
  //   address: '447 Charles Street Dorchester, MA 02125',
  //   partner: true,
  //   contactNo: '+6561053456',
  //   postalCode: '500600',
  //   createdAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  //   updatedAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  // },
  // {
  //   id: '7725a6809413bf8a0a5272b4',
  //   clinicName: 'Jester',
  //   address: '84 Valley View Road Norman, OK 73072',
  //   partner: true,
  //   contactNo: '+6561053456',
  //   postalCode: '500600',
  //   createdAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  //   updatedAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  // },
  // {
  //   id: '7725a6809413bf8a0a5272b5',
  //   clinicName: 'Jester',
  //   address: '204 Clark Street Monsey, NY 10952',
  //   partner: true,
  //   contactNo: '+6561053456',
  //   postalCode: '500600',
  //   createdAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  //   updatedAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  // },
  // {
  //   id: '7725a6809413bf8a0a5272b6',
  //   clinicName: 'Jester',
  //   address: '261 Cleveland Street Riverside, NJ 08075',
  //   partner: true,
  //   contactNo: '+6561053456',
  //   postalCode: '500600',
  //   createdAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  //   updatedAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  // },
];
