import { Component } from '@angular/core';
import { MSMenu, MSServiceNavConfig } from '@brand';

@Component({
  selector: 'organisation-layout',
  template: `
    <service-layout [nav]="nav" [navConfig]="navConfig" [navList]="navList">
      <router-outlet></router-outlet>
    </service-layout>
  `
})
export class OrganisationLayoutComponent {
  nav = true;
  navConfig: MSServiceNavConfig = {
    titleI18n: 'organisation.title',
    docI18n: 'organisation.title.doc'
  };
  navList: MSMenu[] = [
    { text: '', i18n: 'organisation.menu.policies', link: '/organisation/policies' },
    { text: '', i18n: 'organisation.menu.organisation-structure', link: '/organisation/organisation-structure' },
    { text: '', i18n: 'organisation.menu.leave-calendar', link: '/organisation/leave-calendar' }
  ];
}
