import { DelonMockModule } from '@delon/mock';
import { Environment } from '@delon/theme';
import * as MOCKDATA from '../../_mock';

export const environment = {
  production: true,
  useHash: true,
  api: {
    baseUrl: './api',
    refreshTokenEnabled: true,
    refreshTokenType: 'auth-refresh'
  },
  SERVER_URL: `./`,
  API_BASE_PATH: 'https://apigateway.dev.biz.shaw',
  SERVICE_NAME: { FILM_RENTAL: 'ss', API_GATEWAY: '' },
  baseUrl: '/api',
  AUTH_EXPIRES_IN: 60,
  OAUTH_GUARD: true,
  SESSION_END_WARNING_TIMER: 60,
  USER_IDLE_SECONDS: 540,
  USER_TIMEOUT_SECONDS: 600,
  USER_ACTIVITY_PING_SECONDS: 120,
  modules: [DelonMockModule.forRoot({ data: MOCKDATA })]
} as Environment & { [key: string]: any };
