import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from '../../auth/auth.service';

import { StateStorageService } from '../../auth/state-storage.service';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private stateStorageService: StateStorageService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      // tslint:disable-next-line: deprecation
      tap(null, (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401 && err.url && err.url.includes('auth/status') && err.error.signInUrl) {
            // this.stateStorageService.storeSignInUrl(err.error.signInUrl);
            this.authService.routeToLoginPage();
          }
        }
      })
    );
  }
}
