import { KeyValuePipe } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { GroupByPipe, NgArrayPipesModule } from 'ngx-pipes';
import { LeaveService } from 'src/app/shared/services';
import { DepartmentStructureListService, DepartmentStructureService } from './services';

const SERVICES = [DepartmentStructureListService, DepartmentStructureService, LeaveService];

const PIPES = [GroupByPipe, KeyValuePipe];

@NgModule({
  imports: [],
  exports: [NgArrayPipesModule]
})
export class OrganisationSharedModule {
  static forRoot(): ModuleWithProviders<OrganisationSharedModule> {
    return {
      ngModule: OrganisationSharedModule,
      providers: [...SERVICES, ...PIPES]
    };
  }
}
