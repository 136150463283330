import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DelonFormModule, WidgetRegistry } from '@delon/form';
import { NzButtonModule } from 'ng-zorro-antd/button';

import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { AddressModule } from '../components/address';
import { EditorModule } from '../components/editor';
import { FileManagerModule } from '../components/file-manager';
import { AddressWidget } from './widgets/address/address.widget';
import { CustomSelectWidget } from './widgets/custom-select/custom-select.widget';
import { CustomTagWidget } from './widgets/custom-tag/custom-tag.widget';
import { EditorWidget } from './widgets/editor/editor.widget';
import { ImgWidget } from './widgets/img/img.widget';

export const SCHEMA_THIRDS_COMPONENTS = [EditorWidget, ImgWidget, AddressWidget, CustomSelectWidget, CustomTagWidget];

@NgModule({
  declarations: SCHEMA_THIRDS_COMPONENTS,
  entryComponents: SCHEMA_THIRDS_COMPONENTS,
  imports: [
    CommonModule,
    FormsModule,
    DelonFormModule.forRoot(),
    AddressModule,
    EditorModule,
    FileManagerModule,
    NzButtonModule,
    NzSelectModule,
    NzSpinModule,
    NzInputModule,
    NzTagModule
  ],
  exports: [...SCHEMA_THIRDS_COMPONENTS]
})
export class JsonSchemaModule {
  constructor(widgetRegistry: WidgetRegistry) {
    widgetRegistry.register(EditorWidget.KEY, EditorWidget);
    widgetRegistry.register(ImgWidget.KEY, ImgWidget);
    widgetRegistry.register(AddressWidget.KEY, AddressWidget);
    widgetRegistry.register(CustomSelectWidget.KEY, CustomSelectWidget);
    widgetRegistry.register(CustomTagWidget.KEY, CustomTagWidget);
  }
}
