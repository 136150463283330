import { Platform } from '@angular/cdk/platform';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { UserIdleService } from 'angular-user-idle';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Subject, timer } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/oauth';
import { Biz360Utils } from '../../utils/biz360';
@Component({
  selector: 'session-expired-dialog',
  templateUrl: 'session-expired-dialog.component.html',
  styleUrls: ['session-expired-dialog.component.less']
})
export class Biz360SessionExpiredDialogComponent implements OnInit, OnDestroy {
  isLoaded = false;
  isMobile = false;
  minutes!: number;
  seconds!: number;
  private unsubscribe$: Subject<any> = new Subject();

  constructor(private platform: Platform, private userIdle: UserIdleService, private authService: AuthService, private modal: NzModalRef) {}

  ngOnInit(): void {
    if (this.platform.ANDROID || this.platform.IOS) {
      this.isMobile = true;
    }

    timer(0, 1000)
      .pipe(
        takeUntil(this.unsubscribe$),
        map(i => environment.SESSION_END_WARNING_TIMER - i),
        take(environment.SESSION_END_WARNING_TIMER + 1)
      )
      .subscribe(i => {
        this.minutes = Math.floor(i / 60);
        this.seconds = Math.floor(i % 60);

        if (i === 0) {
          this.signout();
        }
      });
  }

  pad(num: number, size: number): string {
    return Biz360Utils.pad(num, size);
  }

  signout(): void {
    this.destroyModal();
    this.authService.BroadcastEvent('raiseLogoutEvent', {});
  }

  continueSession(): void {
    this.authService
      .identity(true)
      .pipe(
        takeUntil(this.unsubscribe$),
        map(authStatus => {
          if (authStatus?.status === 'AUTHENTICATED') {
            this.destroyModal();
            this.userIdle.resetTimer();
          }
        })
      )
      .subscribe();
  }

  destroyModal(): void {
    this.modal.destroy({ data: 'closed' });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
