import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  NgZone,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ModalHelper, ModalHelperOptions } from '@delon/theme';
import { LazyService } from '@delon/util';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DepartmentStructureService } from '../shared/services/department-structure.service';

@Component({
  selector: 'organisation-structure',
  templateUrl: './organisation-structure.component.html',
  styleUrls: ['./organisation-structure.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrganisationStructureComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject();
  selectedDepartment: any;
  data: any[] = [];
  isVisibleContent = false;
  tplModal?: NzModalRef;

  departments = [
    {
      id: 1,
      dept: 'IT'
    },
    {
      id: 2,
      dept: 'Film Admin & Support'
    },
    {
      id: 3,
      dept: 'Finance'
    },
    {
      id: 4,
      dept: 'Marketing - Sales'
    },
    {
      id: 5,
      dept: 'I.C.E.'
    },
    {
      id: 6,
      dept: 'Programming'
    },
    {
      id: 7,
      dept: 'Project'
    },
    {
      id: 8,
      dept: 'HR'
    },
    {
      id: 9,
      dept: 'Real Estate'
    },
    {
      id: 10,
      dept: 'Building Services'
    },
    {
      id: 11,
      dept: 'Theatre Operations'
    },
    {
      id: 12,
      dept: 'Office Support'
    },
    {
      id: 13,
      dept: 'Admin Support'
    },
    {
      id: 14,
      dept: 'Legal'
    },
    {
      id: 15,
      dept: 'Film Distribution'
    },
    // {
    //   id: 16,
    //   dept: 'Concessions',
    // },
    {
      id: 17,
      dept: 'Servicing & Publicity'
    },
    // {
    //   id: 18,
    //   dept: 'Advertising & Promotions Event',
    // },
    {
      id: 19,
      dept: 'Asian Films'
    },
    // {
    //   id: 20,
    //   dept: 'Security',
    // },
    {
      id: 21,
      dept: 'CSO Info Desk'
    }
  ];

  selectedDeptIndex!: number;

  constructor(
    private lazy: LazyService,
    private ngZone: NgZone,
    private cdr: ChangeDetectorRef,
    private departmentStructureService: DepartmentStructureService,
    private modal: NzModalService,
    // private modal: ModalHelper,
    private router: Router
  ) {
    router.events.pipe(takeUntil(this.unsubscribe$)).subscribe(e => {
      if (e instanceof NavigationEnd) {
        if (this.selectedDepartment) {
          this.router.navigate(['/organisation/organisation-structure', this.selectedDepartment.id]);
        }
      }
    });
  }

  selectTab(event: { id: any }): void {
    this.router.navigate(['/organisation/organisation-structure', event.id]);
  }

  transfrom(obj: any, oldKey: string, newKey: string): any {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const element = obj[key];
        if (key === oldKey) {
          obj[newKey] = element.replace('A', 'team-a').replace('B', 'team-b');
          delete obj[oldKey];
        }
        if (typeof element === 'object') {
          obj[key] = this.transfrom(element, oldKey, newKey);
        }
      }
    }
    return obj;
  }

  ngOnInit(): void {
    this.departmentStructureService.onItemChanged.pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
      this.selectedDepartment = res;
      this.selectedDeptIndex = this.departments.findIndex(item => item.id === res.id);
      this.data = [];

      this.transfrom(res, 'team', 'cssClass').childs?.forEach((element: any) => {
        this.data.push(element);
      });
      this.cdr.detectChanges();
    });
  }

  isEmpty(obj: any): boolean {
    return Object.keys(obj).length === 0;
  }

  createTplModal(tplTitle: TemplateRef<{}> | string, tplContent: TemplateRef<{}>, tplFooter: TemplateRef<{}> | string, tplData: any): void {
    this.tplModal = this.modal.create({
      nzTitle: null!,
      nzContent: tplContent,
      nzFooter: null,
      nzMaskClosable: true,
      nzClosable: true,
      nzComponentParams: tplData,
      nzClassName: 'modal-sm',
      nzStyle: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        // width: '50%',
        height: '30%',
        margin: 'auto'
      }
    });
  }

  ngOnDestroy(): void {
    const { unsubscribe$ } = this;
    unsubscribe$.next();
    unsubscribe$.complete();
  }
}
