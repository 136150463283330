import { ElementRef, Injectable, NgZone, Renderer2, RendererFactory2 } from '@angular/core';
import { Platform } from '@angular/cdk/platform';
import { ContentObserver } from '@angular/cdk/observers';
import { OverlayContainer } from '@angular/cdk/overlay';
import { ScrollbarDirective } from '@shared';

@Injectable({ providedIn: 'root' })
export class ScrollbarService {
  private scrollbars: Map<HTMLElement, ScrollbarDirective> = new Map();
  private renderer2: Renderer2;
  private containerClassList: string[] = ['ant-dropdown-menu', 'cdk-virtual-scroll-viewport'];

  constructor(
    private ngZone: NgZone,
    private rendererFactory: RendererFactory2,
    private platform: Platform,
    private observer: ContentObserver,
    private container: OverlayContainer,
  ) {
    this.renderer2 = this.rendererFactory.createRenderer(null, null);
    this.observer.observe(this.container.getContainerElement()).subscribe((records) => {
      const node = records.find((x) => x.type === 'childList')!;
      if (node?.addedNodes?.length > 0) {
        const parentNode = node.addedNodes[0];
        if (parentNode instanceof HTMLElement) {
          this.containerClassList.forEach((x) => {
            const elements = parentNode.getElementsByClassName(x);
            Array.from(elements).forEach((x) => this.createScrollbar(x as HTMLElement));
          });
        }
      }
    });
  }

  private createScrollbar(element: HTMLElement) {
    if (!this.scrollbars.has(element)) {
      const scrollbar = new ScrollbarDirective(new ElementRef(element), this.ngZone, this.platform, this.renderer2);
      scrollbar.ngAfterViewInit();
      setTimeout(() => {
        scrollbar.update();
      }, 200);
      this.scrollbars.set(element, scrollbar);
    }
  }
}
