import { Platform } from '@angular/cdk/platform';
import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  NgZone,
  OnDestroy,
  Output,
  Renderer2,
  RendererStyleFlags2
} from '@angular/core';

import { toBoolean } from '@delon/util';
import * as _ from 'lodash';
import PerfectScrollbar from 'perfect-scrollbar';

import { fromEvent, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { PerfectScrollbarEvent, PerfectScrollbarEvents, ScrollbarOptions } from './scrollbar.interface';

interface Config {
  selector: string;
  'z-index': any;
}

@Directive({
  selector: '[scrollbar]',
  exportAs: 'scrollbarComp'
})
export class ScrollbarDirective implements AfterViewInit, OnDestroy {
  private instance: PerfectScrollbar | null = null;
  private readonly ngDestroy: Subject<void> = new Subject();
  private _disabled = false;
  private _debouncedUpdate: any;
  private elMap: Map<string, Config> = new Map([
    ['st', { selector: '.ant-table-content', 'z-index': 3 }],
    ['nz-tabset', { selector: '.ant-dropdown-menu', 'z-index': 3 }]
    // ['gstc', { selector: '.gantt-schedule-timeline-calendar__horizontal-scroll', 'z-index': 3 }]
  ]);

  isMobile = false;
  isInitialized: boolean;
  // #region fields
  wrap: HTMLElement | null = null;

  @Input('scrollbar') options?: ScrollbarOptions & { config?: Config };

  @Input()
  set disabled(value: boolean) {
    this._disabled = toBoolean(value)!;
    if (this._disabled) {
      this.ngOnDestroy();
    } else {
      this.init();
    }
  }

  @Output() readonly psScrollX: EventEmitter<any> = new EventEmitter<any>();
  @Output() readonly psScrollY: EventEmitter<any> = new EventEmitter<any>();

  @Output() readonly psScrollUp: EventEmitter<any> = new EventEmitter<any>();
  @Output() readonly psScrollDown: EventEmitter<any> = new EventEmitter<any>();
  @Output() readonly psScrollLeft: EventEmitter<any> = new EventEmitter<any>();
  @Output() readonly psScrollRight: EventEmitter<any> = new EventEmitter<any>();

  @Output() readonly psXReachStart: EventEmitter<any> = new EventEmitter<any>();
  @Output() readonly psXReachEnd: EventEmitter<any> = new EventEmitter<any>();
  @Output() readonly psYReachStart: EventEmitter<any> = new EventEmitter<any>();
  @Output() readonly psYReachEnd: EventEmitter<any> = new EventEmitter<any>();

  // #endregion

  scrollToBottom(): void {
    this.el.scrollTop = this.el.scrollHeight - this.el.clientHeight;
  }

  scrollToTop(): void {
    this.el.scrollTop = 0;
  }

  scrollToLeft(): void {
    this.el.scrollLeft = 0;
  }

  scrollToRight(): void {
    this.el.scrollLeft = this.el.scrollWidth - this.el.clientWidth;
  }

  constructor(private elRef: ElementRef, private zone: NgZone, private _platform: Platform, private renderer: Renderer2) {
    this.isInitialized = false;
    this._debouncedUpdate = _.debounce(this.update, 150);

    if ((elRef.nativeElement as HTMLElement)?.classList?.contains('wrap')) {
      this.wrap = elRef.nativeElement as HTMLElement;
    }
  }

  private get el(): any {
    return this.elRef.nativeElement as HTMLElement;
  }

  private init(): any {
    if (this.isInitialized) {
      return;
    }

    if (this._platform.ANDROID || this._platform.IOS) {
      this.isMobile = true;
      return;
    }

    this.isInitialized = true;

    this.zone.runOutsideAngular(() => {
      const options = {
        wheelSpeed: 0.5,
        swipeEasing: true,
        wheelPropagation: true,
        minScrollbarLength: 40,
        // maxScrollbarLength: 300,
        ...this.options
      };
      setTimeout(() => {
        if (this._disabled) {
          return;
        }

        const config: Config = this.elMap.get((this.el as HTMLElement)?.tagName?.toLowerCase()) || this.options?.config!;

        if (config?.selector) {
          this.elRef.nativeElement = (this.el as HTMLElement).querySelector(config?.selector)!;
        }

        if (this.elRef?.nativeElement) {
          this.renderer?.setStyle(this.elRef?.nativeElement, 'position', 'relative');
        }
        this.instance = new PerfectScrollbar(this.el, options);
        this.instance.scrollbarXRail.style.setProperty('z-index', config?.selector ? config['z-index'] : '0');

        PerfectScrollbarEvents.forEach((eventName: PerfectScrollbarEvent) => {
          const eventType = eventName.replace(/([A-Z])/g, c => `-${c.toLowerCase()}`);

          fromEvent<Event>(this.el, eventType)
            .pipe(debounceTime(20), takeUntil(this.ngDestroy))
            .subscribe((event: Event) => {
              this[eventName].emit(event);
            });
        });
      }, this.options?.delay || 0);
    });
  }

  @HostListener('window:resize')
  _updateOnResize(): void {
    this._debouncedUpdate();

    this.clearWrapHeight();
    this.setWrapHeight();
  }

  @HostListener('mouseenter')
  _mouseEnter(): any {
    if (!this.isInitialized || !this.instance) {
      return;
    }

    this.instance.update();
  }

  @HostListener('mousehover')
  _mouseHover(): any {
    if (!this.isInitialized || !this.instance) {
      return;
    }

    this.instance.update();
  }

  @HostListener('document:click', ['$event'])
  documentClick(event: Event): void {
    if (!this.isInitialized || !this.instance) {
      return;
    }

    this.instance.update();
  }

  setWrapHeight(): void {
    if (this.wrap) {
      // tslint:disable-next-line: no-bitwise
      this.renderer.setStyle(
        this.wrap,
        'height',
        `${this.wrap.clientHeight}px`,
        RendererStyleFlags2.DashCase | RendererStyleFlags2.Important
      );
    }
  }

  clearWrapHeight(): void {
    if (this.wrap) {
      this.renderer.removeStyle(this.wrap, 'height');
    }
  }

  update(): void {
    if (!this.isInitialized) {
      return;
    }

    this.instance?.update();
  }

  ngAfterViewInit(): void {
    this.init();
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
    this.zone.runOutsideAngular(() => {
      if (this.instance) {
        this.instance.destroy();
      }
      this.instance = null;
    });
    this.isInitialized = false;
  }
}
