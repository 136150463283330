import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';

import { ModalHelper, ModalHelperOptions } from '@delon/theme';

import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { Biz360PolicyDetailModalComponent } from 'src/app/shared/components/policy-detail-modal';
import { Policy, Scope, ScopeCategory } from 'src/app/shared/models';
import { Biz360Utils } from 'src/app/shared/utils/biz360';
import { PoliciesService } from '../_core/policies.service';

@Component({
  selector: 'policies',
  templateUrl: './policies.component.html',
  styleUrls: ['policies.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PoliciesComponent implements OnInit, OnDestroy {
  policies$: Observable<Policy[]>;
  scopes$: Observable<Scope[]>;
  defaultScope$: Observable<string>;

  radioValue!: string;
  scopeCategory: ScopeCategory[] = [];
  ngSelectValue!: ScopeCategory;

  results$!: Observable<any[]>;
  searchText!: string;

  private userScopes: string[] = ['scope1'];
  private defaultScopeSubject = new BehaviorSubject<string>(null!);
  private subscriptions: Subscription[] = [];

  constructor(private modal: ModalHelper, private policiesService: PoliciesService, private cdr: ChangeDetectorRef) {
    this.policies$ = this.policiesService.policies$;
    this.scopes$ = this.policiesService.scopes$;
    this.defaultScope$ = this.defaultScopeSubject.asObservable();
  }

  ngOnInit(): void {
    this.filterScopes();

    this.filterPolicies();

    this.filterCategories();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  onKeydown(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.performSearch((event.target as HTMLInputElement).value);
    }
  }

  performSearch(searchText: string): void {
    this.policiesService.onSearchTextChanged(searchText);
  }

  openModal(selectedPolicy: Policy): void {
    // console.log(selectedPolicy);
    const options: ModalHelperOptions = {
      size: 'lg',
      modalOptions: {
        nzKeyboard: false,
        nzMaskClosable: false,
        nzWrapClassName: 'vertical-center-modal'
      }
    };

    this.subscriptions.push(this.modal.create(Biz360PolicyDetailModalComponent, { selectedPolicy }, options).subscribe());
  }

  onSelectedTabChanged(event: Scope): void {
    this.defaultScopeSubject.next(event.id);
  }

  compareFn = (o1: any, o2: any) => (o1 && o2 ? o1.value === o2.value : o1 === o2);

  private filterCategories(): void {
    this.subscriptions.push(
      this.policiesService
        .getCategories()
        .pipe(
          switchMap(categories =>
            this.defaultScope$.pipe(
              tap(id => {
                const result = categories.find(category => category.id === id);
                if (result) {
                  this.ngSelectValue = { key: '0', value: 'ALL' };
                  this.scopeCategory = [...result.scopeCategories];
                }
                // console.log(id, this.ngSelectValue, this.scopeCategory);
                this.cdr.markForCheck();
              })
            )
          )
        )
        .subscribe()
    );
  }

  private filterPolicies(): void {
    this.subscriptions.push(
      this.policiesService
        .getPolicies()
        .pipe(
          switchMap(policies => this.defaultScope$.pipe(map(scopeId => policies.filter(policy => policy.scope.id === scopeId)))),
          switchMap(policies =>
            this.policiesService.search$.pipe(
              map(searchText => {
                const filteredItems = !!searchText ? Biz360Utils.filterArrayByStringByProperty(policies, searchText, 'title') : policies;
                this.policiesService.onPoliciesChanged(filteredItems);
              })
            )
          )
        )
        .subscribe()
    );
  }

  private filterScopes(): void {
    this.subscriptions.push(
      this.policiesService
        .getScopes()
        .pipe(
          tap(scopes => {
            let results: Scope[] = [];
            if (this.userScopes.length > 0) {
              this.userScopes.forEach(scopeId => {
                results = [...results, ...scopes.filter(scope => scope.id === scopeId)];
              });
            } else {
              results = [...scopes];
            }
            this.policiesService.onScopesChanged(results);
            this.defaultScopeSubject.next(results[0].id);
          })
        )
        .subscribe()
    );
  }
}
