import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { RouteReuseStrategy } from '@angular/router';
import { throwIfAlreadyLoaded } from '@core';
import { ReuseTabMatchMode, ReuseTabService, ReuseTabStrategy } from '@delon/abc/reuse-tab';
import { STPage } from '@delon/abc/st';
import { DelonACLModule } from '@delon/acl';
import { DelonMockModule } from '@delon/mock';
import { AlainThemeModule } from '@delon/theme';
import { AlainConfig, AlainSTConfig, AlainThemeResponsiveConfig, ALAIN_CONFIG } from '@delon/util';
import { NzConfig, NZ_CONFIG } from 'ng-zorro-antd/core/config';
import * as MOCKDATA from '../../_mock';

// Please refer to: https://ng-alain.com/docs/global-config
// #region NG-ALAIN Config

const alainConfig: AlainConfig = {
  st: {
    // widthMode: { type: 'strict', strictBehavior: 'truncate' },
    modal: { paramsName: 'i', size: 'lg' },
    page: { toTop: true, toTopOffset: 0, zeroIndexed: false, showSize: true } as STPage,
    pop: { title: 'Are you sure?', okType: 'danger', icon: 'exclamation-circle' },
    req: { reName: { pi: 'page', ps: 'size' } },
    res: { reName: { list: 'content', total: 'totalElements' } },
    multiSort: { key: 'sort', separator: ',', nameSeparator: '|', keepEmptyKey: false, global: true },
    singleSort: { key: 'sort', nameSeparator: '|' },
    sortReName: { ascend: 'asc', descend: 'desc' }
  } as AlainSTConfig,
  pageHeader: { homeI18n: 'home', recursiveBreadcrumb: true },
  auth: { login_url: '/account/login' },
  themeResponsive: {
    rules: {
      1: { xs: 24 },
      2: { xs: 24, sm: 24, md: 12 },
      3: { xs: 24, sm: 12, md: 8 },
      4: { xs: 24, sm: 12, md: 8, lg: 6 },
      5: { xs: 24, sm: 12, md: 8, lg: 6, xl: 4 },
      6: { xs: 24, sm: 12, md: 8, lg: 6, xl: 4, xxl: 2 }
    }
  } as AlainThemeResponsiveConfig
};

const alainModules = [AlainThemeModule.forRoot(), DelonACLModule.forRoot(), DelonMockModule.forRoot({ data: MOCKDATA })];
const alainProvides = [{ provide: ALAIN_CONFIG, useValue: alainConfig }];

// #region reuse-tab
/**
 * 若需要[路由复用](https://ng-alain.com/components/reuse-tab)需要：
 * 1、在 `shared-delon.module.ts` 导入 `ReuseTabModule` 模块
 * 2、注册 `RouteReuseStrategy`
 * 3、在 `src/app/layout/default/default.component.html` 修改：
 *  ```html
 *  <section class="alain-default__content">
 *    <reuse-tab #reuseTab></reuse-tab>
 *    <router-outlet (activate)="reuseTab.activate($event)"></router-outlet>
 *  </section>
 *  ```
 */
alainProvides.push({
  provide: RouteReuseStrategy,
  useClass: ReuseTabStrategy,
  deps: [ReuseTabService]
} as any);

// #endregion

// #endregion

// Please refer to: https://ng.ant.design/docs/global-config/en#how-to-use
// #region NG-ZORRO Config

const ngZorroConfig: NzConfig = {};
const zorroProvides = [{ provide: NZ_CONFIG, useValue: ngZorroConfig }];

// #endregion

@NgModule({
  imports: [...alainModules]
})
export class GlobalConfigModule {
  constructor(@Optional() @SkipSelf() parentModule: GlobalConfigModule, reuseTabSrv: ReuseTabService) {
    throwIfAlreadyLoaded(parentModule, 'GlobalConfigModule');
    reuseTabSrv.mode = ReuseTabMatchMode.MenuForce;
  }

  static forRoot(): ModuleWithProviders<GlobalConfigModule> {
    return {
      ngModule: GlobalConfigModule,
      providers: [...alainProvides, ...zorroProvides]
    };
  }
}
