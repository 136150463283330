import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { Biz360DeleteModalDialogComponent } from './delete-modal.component';

@NgModule({
  imports: [CommonModule],
  declarations: [Biz360DeleteModalDialogComponent],
  exports: [],
  providers: []
})
export class Biz360DeleteModalDialogModule {}
