import { Component, Input } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'delete-modal-dialog',
  template: ` <p>{{ content }}</p> `
})
export class Biz360DeleteModalDialogComponent {
  @Input()
  content: any;

  constructor(private modal: NzModalRef) {}
}
