import { MockRequest, MockStatusError } from '@delon/mock';
import { deepCopy } from '@delon/util';
import { Support } from 'src/app/shared/models';

const DATA: Support[] = [
  {
    id: '1',
    title: 'Microsoft Teams',
    guides: [
      {
        id: '1',
        title: 'How To Install Microsoft Teams (Window)',
        content: {
          path: '/assets/documents/Microsoft Team User Guide.pdf',
          initialPage: 2,
          startPage: 2,
          endPage: 2,
          link: 'https://support.office.com/en-us/teams',
          downloadUrl: 'https://teams.microsoft.com/downloads',
        },
        supportId: '1',
      },
      {
        id: '2',
        title: 'How To Install Microsoft Teams (Mac)',
        content: {
          path: '/assets/documents/Microsoft Team User Guide.pdf',
          initialPage: 3,
          startPage: 3,
          endPage: 3,
          link: 'https://support.office.com/en-us/teams',
          downloadUrl: 'https://teams.microsoft.com/downloads',
        },
        supportId: '1',
      },
      {
        id: '3',
        title: 'How To Find The Downloaded Files',
        content: { path: '/assets/documents/Microsoft Team User Guide.pdf', initialPage: 4, startPage: 4, endPage: 5 },
        supportId: '1',
      },
      {
        id: '4',
        title: 'How To Share Your Screen With Teammates',
        content: { path: '/assets/documents/Microsoft Team User Guide.pdf', initialPage: 6, startPage: 6, endPage: 8 },
        supportId: '1',
      },
      {
        id: '5',
        title: 'How To Build A Team From Scratch',
        content: {
          path: '/assets/documents/Microsoft Team User Guide.pdf',
          initialPage: 9,
          startPage: 9,
          endPage: 9,
          link: 'https://support.office.com/en-us/article/Teams-and-channels-df38ae23-8f85-46d3-b071-cb11b9de5499#ID0EAABAAA=For_team_owners',
        },
        supportId: '1',
      },
      {
        id: '6',
        title: 'How To Add A Member To Team',
        content: {
          path: '/assets/documents/Microsoft Team User Guide.pdf',
          initialPage: 10,
          startPage: 10,
          endPage: 10,
          link: 'https://support.office.com/en-us/article/Teams-and-channels-df38ae23-8f85-46d3-b071-cb11b9de5499#ID0EAABAAA=For_team_owners',
        },
        supportId: '1',
      },
      {
        id: '7',
        title: 'How To Share A File From Onedrive In Team',
        content: { path: '/assets/documents/Microsoft Team User Guide.pdf', initialPage: 11, startPage: 11, endPage: 11 },
        supportId: '1',
      },
      {
        id: '8',
        title: 'Useful Links For Microsoft Teams',
        content: {
          path: '/assets/documents/Microsoft Team User Guide.pdf',
          initialPage: 12,
          startPage: 12,
          endPage: 12,
          link: 'https://support.office.com/en-us/teams',
        },
        supportId: '1',
      },
    ],
  },
  {
    id: '2',
    title: 'Remote Computer Setup & Connection',
    guides: [
      {
        id: '1',
        title: 'Access Microsoft Office And Emails Via Web Browser',
        content: { path: '/assets/documents/BCP%20PC%20Guide%20for%20Users%20-%202.pdf', initialPage: 2, startPage: 2, endPage: 2 },
        supportId: '2',
      },
      {
        id: '2',
        title: 'Access Your Existing Documents That Are Currently On Your Shaw Office PC',
        content: { path: '/assets/documents/BCP%20PC%20Guide%20for%20Users%20-%202.pdf', initialPage: 3, startPage: 3, endPage: 3 },
        supportId: '2',
      },
      {
        id: '3',
        title: 'SSL VPN For Remote Access',
        content: { path: '/assets/documents/BCP%20PC%20Guide%20for%20Users%20-%202.pdf', initialPage: 4, startPage: 4, endPage: 5 },
        supportId: '2',
      },
      {
        id: '4',
        title: 'PC Security: Antivirus',
        content: { path: '/assets/documents/BCP%20PC%20Guide%20for%20Users%20-%202.pdf', initialPage: 6, startPage: 6, endPage: 6 },
        supportId: '2',
      },
      {
        id: '5',
        title: 'PC Security: Secure Computing Tips (Top 10)',
        content: { path: '/assets/documents/BCP%20PC%20Guide%20for%20Users%20-%202.pdf', initialPage: 7, startPage: 7, endPage: 8 },
        supportId: '2',
      },
      {
        id: '6',
        title: 'How To Connect Your Computer At Home',
        subTitle: '(For those with desktop computers on loan)',
        content: { path: '/assets/documents/BCP%20PC%20Guide%20for%20Users%20-%202.pdf', initialPage: 9, startPage: 9, endPage: 9 },
        supportId: '2',
      },
      {
        id: '7',
        title: 'SSLVPN Directly to Your Department’s Shared Drives in Shaw Office SAN ',
        content: {
          path: '/assets/documents/Remote%20Connect%20Directly%20to%20Shaw%20SAN.pdf',
          initialPage: 1,
          startPage: 1,
          endPage: 1,
        },
        supportId: '2',
      },
    ],
  },
  {
    id: '3',
    title: 'Digital Petty Cash Form',
    guides: [
      {
        id: '1',
        title: 'How To Open OneDrive',
        content: { path: '/assets/documents/User Guide for Petty Cash Form.pdf', initialPage: 2, startPage: 2, endPage: 2 },
        supportId: '3',
      },
      {
        id: '2',
        title: 'How To Download Petty Cash Form',
        content: { path: '/assets/documents/User Guide for Petty Cash Form.pdf', initialPage: 3, startPage: 3, endPage: 3 },
        supportId: '3',
      },
      {
        id: '3',
        title: 'How To Open Downloaded File',
        content: { path: '/assets/documents/User Guide for Petty Cash Form.pdf', initialPage: 4, startPage: 4, endPage: 5 },
        supportId: '3',
      },
      {
        id: '4',
        title: 'How To Fill In The Form',
        content: { path: '/assets/documents/User Guide for Petty Cash Form.pdf', initialPage: 6, startPage: 6, endPage: 7 },
        supportId: '3',
      },
      {
        id: '5',
        title: 'How To Rename A File',
        content: { path: '/assets/documents/User Guide for Petty Cash Form.pdf', initialPage: 8, startPage: 8, endPage: 8 },
        supportId: '3',
      },
      {
        id: '6',
        title: 'How To Upload The File',
        content: { path: '/assets/documents/User Guide for Petty Cash Form.pdf', initialPage: 9, startPage: 9, endPage: 9 },
        supportId: '3',
      },
    ],
  },
  {
    id: '4',
    title: 'File Downloading, Uploading and Sharing in OneDrive',
    guides: [
      {
        id: '1',
        title: 'How To Open OneDrive',
        content: { path: '/assets/documents/User Guide for One Drive.pdf', initialPage: 2, startPage: 2, endPage: 2 },
        supportId: '4',
      },
      {
        id: '2',
        title: 'How To Download File From Shared OneDrive',
        content: { path: '/assets/documents/User Guide for One Drive.pdf', initialPage: 3, startPage: 3, endPage: 3 },
        supportId: '4',
      },
      {
        id: '3',
        title: 'How To Download File From Own OneDrive',
        content: { path: '/assets/documents/User Guide for One Drive.pdf', initialPage: 4, startPage: 4, endPage: 4 },
        supportId: '4',
      },
      {
        id: '4',
        title: 'How To Open Downloaded File',
        content: { path: '/assets/documents/User Guide for One Drive.pdf', initialPage: 5, startPage: 5, endPage: 6 },
        supportId: '4',
      },
      {
        id: '5',
        title: 'How To Upload The File To Shared OneDrive',
        content: { path: '/assets/documents/User Guide for One Drive.pdf', initialPage: 7, startPage: 7, endPage: 7 },
        supportId: '4',
      },
      {
        id: '6',
        title: 'How To Upload The File To Own OneDrive',
        content: { path: '/assets/documents/User Guide for One Drive.pdf', initialPage: 8, startPage: 8, endPage: 8 },
        supportId: '4',
      },
      {
        id: '7',
        title: 'How To Share A File From Own OneDrive Email',
        content: { path: '/assets/documents/User Guide for One Drive.pdf', initialPage: 9, startPage: 9, endPage: 10 },
        supportId: '4',
      },
    ],
  },
];

function generateGUID(): string {
  // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
  function S4(): string {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  return S4() + S4();
}

function getIdx(id: string): number {
  const idx = DATA.findIndex((w) => w.id === id);
  if (!idx) {
    throw new MockStatusError(404);
  }
  return idx;
}

function saveData(id: string, value: any): any {
  const item = DATA.find((w) => w.id === id);
  if (!item) {
    return { msg: 'Data not found!' };
  }
  Object.assign(item, value);
  return { msg: 'ok' };
}

export const SUPPORTS = {
  'GET /api/supports': () => deepCopy(DATA),
  'GET /supports:id': (req: MockRequest) => DATA.find((w) => w.id === req.params.id),
  'POST /supports/:id': (req: MockRequest) => saveData(req.params.id, req.body),
  'POST /supports': (req: MockRequest) => {
    const id = req.body.id || 0;
    if (id > 0) {
      const idx = getIdx(id);
      DATA[idx] = { ...DATA[idx], ...req.body };
      return { msg: 'ok', item: DATA[idx] };
    }

    const item = { ...req.body };
    DATA.push(item);
    return { msg: 'ok', item };
  },
  'DELETE /supports/:id': (req: MockRequest) => {
    const idx = getIdx(req.params.id || 0);
    DATA.splice(idx, 1);
    return { msg: 'ok' };
  },
  // 'POST /supports/move': (req: MockRequest) => {
  //   const idx = getIdx(req.body.from || 0);
  //   DATA[idx].parent_id = req.body.to || 0;
  //   return { msg: 'ok', item: DATA[idx] };
  // },
};
