import { KeyValuePipe } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Inject, NgModule } from '@angular/core';

import { I18NService } from '@core';
import { ALAIN_I18N_TOKEN } from '@delon/theme';
import { SharedModule } from '@shared';
import { AngularGanttScheduleTimelineCalendarModule } from 'angular-gantt-schedule-timeline-calendar';
import { NgxOrgChartModule } from 'ngx-org-chart';
import { FilterByPipe, GroupByPipe } from 'ngx-pipes';
import { LeaveService } from 'src/app/shared/services';
import { PoliciesService } from './_core/policies.service';
import { default as en_US } from './_i18n/en-US';
import { default as zh_CN } from './_i18n/zh-CN';
import { default as zh_TW } from './_i18n/zh-TW';

import { OrganisationLayoutComponent } from './_layout/layout.component';
import { LeaveCalendarComponent } from './leave-calendar/leave-calendar.component';
import { OrganisationRoutingModule } from './organisation-routing.module';
import { OrganisationStructureComponent } from './organisation-structure/organisation-structure.component';
import { PoliciesComponent } from './policies/policies.component';
import { OrganisationSharedModule } from './shared/organisation-shared.module';

import { DataSummaryTradeComponent } from './trade/trade.component';

const COMPONENTS = [
  OrganisationLayoutComponent,
  LeaveCalendarComponent,
  PoliciesComponent,
  OrganisationStructureComponent,
  DataSummaryTradeComponent
];

const COMPONENTS_NOROUNT: any = [];

const SERVICES = [LeaveService, PoliciesService];

const PIPES = [GroupByPipe, KeyValuePipe, FilterByPipe];

@NgModule({
  imports: [
    SharedModule,
    OrganisationRoutingModule,
    NgxOrgChartModule,
    OrganisationSharedModule.forRoot(),
    AngularGanttScheduleTimelineCalendarModule
  ],
  declarations: [...COMPONENTS, ...COMPONENTS_NOROUNT],
  providers: [...SERVICES, ...PIPES],
  entryComponents: COMPONENTS_NOROUNT,
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class OrganisationModule {
  constructor(@Inject(ALAIN_I18N_TOKEN) i18n: I18NService) {
    i18n.load('zh-CN', zh_CN);
    i18n.load('zh-TW', zh_TW);
    i18n.load('en-US', en_US);
  }
}
