import { KeyValuePipe } from '@angular/common';
import { Injectable, NgZone } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { _HttpClient } from '@delon/theme';
import moment, { Moment } from 'moment';
import { GroupByPipe } from 'ngx-pipes';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
@Injectable()
export class LeaveService implements Resolve<any> {
  resourceUrl = '/summary/leave';
  leaveTypeUrl = '/summary/leave-types';

  onDataChanged: BehaviorSubject<any> = new BehaviorSubject({});
  onDateChanged: BehaviorSubject<any> = new BehaviorSubject({});
  onLeaveTypeChanged: BehaviorSubject<any> = new BehaviorSubject({});
  leaveTypes!: any[];
  startDate!: Moment;
  endDate!: Moment;
  date$: Observable<any>;
  config!: any;

  private routeData: any;
  constructor(private http: _HttpClient, private groupByPipe: GroupByPipe, private keyValuePipe: KeyValuePipe, private ngZone: NgZone) {
    this.date$ = this.onDateChanged.asObservable();
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    this.routeData = route.data;

    return new Promise((resolve, reject) => {
      Promise.all([this.getLeaveTypes(), this.getListing()]).then(res => {
        resolve(res);
      }, reject);
    });
  }

  getListing(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.date$.subscribe((dateSub: Moment) => {
        if (Object.keys(dateSub).length === 0) {
          dateSub = moment().startOf('date');
        }
        console.log();
        this.startDate = this.routeData.weekly ? moment().startOf('date') : moment(dateSub).startOf('month');
        this.endDate = this.routeData.weekly ? moment().startOf('date').add(7, 'days') : moment(dateSub).endOf('month');

        this.http
          .get('/summary/leave', {
            start: this.startDate.valueOf(),
            end: this.endDate.valueOf()
          })
          .subscribe(res => {
            const empgroup = this.groupByPipe.transform(res, ['empName']);
            const keypairs = this.keyValuePipe.transform(empgroup) || [];
            const config = this.getGanttObjects(keypairs, this.leaveTypes);
            this.config = config;
            console.log('gantt config', this.config);
            this.onDataChanged.next(config);
            resolve(res);
          }, reject);
      });
    });
  }

  getGanttObjects(keyPair: any[], leaveTypes: any[]): any {
    const rows: any = {};
    const items: any = {};
    const columns = {
      percent: 100,
      resizer: {
        inRealTime: true
      },
      data: {
        label: {
          id: 'label',
          data: 'label',
          expander: false,
          isHtml: true,
          width: 170,
          minWidth: 100,
          header: {
            // content: 'Room'
          }
        }
      }
    };

    for (let i = 0; i < keyPair.length; i++) {
      rows[i] = {
        id: i,
        label: keyPair[i].key,
        parentId: undefined,
        expanded: false
      };
    }

    let itemId = 0;
    for (let i = 0; i < keyPair.length; i++) {
      for (const item of keyPair[i].value) {
        const rowId = i.toString();
        const id = itemId.toString();
        const leaveType = leaveTypes.filter(
          (x: { leaveTypeName: string }) => x.leaveTypeName.toLowerCase() === item.leaveCode.toLowerCase()
        )[0];

        items[id] = {
          id,
          label: leaveType.leaveTypeName,
          time: {
            start: item.startTime,
            end: item.endTime
          },
          rowId,
          style: { background: leaveType.colorCode }
        };
        itemId++;
      }
    }

    return {
      height: 600,
      scroll: {
        bodyClassName: 'scrollbar'
      },
      list: {
        rows,
        columns,
        toggle: {
          display: false
        }
      },
      chart: {
        calendar: {
          expand: false,
          vertical: {
            smallFormat: 'YYYY-MM-DD'
          }
        },
        time: {
          calculatedZoomMode: false,
          centerGlobal: 1583507267584,
          finalFrom: this.startDate.valueOf(),
          finalTo: this.endDate.valueOf(),
          from: this.startDate.valueOf(),
          leftGlobal: this.startDate.valueOf(),
          levels: [],
          period: 'day',
          rightGlobal: 1583936135168,
          to: this.endDate.valueOf(),
          zoom: 20
        },
        items
      },
      startDate: this.startDate,
      endDate: this.endDate
    };
  }

  getLeaveTypes(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(`${this.leaveTypeUrl}`)
        .pipe(
          debounceTime(0),
          tap((data: any[]) => data)
        )
        .subscribe((response: any) => {
          this.leaveTypes = response;
          resolve(response);
        }, reject);
    });
  }
}
