import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { _HttpClient } from '@delon/theme';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class DepartmentStructureService implements Resolve<any> {
  routeParams: any;
  data: any;
  onItemChanged: BehaviorSubject<any>;

  constructor(private http: _HttpClient) {
    this.onItemChanged = new BehaviorSubject({});
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    this.routeParams = route.params;

    return new Promise((resolve, reject) => {
      Promise.all([this.getItem()]).then(res => {
        resolve(res);
      }, reject);
    });
  }

  getItem(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(`/organisation/${this.routeParams.id}`).subscribe((response: any) => {
        this.data = response;
        console.log('response;', response);
        this.onItemChanged.next(this.data);
        resolve(response);
      }, reject);
    });
  }
}
