import { Inject, Injectable } from '@angular/core';
import { DA_SERVICE_TOKEN, ITokenModel, ITokenService } from '@delon/auth';
import { SettingsService } from '@delon/theme';

@Injectable({ providedIn: 'root' })
export class UserService {
  logout(): void {
    this.tokenSrv.clear();
  }

  get login_url(): string {
    return this.tokenSrv.login_url!;
  }

  get item(): ITokenModel {
    return this.tokenSrv.get()!;
  }

  get isLogin(): boolean {
    return !!this.item.token;
  }

  get name(): string {
    return this.settingsSrv.user.name!;
  }

  get email(): string {
    return this.settingsSrv.user.email!;
  }

  get avatar(): string {
    return this.settingsSrv.user.avatar!;
  }

  constructor(@Inject(DA_SERVICE_TOKEN) private tokenSrv: ITokenService, private settingsSrv: SettingsService) {}
}
