import { MockRequest, MockStatusError } from '@delon/mock';
import { deepCopy, isNum } from '@delon/util';

import { Random } from 'mockjs';
import * as moment from 'moment';

import { Biz360Utils } from 'src/app/shared/utils/biz360';
import { genArr, genContent, genData, genMp } from '../utils';
import { EMPLOYEES } from './_distributors';
import { EXPENSES } from './_expenses';
import { CLINICS } from './_films';

interface DATAType {
  expenses: any;
  films: any;
  distributors: any;
}

const DATA: DATAType = {
  expenses: null,
  films: null,
  distributors: null,
};

function remove<T extends keyof DATAType>(type: T, id: string): any {
  const item = getItem(type, id);
  if (item) {
    DATA[type] = (DATA[type] as any[]).filter((d) => d.id !== id);
    return { msg: 'ok' };
  }
  throw new MockStatusError(503, 'Service Unavailable');
}

function save<T extends keyof DATAType>(type: T, id: string, body: any): any {
  if (!getItem(type, id)) {
    throw new MockStatusError(410, 'GONE');
  }

  let item: any;

  DATA[type] = (DATA[type] as any[]).map((d) => (d.id === id ? (item = { ...d, ...body, updatedAt: moment().toDate() }) : d));
  console.log('post edit', type, body);

  return { msg: 'ok', type: 'edit', id: item.id };
}

function add<T extends keyof DATAType>(type: T, body: any): any {
  const item = {
    ...body,
    id: Biz360Utils.generateGUID(),
    claimDate: moment().toDate(),
    createdAt: moment().toDate(),
    updatedAt: moment().toDate(),
  };
  console.log('post add', type, body);

  checkDATA(type);

  (DATA[type] as any[]).splice(0, 0, item);
  return { msg: 'ok', type: 'add', id: item.id };
}

function genPage<T extends keyof DATAType>(
  type: T,
  queryString: any,
  qField: string = 'name',
  callback: ((data: any) => any) | null = null,
): any {
  const pi = +(queryString.page || 1);
  const ps = +(queryString.size || 10);

  // data
  let data = deepCopy(DATA[type]) as any[];
  if (!data) {
    throw new MockStatusError(404, 'Not Found');
  }

  if (queryString.q) {
    data = data.filter((item) => item[qField].indexOf(queryString.q) > -1);
  }
  if (callback) {
    data = callback(data);
  }

  console.log(queryString, pi, ps);

  let content = [];
  if (isNum(pi) && isNum(ps)) {
    // content = pi > 0 ? data.slice(pi * ps, (pi + 1) * ps) : data.slice(0, ps);
    content = data.slice((pi - 1) * ps, pi * ps);
  } else {
    content = data;
  }

  return {
    page: pi,
    size: ps,
    content,
    totalElements: data.length,
  };
}

function checkDATA<T extends keyof DATAType>(type: T): any {
  switch (type) {
    case 'expenses':
      if (!DATA[type]) {
        DATA[type] = deepCopy([...EXPENSES]);
      }
      break;
    case 'distributors':
      if (!DATA[type]) {
        DATA[type] = deepCopy([...EMPLOYEES]);
      }
      break;
    case 'films':
      if (!DATA[type]) {
        DATA[type] = deepCopy([...CLINICS]);
      }
      break;
    default:
      throw new MockStatusError(501, 'Not Implemented');
  }
}

function getList<T extends keyof DATAType>(type: T, queryString: any): any {
  checkDATA(type);

  return genPage(type, queryString);
}

function getItem<T extends keyof DATAType>(type: T, id: string): any {
  checkDATA(type);

  const item = (DATA[type] as any[]).find((d) => d.id === id);

  if (!item) {
    throw new MockStatusError(404, 'Not Found');
  }

  return { item } as any;
}

export const FILM_RENTAL = {
  'GET /api/film-rental/expenses': (req: MockRequest) => getList('expenses', req.queryString),
  'GET /api/film-rental/expenses/:id': (req: MockRequest) => getItem('expenses', req.params.id),
  'GET /api/film-rental/distributors': (req: MockRequest) => getList('distributors', req.queryString),
  'GET /api/film-rental/distributors/:id': (req: MockRequest) => getItem('distributors', req.params.id),
  'GET /api/film-rental/films': (req: MockRequest) => getList('films', req.queryString),
  'GET /api/film-rental/films/:id': (req: MockRequest) => getItem('films', req.params.id),

  'POST /api/film-rental/expenses': (req: MockRequest) => add('expenses', req.body),
  'POST /api/film-rental/expenses/:id': (req: MockRequest) => save('expenses', req.params.id, req.body),
  'POST /api/film-rental/distributors': (req: MockRequest) => add('distributors', req.body),
  'POST /api/film-rental/distributors/:id': (req: MockRequest) => save('distributors', req.params.id, req.body),
  'POST /api/film-rental/films': (req: MockRequest) => add('films', req.body),
  'POST /api/film-rental/films/:id': (req: MockRequest) => save('films', req.params.id, req.body),

  'DELETE /api/film-rental/expenses/:id': (req: MockRequest) => remove('expenses', req.params.id),
  'DELETE /api/film-rental/distributors/:id': (req: MockRequest) => remove('distributors', req.params.id),
  'DELETE /api/film-rental/films/:id': (req: MockRequest) => remove('films', req.params.id),
};
