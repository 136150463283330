/*
 * Automatically generated by 'ng g ng-alain:plugin icon'
 */

import {
  AppstoreOutline,
  BookOutline,
  CaretRightOutline,
  CustomerServiceOutline,
  DesktopOutline,
  DownloadOutline,
  FolderOutline,
  GlobalOutline,
  MenuFoldOutline,
  PhoneOutline,
  PieChartOutline,
  QuestionOutline,
  RollbackOutline,
  ScanOutline,
  SettingOutline,
  ShareAltOutline,
  StarOutline,
  TableOutline,
  UserOutline
} from '@ant-design/icons-angular/icons';

export const ICONS_AUTO = [
  AppstoreOutline,
  BookOutline,
  CaretRightOutline,
  CustomerServiceOutline,
  DesktopOutline,
  DownloadOutline,
  FolderOutline,
  GlobalOutline,
  MenuFoldOutline,
  PhoneOutline,
  PieChartOutline,
  QuestionOutline,
  RollbackOutline,
  ScanOutline,
  SettingOutline,
  ShareAltOutline,
  StarOutline,
  TableOutline,
  UserOutline
];
