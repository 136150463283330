import { Biz360Utils } from '../utils/biz360';

export class Employee {
  id: string;
  name: string;
  lastName: string;
  avatar: string;
  nickname: string;
  company: string;
  jobTitle: string;
  email: string;
  phone: string;
  address: string;
  birthday?: string;
  partner: string;
  notes?: string;

  constructor(data?: any) {
    data = data || {};
    this.id = data.id || Biz360Utils.generateGUID();
    this.name = data.name;
    this.lastName = data.lastName;
    this.avatar = data.avatar || 'assets/images/avatars/profile.jpg';
    this.nickname = data.nickname;
    this.company = data.company;
    this.jobTitle = data.jobTitle;
    this.email = data.email;
    this.phone = data.phone;
    this.address = data.address;
    this.partner = data.partner;
  }
}
