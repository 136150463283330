export interface Guide {
  id: string;
  title: string;
  subTitle?: string;
  content?: { path?: string; link?: string; downloadUrl?: string; initialPage?: number; startPage?: number; endPage?: number };
  supportId: string;
}

export class Support {
  id!: string;
  title!: string;
  guides!: Guide[] | [];
}
