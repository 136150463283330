import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NzIconModule } from 'ng-zorro-antd/icon';

import { FooterCopyrightComponent } from './footer-copyright.component';
import { FooterGlobalComponent } from './footer-global.component';

const COMPONENTS = [FooterCopyrightComponent, FooterGlobalComponent];

@NgModule({
  imports: [CommonModule, RouterModule, NzIconModule],
  declarations: COMPONENTS,
  exports: COMPONENTS
})
export class FooterModule {}
