import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ResourceService } from '@core';

import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { Category, Policy, Scope, ScopeCategory } from 'src/app/shared/models';

@Injectable()
export class PoliciesService extends ResourceService<Policy | Scope | Category | ScopeCategory> {
  policies$: Observable<Policy[]>;
  scopes$: Observable<Scope[]>;
  categories$: Observable<Category[]>;
  search$: Observable<string>;

  private policiesSubject = new BehaviorSubject<Policy[]>([]);
  private scopesSubjects = new BehaviorSubject<Scope[]>([]);
  private categoriesSubject = new BehaviorSubject<Category[]>([]);
  private searchTextSubject = new BehaviorSubject<string>('');

  constructor(protected httpClient: HttpClient) {
    super(httpClient);
    this.policies$ = this.policiesSubject.asObservable();
    this.scopes$ = this.scopesSubjects.asObservable();
    this.categories$ = this.categoriesSubject.asObservable();
    this.search$ = this.searchTextSubject.asObservable();
  }

  getPolicies(): Observable<Policy[]> {
    return this.apiService.get('policies').pipe(map(policies => policies as Policy[]));
  }

  getScopes(): Observable<Scope[]> {
    return this.apiService.get('scopes').pipe(map(scopes => scopes as Scope[]));
  }

  getCategories(): Observable<Category[]> {
    return this.apiService.get('categories').pipe(map(categories => categories as Category[]));
  }

  onPoliciesChanged(policies: Policy[]): void {
    this.policiesSubject.next(policies);
  }

  onScopesChanged(scopes: Scope[]): void {
    this.scopesSubjects.next(scopes);
  }

  onCategoriesChanged(categories: Category[]): void {
    this.categoriesSubject.next(categories);
  }

  onSearchTextChanged(value: string): void {
    this.searchTextSubject.next(value);
  }

  addScope(item: Scope): Observable<Scope> {
    return this.apiService.post('scopes', item).pipe(map(scope => scope as Scope));
  }

  updateCategory(items: ScopeCategory[], scopeId: string): Observable<Category> {
    let itemToUpdate = new Category();
    itemToUpdate = { ...itemToUpdate, ...items };
    console.log(items, scopeId);
    return this.apiService.put(`/categories/${scopeId}`, itemToUpdate).pipe(map(category => category as Category));
  }
}
