import { NgModule } from '@angular/core';

import { CustomScrollDirective } from './custom-scroll.directive';
import { ScrollbarDirective } from './scrollbar.directive';

const COMPONENTS = [ScrollbarDirective, CustomScrollDirective];

@NgModule({
  declarations: COMPONENTS,
  exports: COMPONENTS
})
export class ScrollbarModule {}
