import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { I18NService, LangType } from '@core';
import { ALAIN_I18N_TOKEN, SettingsService } from '@delon/theme';
import { BooleanInput, InputBoolean } from '@delon/util';
import { NzSafeAny } from 'ng-zorro-antd/core/types';
import { NzPlacementType } from 'ng-zorro-antd/dropdown';

@Component({
  selector: 'langs',
  templateUrl: './langs.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LangsComponent {
  static ngAcceptInputType_showLangText: BooleanInput;

  /** Placement of pop menu */
  @Input() placement: NzPlacementType = 'bottomRight';

  /** Whether to display language text */
  @Input() @InputBoolean() showLangText = false;

  get langs(): NzSafeAny {
    return this.i18n.getLangs();
  }

  get curLangCode(): string {
    return this.settings.layout.lang;
  }

  get text(): string {
    return this.i18n.text;
  }

  constructor(private settings: SettingsService, @Inject(ALAIN_I18N_TOKEN) private i18n: I18NService, @Inject(DOCUMENT) private doc: any) {}

  change(lang: LangType): void {
    this.i18n.use(lang);
    this.settings.setLayout('lang', lang);
    setTimeout(() => this.doc.location.reload());
  }
}
