import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzTagModule } from 'ng-zorro-antd/tag';

import { Biz360PolicyDetailModalComponent } from './detail.component';

@NgModule({
  imports: [CommonModule, NzTagModule, NzIconModule],
  declarations: [Biz360PolicyDetailModalComponent],
  exports: [Biz360PolicyDetailModalComponent],
  providers: []
})
export class Biz360PolicyDetailModalModule {}
