import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Chart } from '@antv/g2';
import { _HttpClient } from '@delon/theme';
// import { DataSummaryComponent } from '../summary.component';
import * as moment from 'moment';
import { Moment } from 'moment';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Subscription } from 'rxjs';

@Component({
  selector: 'data-summary-trade',
  templateUrl: './trade.component.html',
  host: {
    '[class.card]': 'true'
  },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataSummaryTradeComponent implements OnDestroy {
  private ref$!: Subscription;
  private chart!: Chart;
  loading = true;
  data = [];
  time!: Date | number | string;
  top = 120;
  values = ['Annual Leave', 'Sick Leave'];

  constructor(
    // private p: DataSummaryComponent,
    public http: _HttpClient,
    public msg: NzMessageService,
    private ngZone: NgZone,
    private cdr: ChangeDetectorRef
  ) {}

  render(er: ElementRef): void {
    this.ngZone.runOutsideAngular(() => setTimeout(() => this.init(er.nativeElement), 50));
  }

  private init(container: HTMLElement): void {
    const { top } = this;
    const chart = (this.chart = new Chart({
      container,
      autoFit: true,
      height: 500
      // padding: 'auto',
    }));
    // chart.render();

    chart.coordinate().transpose().scale(1, -1);

    chart.tooltip({
      showMarkers: false
    });

    chart.interaction('element-active');

    chart
      .interval()
      .position('task*range')
      .color('status', ['#2FC25B', '#F04864'])
      .animate({
        appear: {
          animation: 'scale-in-x'
        }
      });

    this.attachData();
  }

  private attachData(): void {
    const { chart, ngZone } = this;
    ngZone.run(() => {
      this.loading = true;
      this.cdr.detectChanges();
    });
    this.http
      .get('/summary/leave', {
        startTime: moment(),
        endTime: moment().add(7, 'days')
      })
      .subscribe((res: any) => {
        ngZone.run(() => {
          this.data = res;
          console.log(this.data);
          this.data.forEach((obj: any) => {
            obj.range = [obj.startTime, obj.endTime];
            obj.status = this.values[obj.status];
          });
          this.time = new Date();
          this.loading = false;
          this.cdr.detectChanges();
        });
        ngZone.runOutsideAngular(() => chart.changeData(res));
      });
  }
  ngOnDestroy(): void {
    this.ref$.unsubscribe();
  }
}
