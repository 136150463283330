import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// layout
import { MSLayoutComponent } from '@brand';
import { SimpleGuard } from '@delon/auth';
import { environment } from '@env/environment';
import { UserRouteAccessService } from '@oauth';
// single pages
import { CallbackComponent } from './callback/callback.component';
import { OrganisationModule } from './organisation/organisation.module';

const routes: Routes = [
  // Full pages
  {
    path: '',
    component: MSLayoutComponent,
    canActivate: [],
    children: [
      { path: '', redirectTo: 'dashboards', pathMatch: 'full' },
      {
        path: 'dashboards',
        loadChildren: () => import('./dashboards/dashboards.module').then(m => m.DashboardsModule),
        canActivate: [UserRouteAccessService]
      },
      {
        path: 'hr-management',
        loadChildren: () => import('./hr-management/hr-management.module').then(m => m.HrManagementModule),
        canActivate: [UserRouteAccessService]
      },
      {
        path: 'communications',
        loadChildren: () => import('./communications/communications.module').then(m => m.CommunicationsModule),
        canActivate: [UserRouteAccessService]
      },
      {
        path: 'claims',
        loadChildren: () => import('./claims/claims.module').then(m => m.ClaimsModule),
        canActivate: [UserRouteAccessService]
      },
      {
        path: 'cfm',
        loadChildren: () => import('./cinema-film-management/cinema-film-management.module').then(m => m.CFMModule),
        canActivate: [UserRouteAccessService]
      },
      {
        path: 'access-control',
        loadChildren: () => import('./access-control/access-control.module').then(m => m.AccessControlModule),
        canActivate: [UserRouteAccessService]
      },
      // Exception
      {
        path: 'exception',
        loadChildren: () => import('./exception/exception.module').then(m => m.ExceptionModule)
      }
    ]
  },
  // supports
  {
    path: 'supports',
    component: MSLayoutComponent,
    data: { hasAllNav: false, hasSidebar: true },
    canActivate: [UserRouteAccessService],
    children: [{ path: '', loadChildren: () => import('./supports/supports.module').then(m => m.SupportsModule) }]
  },
  // account
  {
    path: 'account',
    loadChildren: () => import('./account/account.module').then(m => m.AccountModule)
  },
  // 单页不包裹Layout
  { path: 'callback/:type', component: CallbackComponent },
  { path: '**', redirectTo: 'exception/404' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // useHash: environment.useHash,
      scrollPositionRestoration: 'top',
      relativeLinkResolution: 'legacy'
    })
  ],
  exports: [RouterModule, OrganisationModule]
})
export class RouteRoutingModule {}
