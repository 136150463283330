import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { Inject, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { I18NService } from '@core';
import { AlainThemeModule, ALAIN_I18N_TOKEN } from '@delon/theme';
import { ThemeBtnModule } from '@delon/theme/theme-btn';
import { DelayModule } from '@shared';
import { NzAnchorModule } from 'ng-zorro-antd/anchor';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { default as en_US } from './ms/_i18n/en-US';
import { default as zh_CN } from './ms/_i18n/zh-CN';
import { default as zh_TW } from './ms/_i18n/zh-TW';
import { MS_COMPONENTS } from './ms/index';
import { MSSharedModule } from './ms/shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    DragDropModule,
    MSSharedModule,
    NzSpinModule,
    NzAnchorModule,
    NzAutocompleteModule,
    NzAvatarModule,
    NzDividerModule,
    NzInputModule,
    NzIconModule,
    AlainThemeModule.forChild(),
    ThemeBtnModule,
    DelayModule
  ],
  declarations: MS_COMPONENTS,
  exports: MS_COMPONENTS
})
export class LayoutModule {
  constructor(@Inject(ALAIN_I18N_TOKEN) i18n: I18NService) {
    i18n.load('zh-CN', zh_CN);
    i18n.load('zh-TW', zh_TW);
    i18n.load('en-US', en_US);
  }
}
