import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { SFButton, SFComponent, SFSchema, SFUISchema } from '@delon/form';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'sf-modal',
  template: `
    <div class="wrap" [scrollbar]>
      <sf
        #sf
        [schema]="schema"
        [ui]="ui"
        [mode]="mode"
        [noColon]="true"
        [formData]="formData"
        [layout]="layout"
        (formChange)="formChange($event)"
        (formValueChange)="formValueChange($event)"
        (formSubmit)="formSubmit($event)"
        (formReset)="formReset($event)"
      >
      </sf>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class Biz360SFModalComponent implements AfterViewInit {
  @ViewChild('sf', { static: false }) readonly sf!: SFComponent;

  @Input() schema: SFSchema = {};
  @Input() ui: SFUISchema = {};
  @Input() mode: 'default' | 'edit' | 'search' = 'default';
  @Input() layout: 'horizontal' | 'vertical' | 'inline' = 'horizontal';
  @Input() formData: any;
  @Input() button!: SFButton | 'none';

  @Output() readonly formChanged = new EventEmitter<any>();
  @Output() readonly formValueChanged = new EventEmitter<any>();

  constructor(private modal: NzModalRef) {}

  // ngOnInit(): void {}

  ngAfterViewInit(): void {
    if (this.button && this.button === 'none') {
      this.sf.button = this.button;
    }
  }

  formChange(e: any): void {
    // console.log(e);
    this.formChanged.emit(e);
  }

  formValueChange(e: any): void {
    // console.log(e);
    this.formValueChanged.emit(e);
  }

  formSubmit(e: any): void {
    if (this.sf.valid) {
      this.modal.close(e);
    }
  }

  formReset(e?: any): void {
    // console.log(e);
    this.sf.refreshSchema(this.schema, this.ui);
    // this.sf.reset();
  }
}
