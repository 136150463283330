/**
 * API Gateway
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AuthenticationStatusDetail { 
    status: AuthenticationStatusDetail.StatusEnum;
    signOutUrl: string;
    signInUrl: string;
}
export namespace AuthenticationStatusDetail {
    export type StatusEnum = 'AUTHENTICATED' | 'UNAUTHENTICATED';
    export const StatusEnum = {
        Authenticated: 'AUTHENTICATED' as StatusEnum,
        Unauthenticated: 'UNAUTHENTICATED' as StatusEnum
    };
}


