import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { _HttpClient } from '@delon/theme';
import moment, { Moment } from 'moment';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LeaveService } from 'src/app/shared/services';
@Component({
  selector: 'leave-calendar',
  templateUrl: './leave-calendar.component.html',
  styleUrls: ['./leave-calendar.component.less'],
  encapsulation: ViewEncapsulation.None
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LeaveCalendarComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('gstcComp', { static: false }) gstc!: any;

  current: Moment;
  leaveTypes: any;

  private unsubscribe$ = new Subject<void>();
  constructor(private leaveService: LeaveService, public http: _HttpClient, private renderer: Renderer2) {
    this.current = moment().startOf('date');
  }

  config$?: Observable<any>;
  config: any;
  gstcState: any;

  ngOnInit(): void {
    this.leaveService.onDataChanged.pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
      this.config = res;
      if (this.gstcState) {
        this.gstcState.update('config.chart.time', this.config.chart.time);
        this.gstcState.update('config.chart.items', this.config.chart.items);
        this.gstcState.update('config.list.rows', this.config.list.rows);
      }
    });

    this.leaveService.getLeaveTypes().then(res => {
      this.leaveTypes = res;
    });
  }

  ngAfterViewInit(): void {
    const element = this.gstc?.gstcElement.nativeElement;

    if (element) {
      let scrollBarEl = element.querySelectorAll('.gantt-schedule-timeline-calendar__horizontal-scroll')[0] as HTMLElement;
      this.renderer.addClass(scrollBarEl, 'scrollbar');
    }
  }

  onState(state: any): void {
    this.gstcState = state;
    // YOU CAN SUBSCRIBE TO CHANGES

    this.gstcState.subscribe('config', (config: any) => {
      console.log('config changed', config);
    });

    this.gstcState.subscribe('config.list.rows', (rows: any) => {
      console.log('rows changed', rows);
    });

    this.gstcState.subscribe('config.list.columns', (columns: any) => {
      console.log('columns changed', columns);
    });

    this.gstcState.subscribe('config.chart', (chart: any) => {
      console.log('chart changed', chart);
    });

    this.gstcState.subscribe('config.chart.items', (items: any) => {
      console.log('items changed', items);
      let earliestDate = moment().startOf('date').valueOf();

      for (let i = 0; i < Object.keys(items).length; i++) {
        if (items[i].time.start < earliestDate) {
          earliestDate = items[i].time.start;
        }
      }
      // this.changeFrom(earliestDate);
    });

    this.gstcState.subscribe(
      'config.chart.items.:id',
      (bulk: any, eventInfo: any) => {
        if (eventInfo.type === 'update' && eventInfo.params.id) {
          const itemId = eventInfo.params.id;
          console.log(`item ${itemId} changed`, this.gstcState.get(`config.chart.items.${itemId}`));
        }
      },
      { bulk: true }
    );
  }

  changeFrom(from: any): void {
    this.gstcState.update('config.chart.time.from', from);
  }

  onChangeMonth(event: Moment): void {
    const startDate = event.startOf('month');
    this.leaveService.onDateChanged.next(startDate);
  }

  onPrevious(): void {
    this.current = this.current.add(-1, 'months');
    this.onChangeMonth(this.current);
  }

  onToday(): void {
    this.current = moment();
    this.onChangeMonth(this.current);
  }

  onNext(): void {
    this.current = this.current.add(1, 'months');
    this.onChangeMonth(this.current);
  }

  isEmpty(obj: any): boolean {
    return Object.keys(obj).length === 0;
  }

  ngOnDestroy(): void {
    const { unsubscribe$ } = this;
    unsubscribe$.next();
    unsubscribe$.complete();
  }
}
