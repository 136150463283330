import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ScrollbarModule } from '../scrollbar';

import { Biz360PdfviewerScrollableModalComponent } from './pdfviewer-scrollable-modal.component';

@NgModule({
  imports: [CommonModule, ScrollbarModule, PdfViewerModule, NzIconModule, NzButtonModule],
  declarations: [Biz360PdfviewerScrollableModalComponent],
  exports: [Biz360PdfviewerScrollableModalComponent],
  providers: []
})
export class Biz360PdfViewerScrollableModalModule {}
