/* eslint-disable import/order */
import { CommonModule } from '@angular/common';
import { NgModule, Type } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// #region your module
import { MSSharedModule } from '@brand';
import { DelonACLModule } from '@delon/acl';
import { DelonFormModule } from '@delon/form';
import { AlainThemeModule } from '@delon/theme';

import { AddressModule } from './components/address';
import { CaptchaBtnModule } from './components/captcha-btn';
import { CustomColumnModule } from './components/custom-column';
import { DelayModule } from './components/delay';
import { EditorModule } from './components/editor';
import { FileManagerModule } from './components/file-manager';
import { FooterModule } from './components/footer';
import { MasonryModule } from './components/masonry';
import { MouseFocusModule } from './components/mouse-focus';
import { ScrollbarModule } from './components/scrollbar';
import { StatusLabelModule } from './components/status-label';
import { SHARED_DELON_MODULES } from './shared-delon.module';
import { SHARED_ZORRO_MODULES } from './shared-zorro.module';

import { Biz360PdfViewerScrollableModalModule } from './components/pdfviewer-scrollable-modal';
import { Biz360AnnouncementDetailModalModule } from './components/announcement-detail-modal';
import { Biz360PolicyDetailModalModule } from './components/policy-detail-modal';
import { Biz360FilterSearchModule } from './components/filter-search';
import { Biz360DeleteModalDialogModule } from './components/delete-modal';
import { Biz360SFModalModule } from './components/sf-modal';
import { Biz360StepsModule } from './components/steps';
import { Biz360SessionExpiredDialogModule } from './components/session-expired-dialog';

const MODULES = [
  MSSharedModule,
  AddressModule,
  DelayModule,
  EditorModule,
  FileManagerModule,
  MasonryModule,
  MouseFocusModule,
  ScrollbarModule,
  StatusLabelModule,
  CaptchaBtnModule,
  CustomColumnModule,
  FooterModule,

  Biz360PdfViewerScrollableModalModule,
  Biz360AnnouncementDetailModalModule,
  Biz360PolicyDetailModalModule,
  Biz360FilterSearchModule,
  Biz360StepsModule,
  Biz360SFModalModule,
  Biz360DeleteModalDialogModule,
  Biz360SessionExpiredDialogModule
];
// #endregion

// #region third libs
import { FullCalendarModule } from '@fullcalendar/angular'; // for FullCalendar!
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import { AngularResizedEventModule } from 'angular-resize-event';
import { CountdownModule } from 'ngx-countdown';
import { NgArrayPipesModule, NgStringPipesModule } from 'ngx-pipes';

FullCalendarModule.registerPlugins([dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]);

const THIRDMODULES: Array<Type<any>> = [
  FullCalendarModule,
  AngularResizedEventModule,
  CountdownModule,
  NgArrayPipesModule,
  NgStringPipesModule
];
// #endregion

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    AlainThemeModule.forChild(),
    DelonACLModule,
    DelonFormModule,
    ...SHARED_DELON_MODULES,
    ...SHARED_ZORRO_MODULES,
    ...MODULES,
    // third libs
    ...THIRDMODULES
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    AlainThemeModule,
    DelonACLModule,
    DelonFormModule,
    ...SHARED_DELON_MODULES,
    ...SHARED_ZORRO_MODULES,
    ...MODULES,
    // third libs
    ...THIRDMODULES
  ],
  providers: []
})
export class SharedModule {}
