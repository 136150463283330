import { HttpClient } from '@angular/common/http';

import { environment } from '@env/environment';
import { ApiService } from './api.service';

export abstract class ResourceService<T> {
  protected apiService: ApiService<T>;

  private baseUrl = environment.baseUrl;

  constructor(protected httpClient: HttpClient) {
    this.apiService = new ApiService(httpClient, this.baseUrl);
  }
}
