import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DelonFormModule } from '@delon/form';
import { NzStepsModule } from 'ng-zorro-antd/steps';

import { Biz360StepsComponent } from './steps.component';

@NgModule({
  imports: [CommonModule, NzStepsModule, DelonFormModule],
  declarations: [Biz360StepsComponent],
  exports: [Biz360StepsComponent],
  providers: []
})
export class Biz360StepsModule {}
