import { Platform } from '@angular/cdk/platform';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, NgZone, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ControlWidget, SFSchemaEnum, SFValue } from '@delon/form';
import { EMPTY, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ScrollbarDirective } from 'src/app/shared/components/scrollbar';

@Component({
  selector: 'sf-custom-select',
  templateUrl: 'custom-select.widget.html',
  preserveWhitespaces: false,
  changeDetection: ChangeDetectionStrategy.OnPush
})
// tslint:disable-next-line: component-class-suffix
export class CustomSelectWidget extends ControlWidget implements OnInit, AfterViewInit, OnDestroy {
  static readonly KEY = 'custom-select';

  isLoading = false;
  isScrolling = false;
  selectedValue: any = undefined;
  optionList: SFSchemaEnum[] = [];

  private subscription: Subscription[] = [];

  compareFn = (o1: any, o2: any) => {
    if (o1 && o2) {
      return typeof o1 === 'object' ? o1.id === o2.id : o1 === o2;
    }
    return o1 === o2;
    // tslint:disable-next-line: semicolon
  };

  asyncData = () => (this.ui?.asyncData ? this.ui.asyncData() : EMPTY);

  ngOnInit(): void {
    if (this.formProperty?.formData) {
      this.selectedValue = this.formProperty?.formData;
    }
    // console.log(this.selectedValue, this.formProperty.valid, this.formProperty);

    this.subscription.push(
      this.asyncData()
        .pipe(
          tap((res: SFSchemaEnum[]) => {
            this.isLoading = false;
            this.isScrolling = false;
            this.optionList = res as any[];

            this.detectChanges(true);
            // console.log(res);
          })
        )
        .subscribe()
    );
  }

  ngOnDestroy(): void {
    this.subscription.forEach(sub => sub.unsubscribe());
  }

  reset(value: string): void {
    this.selectedValue = value;
  }

  change(value: SFValue | SFValue[]): void {
    if (this.ui?.change) {
      const orgData = this.optionList.find(opt => opt.value === value);
      this.ui.change(value, orgData);
    }
    this.setValue(value);
  }

  onSearch(event: any): void {
    if (this.ui?.onSearch) {
      this.isLoading = true;
      this.ui.onSearch(event, this.formProperty);
    }
  }

  scrollToBottom(): void {
    if (this.ui?.scrollToBottom) {
      // this.isScrolling = true;
      this.ui.scrollToBottom();
    }
  }
}
