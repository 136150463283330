import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { NzIconModule } from 'ng-zorro-antd/icon';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ScrollbarModule } from '../scrollbar';

import { Biz360AnnouncementDetailModalComponent } from './detail.component';

@NgModule({
  imports: [CommonModule, ScrollbarModule, PdfViewerModule, NzIconModule],
  declarations: [Biz360AnnouncementDetailModalComponent],
  exports: [Biz360AnnouncementDetailModalComponent],
  providers: []
})
export class Biz360AnnouncementDetailModalModule {}
