import { HttpClient, HttpErrorResponse, HttpEvent, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

export class ApiService<T> {
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  private params = new HttpParams().set('pageNumber', '0').set('pageSize', '10');
  private httpOptions = { headers: this.headers, params: this.params };

  constructor(private httpClient: HttpClient, private baseUrl: string) {}

  get(path: string, params?: HttpParams): Observable<T | any> {
    this.httpOptions.params = !!params ? params : this.httpOptions.params;
    return this.httpClient.get<T>(`${this.baseUrl}/${path}`, this.httpOptions).pipe(
      retry(1),
      catchError(error => this.handleError(error))
    );
  }

  post(path: string, data: Partial<T>): Observable<T | any> {
    return this.httpClient.post<T>(`${this.baseUrl}/${path}`, data, this.httpOptions).pipe(
      retry(1),
      catchError(error => this.handleError(error))
    );
  }

  put(path: string, data: T): Observable<T | any> {
    return this.httpClient.put<T>(`${this.baseUrl}/${path}`, data, this.httpOptions).pipe(
      retry(1),
      catchError(error => this.handleError(error))
    );
  }

  delete(path: string, ids: string | string[]): Observable<T | any> {
    return this.httpClient.delete<T>(`${this.baseUrl}/${path}/${ids}`, this.httpOptions).pipe(
      retry(1),
      catchError(error => this.handleError(error))
    );
  }

  patch(path: string, data: T[]): Observable<T[]> {
    return this.httpClient.patch<T[]>(`${this.baseUrl}/${path}`, data, this.httpOptions).pipe(
      retry(1),
      catchError(error => this.handleError(error))
    );
  }

  send(path: string, data: FormData): Observable<HttpEvent<FormData> | any> {
    // !! temp fix for X-XSRF-TOKEN in httpheader error
    const baseUrl = this.baseUrl.replace('http:', '');

    return this.httpClient
      .post<FormData>(`${baseUrl}/${path}`, data, {
        reportProgress: true,
        responseType: 'json',
        observe: 'events'
      })
      .pipe(
        // retry(1),
        catchError(error => this.handleError(error))
      );
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    let message = '';
    if (error.error instanceof ErrorEvent) {
      message = `An error occurred: , ${error.error.message}`;
    } else {
      message = `Server returned sode: ${error.status}, status: ${error.statusText}, url: ${error.url}, message: ${JSON.stringify(
        error.error
      )} `;
    }
    message = `${message}\n Something bad happened; please try again later.`;
    console.error(message);

    return throwError(error);
  }
}
