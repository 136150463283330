import { MockRequest, MockStatusError } from '@delon/mock';
import { deepCopy, isNum } from '@delon/util';

import * as _ from 'lodash';
import * as moment from 'moment';

import { Biz360Utils } from 'src/app/shared/utils/biz360';
import { MEDICALS } from '../claims/_medicals';
import { CLINICS } from './_clinics';
import { EMPLOYEES } from './_employees';

interface DATAType {
  medicals: any;
  employees: any;
  clinics: any;
}

const DATA: DATAType = {
  medicals: null,
  employees: null,
  clinics: null,
};

function remove<T extends keyof DATAType>(type: T, id: string): any {
  const item = getItem(type, id);
  if (item) {
    DATA[type] = (DATA[type] as any[]).filter((d) => d.id !== id);
    return { msg: 'ok' };
  }
  throw new MockStatusError(503, 'Service Unavailable');
}

function save<T extends keyof DATAType>(type: T, id: string, body: any): any {
  if (!getItem(type, id)) {
    throw new MockStatusError(410, 'GONE');
  }

  let item: any;

  DATA[type] = (DATA[type] as any[]).map((d) => (d.id === id ? (item = { ...d, ...body, updatedAt: moment().toDate() }) : d));
  console.log('post edit', type, body);

  return { msg: 'ok', type: 'edit', id: item.id };
}

function add<T extends keyof DATAType>(type: T, body: any): any {
  const item = {
    ...body,
    id: Biz360Utils.generateGUID(),
    claimDate: moment().toDate(),
    createdAt: moment().toDate(),
    updatedAt: moment().toDate(),
  };
  console.log('post add', type, body);

  checkDATA(type);

  (DATA[type] as any[]).splice(0, 0, item);
  return { msg: 'ok', type: 'add', id: item.id };
}

function genPage<T extends keyof DATAType>(
  type: T,
  queryString: any,
  qField: string = 'name',
  callback: ((data: any) => any) | null = null,
): any {
  const pi = +(queryString.page || 1);
  const ps = +(queryString.size || 10);

  // data
  let data = deepCopy(DATA[type]) as any[];
  if (!data) {
    throw new MockStatusError(404, 'Not Found');
  }

  if (queryString.q) {
    // data = data.filter((item) => item[qField].indexOf(queryString.q) > -1);
    const matchesProperty: string[] = _.flattenDeep<string[]>(queryString.q.split(',').map((group: string) => group.split(':'))).map(
      (item: string) => {
        // tslint:disable-next-line: quotemark
        const reg = "'";
        return item.replace(reg, '');
      },
    );
    data = _.filter(data, matchesProperty);
    console.log(matchesProperty, data);
  }
  if (callback) {
    data = callback(data);
  }

  console.log(queryString, pi, ps);

  let content = [];
  if (isNum(pi) && isNum(ps)) {
    // content = pi > 0 ? data.slice(pi * ps, (pi + 1) * ps) : data.slice(0, ps);
    content = data.slice((pi - 1) * ps, pi * ps);
  } else {
    content = data;
  }

  if (queryString.sort) {
    const iteratees = queryString.sort.split(',').map((group: string) => group.split('|')[0]);
    const orders = queryString.sort.split(',').map((group: string) => group.split('|')[1]);

    console.log(iteratees, orders);
    content = _.orderBy(content, iteratees, orders);
  }

  return {
    page: pi,
    size: ps,
    content,
    totalElements: data.length,
  };
}

function checkDATA<T extends keyof DATAType>(type: T): any {
  switch (type) {
    case 'medicals':
      if (!DATA[type]) {
        DATA[type] = deepCopy([...MEDICALS]);
      }
      break;
    case 'employees':
      if (!DATA[type]) {
        DATA[type] = deepCopy([...EMPLOYEES]);
      }
      break;
    case 'clinics':
      if (!DATA[type]) {
        DATA[type] = deepCopy([...CLINICS]);
      }
      break;
    default:
      throw new MockStatusError(501, 'Not Implemented');
  }
}

function getList<T extends keyof DATAType>(type: T, queryString: any): any {
  checkDATA(type);

  return genPage(type, queryString);
}

function getItem<T extends keyof DATAType>(type: T, id: string): any {
  checkDATA(type);

  const item = (DATA[type] as any[]).find((d) => d.id === id);

  if (!item) {
    throw new MockStatusError(404, 'Not Found');
  }

  return { item } as any;
}

export const CLAIMS = {
  'GET /api/medicals': (req: MockRequest) => getList('medicals', req.queryString),
  'GET /api/medicals/:id': (req: MockRequest) => getItem('medicals', req.params.id),
  'GET /api/employees': (req: MockRequest) => getList('employees', req.queryString),
  'GET /api/clinics': (req: MockRequest) => getList('clinics', req.queryString),
  'GET /api/clinics/:id': (req: MockRequest) => getItem('clinics', req.params.id),

  'POST /api/medicals': (req: MockRequest) => add('medicals', req.body),
  'POST /api/medicals/:id': (req: MockRequest) => save('medicals', req.params.id, req.body),
  'POST /api/clinics': (req: MockRequest) => add('clinics', req.body),
  'POST /api/clinics/:id': (req: MockRequest) => save('clinics', req.params.id, req.body),

  'DELETE /api/medicals/:id': (req: MockRequest) => remove('medicals', req.params.id),
  'DELETE /api/clinics/:id': (req: MockRequest) => remove('clinics', req.params.id),
};
