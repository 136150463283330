import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { SFSchema, SFUISchema } from '@delon/form';

@Component({
  selector: 'steps-container',
  templateUrl: 'steps.component.html'
})
export class Biz360StepsComponent {
  @Input() schema: SFSchema = {};

  @Input() ui: SFUISchema = {};

  current = 0;

  index = 'Search Collections';

  pre(): void {
    this.current -= 1;
    this.changeContent();
  }

  next(): void {
    this.current += 1;
    this.changeContent();
  }

  done(): void {
    console.log('done');
  }

  constructor() {}

  changeContent(): void {
    switch (this.current) {
      case 0: {
        this.index = 'Search Collections';
        break;
      }
      case 1: {
        this.index = 'Select Collection';
        break;
      }
      case 2: {
        this.index = 'Add Line Item';
        break;
      }
      default: {
        this.index = 'error';
      }
    }
  }
}
