import { MockRequest, MockStatusError } from '@delon/mock';
import { deepCopy } from '@delon/util';
import { Category, Policy, Scope } from 'src/app/shared/models';

const POLICY_DATA: Policy[] = [
  {
    id: '001',
    title: 'PDPA Policy for IT',
    scope: { id: 'scope2', name: 'IT Department' },
    category: { key: '1', value: 'PDPA' },
    content: {
      html:
        '<p>Dear All,</p><p><br />Please see attached for the following documents:</p><p>1. Shaw Organisation PDPA Seminar &amp;ndash; Presentation Slides<br />Presentation slides from the PDPA seminar on 27th September advising on guidelines to adhere to.</p><p>2. PDPA Guidelines for IT Department<br />PDPA guidelines that we should adhere to within the department.</p><p><br />Kindly go through the documents in order, so that you can have a better understanding of its implications and importance.<br />You may wish to keep them so that you may easily reference back to them</p><p>If you wish to add on, or is unclear of any parts, please feel free to get back to me for clarification.</p><p><br />Thanks and Regards,<br />Amelia</p><p>&nbsp;</p>',
      attachments: [
        { name: 'PDPA Guidelines for IT Department', type: 'pdf', path: '/assets/documents/PDPA Guidelines for IT Department.pdf' },
        {
          name: 'Shaw Organisation PDPA Seminar - Presentation Slides (DNN 27 Sep 2019)',
          type: 'pdf',
          path: '/assets/documents/Shaw Organisation PDPA Seminar - Presentation Slides (DNN 27 Sep 2019).pdf',
        },
      ],
    },
    updatedOn: new Date('2020-02-10T04:05:00Z'),
  },
  // {
  //   id: '002',
  //   title: 'Equipment  Policy',
  //   scope: { id: 'scope1', name: 'General' },
  //   category: { key: '3', value: 'IT' },
  //   updatedOn: new Date('2020-02-10T04:05:00Z'),
  // },
  {
    id: '003',
    title: 'BCP IT Policy',
    scope: { id: 'scope2', name: 'IT Department' },
    category: { key: '2', value: 'BCP' },
    updatedOn: new Date('2020-02-10T04:05:00Z'),
  },
  {
    id: '004',
    title: 'Accounting Policy',
    scope: { id: 'scope3', name: 'Finance' },
    category: { key: '1', value: 'PDPA' },
    updatedOn: new Date('2020-02-10T04:05:00Z'),
  },
  {
    id: '005',
    title: 'BCP HR Policy',
    scope: { id: 'scope4', name: 'HR' },
    category: { key: '2', value: 'BCP' },
    updatedOn: new Date('2020-02-10T04:05:00Z'),
  },
  {
    id: '006',
    title: 'Personal Data Protection Policy',
    scope: { id: 'scope1', name: 'General' },
    category: { key: '1', value: 'PDPA' },
    content: {
      html:
        '<p>Hi All,</p><p>As you all may be aware, the Personal Data Protection Act 2012 (PDPA) which governs the collection, use, disclosure and care of personal data has come into effect since 2012. The PDPA ensures a baseline standard of protection for personal data across the economy by complementing sector-specific legislative and regulatory frameworks.&nbsp; It also recognises both the rights of individuals to protect their personal data, including rights of access and correction, and the needs of organisations to collect, use or disclose personal data for legitimate and reasonable purposes.</p><p>Please read through the Company&rsquo;s HR Personal Data Protection policy in relation to the collection and retention of the personal information of our staff by clicking on the link below.</p><p><a href="https://forms.gle/C2W2LCxtBe64ZUob6" target="_blank">https://forms.gle/C2W2LCxtBe64ZUob6</a></p><p>&nbsp;</p><p>Every staff is required to read through the policy and acknowledge receipt by submitting the form by 31 January 2020.</p><p>Please do not hesitate to contact anyone in the HR team if you require any clarification on the policy.</p><p>Best regards,</p><p>HR Team</p>',
      attachments: [{ name: 'PDPA Policy', type: 'pdf', path: '/assets/documents/PDPA Policy.pdf' }],
    },
    updatedOn: new Date('2020-02-10T04:05:00Z'),
  },
  {
    id: '007',
    title: 'IT Procurement  Policy',
    scope: { id: 'scope2', name: 'IT Department' },
    category: { key: '3', value: 'IT' },
    updatedOn: new Date('2020-02-10T04:05:00Z'),
  },
  {
    id: '008',
    title: 'Dress Code Policy',
    scope: { id: 'scope4', name: 'HR' },
    category: { key: '3', value: 'HR' },
    updatedOn: new Date('2020-02-10T04:05:00Z'),
  },
];

const CATEGORY_DATA: Category[] = [
  {
    id: 'scope1',
    scopeCategories: [
      { key: '0', value: 'ALL' },
      { key: '1', value: 'PDPA' },
      // { key: '2', value: 'BCP' },
      // { key: '3', value: 'IT' },
      // { key: '4', value: 'HR' },
    ],
  },
  {
    id: 'scope2',
    scopeCategories: [
      { key: '0', value: 'ALL' },
      { key: '1', value: 'PDPA' },
      { key: '2', value: 'BCP' },
      { key: '3', value: 'IT' },
    ],
  },
  {
    id: 'scope3',
    scopeCategories: [
      { key: '0', value: 'ALL' },
      { key: '1', value: 'PDPA' },
      { key: '2', value: 'BCP' },
    ],
  },
  {
    id: 'scope4',
    scopeCategories: [
      { key: '0', value: 'ALL' },
      { key: '1', value: 'PDPA' },
      { key: '2', value: 'BCP' },
      { key: '3', value: 'HR' },
    ],
  },
];

const SCOPE_DATA: Scope[] = [
  { id: 'scope1', name: 'General' },
  { id: 'scope2', name: 'IT Department' },
  { id: 'scope3', name: 'Finance Department' },
  { id: 'scope4', name: 'HR' },
];

function generateGUID(): string {
  // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
  function S4(): string {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  return S4() + S4();
}

function getIdx(id: string): number {
  const idx = POLICY_DATA.findIndex((w) => w.id === id);
  if (!idx) {
    throw new MockStatusError(404);
  }
  return idx;
}

function saveData(id: string, value: any): any {
  const item = POLICY_DATA.find((w) => w.id === id);
  if (!item) {
    return { msg: 'Data not found!' };
  }
  Object.assign(item, value);
  return { msg: 'ok' };
}

export const POLICIES = {
  'GET /api/policies': () => deepCopy(POLICY_DATA),
  'GET /policies:id': (req: MockRequest) => POLICY_DATA.find((w) => w.id === req.params.id),
  'POST /policies/:id': (req: MockRequest) => saveData(req.params.id, req.body),
  'POST /policies': (req: MockRequest) => {
    const id = req.body.id || 0;
    if (id > 0) {
      const idx = getIdx(id);
      POLICY_DATA[idx] = { ...POLICY_DATA[idx], ...req.body };
      return { msg: 'ok', item: POLICY_DATA[idx] };
    }

    const item = { ...req.body };
    POLICY_DATA.push(item);
    return { msg: 'ok', item };
  },
  'DELETE /policies/:id': (req: MockRequest) => {
    const idx = getIdx(req.params.id || 0);
    POLICY_DATA.splice(idx, 1);
    return { msg: 'ok' };
  },
  // 'POST /policies/move': (req: MockRequest) => {
  //   const idx = getIdx(req.body.from || 0);
  //   POLICY_DATA[idx].parent_id = req.body.to || 0;
  //   return { msg: 'ok', item: POLICY_DATA[idx] };
  // },

  'GET /api/categories': () => deepCopy(CATEGORY_DATA),
  'GET /categories:id': (req: MockRequest) => CATEGORY_DATA.find((w) => w.id === req.params.id),
  'POST /categories/:id': (req: MockRequest) => saveData(req.params.id, req.body),
  'POST /categories': (req: MockRequest) => {
    const id = req.body.id || 0;
    if (id > 0) {
      const idx = getIdx(id);
      CATEGORY_DATA[idx] = { ...CATEGORY_DATA[idx], ...req.body };
      return { msg: 'ok', item: CATEGORY_DATA[idx] };
    }

    const item = { ...req.body };
    CATEGORY_DATA.push(item);
    return { msg: 'ok', item };
  },
  'DELETE /categories/:id': (req: MockRequest) => {
    const idx = getIdx(req.params.id || 0);
    CATEGORY_DATA.splice(idx, 1);
    return { msg: 'ok' };
  },
  // 'POST /policies/move': (req: MockRequest) => {
  //   const idx = getIdx(req.body.from || 0);
  //   CATEGORY_DATA[idx].parent_id = req.body.to || 0;
  //   return { msg: 'ok', item: CATEGORY_DATA[idx] };
  // },

  'GET /api/scopes': () => deepCopy(SCOPE_DATA),
  'GET /scopes:id': (req: MockRequest) => SCOPE_DATA.find((w) => w.id === req.params.id),
  'POST /scopes/:id': (req: MockRequest) => saveData(req.params.id, req.body),
  'POST /scopes': (req: MockRequest) => {
    const id = req.body.id || 0;
    if (id > 0) {
      const idx = getIdx(id);
      SCOPE_DATA[idx] = { ...SCOPE_DATA[idx], ...req.body };
      return { msg: 'ok', item: SCOPE_DATA[idx] };
    }

    const item = { ...req.body };
    SCOPE_DATA.push(item);
    return { msg: 'ok', item };
  },
  'DELETE /scopes/:id': (req: MockRequest) => {
    const idx = getIdx(req.params.id || 0);
    SCOPE_DATA.splice(idx, 1);
    return { msg: 'ok' };
  },
  // 'POST /scopes/move': (req: MockRequest) => {
  //   const idx = getIdx(req.body.from || 0);
  //   SCOPE_DATA[idx].parent_id = req.body.to || 0;
  //   return { msg: 'ok', item: SCOPE_DATA[idx] };
  // },
};
