/*
 * 以下部分模拟数据来自阿里云控制台
 * https://home.console.aliyun.com/
 */
import { MockRequest, MockStatusError } from '@delon/mock';
import { deepCopy } from '@delon/util';
import { Random } from 'mockjs';
import * as moment from 'moment';
import { genArr, genBP, genContent, genData, genMp } from './utils';

let MOCKID = 1;
interface DATAType {
  home: any;
  domain: any;
  domainLog: any;
  dnsGroup: any;
  dnsSetting: any;
  apis: any;
  claims: any;
  region: any;
  leaves: any;
  leaveTypes: any;
  summary_status: any;
  summary_month: any;
  summary_healthy: any;
  help_categories: any;
  help_product_menus: any;
  notifications: any;
  user: any;
  userDefaultAvatar: any;
  finance: any;
  organisation: any;
}

const DATA: DATAType = {
  home: null,
  domain: null,
  domainLog: null,
  dnsGroup: null,
  dnsSetting: null,
  apis: null,
  claims: null,
  region: null,
  leaves: null,
  leaveTypes: null,
  summary_status: null,
  summary_month: null,
  summary_healthy: null,
  help_categories: null,
  help_product_menus: null,
  notifications: null,
  user: null,
  userDefaultAvatar: null,
  finance: null,
  organisation: null,
};

function getIdx<T extends keyof DATAType>(type: T, id: number): number {
  id = +id;
  console.log('idx', DATA);
  const idx = DATA[type].findIndex((w: any) => w.id === id);
  if (idx === -1) {
    throw new MockStatusError(404);
  }
  return idx;
}

function save<T extends keyof DATAType>(type: T, body: any): { msg: string; item: any; type: string } {
  const id = body.id || 0;
  if (id > 0) {
    const idx = getIdx(type, id);
    DATA[type][idx] = { ...DATA[type][idx], ...body };
    return { msg: 'ok', item: DATA[type][idx], type: 'edit' };
  }

  const sorted = DATA[type].sort((a: any, b: any) => b.id - a.id);
  const item = { ...body, id: sorted.length > 0 ? sorted[0].id + 1 : 1 };
  console.log('post add', type, body, item);
  (DATA[type] as any[]).splice(0, 0, item);
  return { msg: 'ok', item, type: 'add' };
}

function del<T extends keyof DATAType>(type: T, p: any): any {
  console.log('Type', type);
  const cid = +(p.cid || '0');
  let list: any[] = DATA[type];
  if (cid > 0) {
    list = DATA[type].find((w: any) => w.id === cid).list;
  }

  p.id
    .toString()
    .split(',')
    .forEach((id: string) => {
      const idx = list.findIndex((w) => w.id === id);
      list.splice(idx, 1);
    });
  return { msg: 'ok' };
}

function genPage<T extends keyof DATAType>(
  type: T,
  queryString: any,
  qField: string = 'name',
  callback: ((data: any) => any) | null = null,
): any {
  const pi = +(queryString.pi || 1);
  const ps = +(queryString.ps || 10);
  // data
  let data = deepCopy(DATA[type]);
  if (queryString.q) {
    data = data.filter((item: any) => item[qField].indexOf(queryString.q) > -1);
  }
  if (callback) {
    data = callback(data);
  }
  return {
    total: data.length,
    list: data.slice((pi - 1) * ps, pi * ps),
  };
}

// #region region

function regionList(): any {
  if (DATA.region) {
    return DATA.region;
  }
  DATA.region = [
    {
      id: 'cn-qingdao',
      country: 'cn',
      cnName: '华北1（青岛）',
      enName: 'China (Qingdao)',
      district_cnName: '亚太',
      district_enName: 'Asia Pacific',
    },
    {
      id: 'cn-beijing',
      country: 'cn',
      cnName: '华北2（北京）',
      enName: 'China (Beijing)',
      district_cnName: '亚太',
      district_enName: 'Asia Pacific',
    },
    {
      id: 'cn-hangzhou',
      country: 'cn',
      cnName: '华东1（杭州）',
      enName: 'China (Hangzhou)',
      district_cnName: '亚太',
      district_enName: 'Asia Pacific',
    },
    {
      id: 'cn-shanghai',
      country: 'cn',
      cnName: '华东2（上海）',
      enName: 'China (Shanghai)',
      district_cnName: '亚太',
      district_enName: 'Asia Pacific',
    },
    {
      id: 'cn-shenzhen',
      country: 'cn',
      cnName: '华南1（深圳）',
      enName: 'China (Shenzhen)',
      district_cnName: '亚太',
      district_enName: 'Asia Pacific',
    },
    {
      id: 'cn-hongkong',
      country: 'cn',
      cnName: '香港',
      enName: 'Hong Kong',
      district_cnName: '亚太',
      district_enName: 'Asia Pacific',
    },
    {
      id: 'sg-southeast-1',
      country: 'sg',
      cnName: '新加坡',
      enName: 'Singapore',
      district_cnName: '亚太',
      district_enName: 'Asia Pacific',
    },
    {
      id: 'my-southeast-3',
      country: 'my',
      cnName: '马来西亚（吉隆坡）',
      enName: 'Malaysia (Kuala Lumpur)',
      district_cnName: '亚太',
      district_enName: 'Asia Pacific',
    },
    {
      id: 'jp-northeast-1',
      country: 'jp',
      cnName: '日本（东京）',
      enName: 'Japan (Tokyo)',
      district_cnName: '亚太',
      district_enName: 'Asia Pacific',
    },
    {
      id: 'us-west-1',
      country: 'us',
      cnName: '美国（硅谷）',
      enName: 'US (Silicon Valley)',
      district_cnName: '欧洲与美洲',
      district_enName: 'Europe & Americas',
    },
    {
      id: 'us-east-1',
      country: 'us',
      cnName: '美国（弗吉尼亚）',
      enName: 'US (Virginia)',
      district_cnName: '欧洲与美洲',
      district_enName: 'Europe & Americas',
    },
    {
      id: 'de-central-1',
      country: 'de',
      cnName: '德国（法兰克福）',
      enName: 'Germany (Frankfurt)',
      district_cnName: '欧洲与美洲',
      district_enName: 'Europe & Americas',
    },
    {
      id: 'me-east-1',
      country: 'me',
      cnName: '阿联酋（迪拜）',
      enName: 'UAE (Dubai)',
      district_cnName: '中东与印度',
      district_enName: 'Middle East & India',
    },
    {
      id: 'in-south-1',
      country: 'in',
      cnName: '印度（孟买）',
      enName: 'India (Mumbai)',
      district_cnName: '中东与印度',
      district_enName: 'Middle East & India',
    },
    {
      id: 'eu-west-1',
      country: 'gb',
      cnName: '英国（伦敦）',
      enName: 'UK(London)',
      district_cnName: '欧洲与美洲',
      district_enName: 'Europe & Americas',
    },
    {
      id: 'ap-southeast-5',
      country: 'ap',
      cnName: '印度尼西亚（雅加达）',
      enName: 'Indonesia (Jakarta)',
      district_cnName: '亚太',
      district_enName: 'Asia Pacific',
    },
  ];
  return DATA.region;
}

// #endregion

// #region home

function home(): any {
  if (!DATA.home) {
    DATA.home = {
      consumeMonthly: [
        { month: '11', value: 39, percent: 4.8 },
        { month: '10', value: 2439, percent: 77.6 },
        { month: '09', value: 0, percent: 0 },
        { month: '08', value: 610, percent: 80 },
      ],
      notice: [
        {
          title: '全部公告',
          list: [`PDPA Policy`, `Updates for Movie Benefits 2019`, `Office Dress Code`],
        },
        {
          title: '升级',
          list: [`Zoo Card Reservation`, `Meeting With M1`, `Public Beta Release`],
        },
        {
          title: '安全',
          list: [
            `【漏洞预警】最新Apache Struts远程代码执行漏洞(CVE-2016-1000031)`,
            `【漏洞预警】最新Apache Struts远程代码执行漏洞(CVE-2016-1000031)`,
            `【漏洞预警】最新Apache Struts远程代码执行漏洞(CVE-2016-1000031)`,
          ],
        },
        {
          title: '备案',
          list: [
            `【备案】关于开展2018年全国网站备案信息抽查评估的通知`,
            `【备案】关于开展2018年全国网站备案信息抽查评估的通知`,
            `【备案】关于开展2018年全国网站备案信息抽查评估的通知`,
          ],
        },
        {
          title: '其他',
          list: [
            `【其他】关于开展2018年全国网站备案信息抽查评估的通知`,
            `【其他】关于开展2018年全国网站备案信息抽查评估的通知`,
            `【其他】关于开展2018年全国网站备案信息抽查评估的通知`,
          ],
        },
      ],
      assistant: [
        {
          icon: 'icon-slb',
          title: '推荐好友送云服务器',
          remark: '邀请好友7.1折起购短信包，即可获赠云服务器',
        },
        {
          icon: 'icon-ecs',
          title: '网站建设',
          remark: '提供高性价比的自营建站产品，不满意退款',
        },
        {
          icon: 'icon-sms',
          title: '用户调研（约2分钟）',
          remark: '为了更好地服务于您，我们准备了约2分钟的小问卷，欢迎参加',
        },
      ],
    };
  }
  return deepCopy(DATA.home);
}

// #endregion

// #region domain

function domainList(queryString: any): any {
  if (DATA.domain) {
    return genPage('domain', queryString, 'subject');
  }
  const res: any[] = new Array(11).fill({}).map(() => ({
    id: MOCKID++,
    domain: genArr(['ng-alain.com', 'aliyun.com', 'ant.design']),
    domainType: genArr(['ccTLD', 'New gTLD', 'gTLD']),
    status: genArr(['正常', '已过期', '未认证']),
    registrantDate: genData(10),
    expirationDate: genData(10),
  }));
  // labels
  DATA.domain = res;
  return genPage('domain', queryString, 'subject');
}

function domainGet(id: number): any {
  const idx = getIdx('domain', id || 0);
  const item = { ...DATA.domain[idx] };
  return item;
}

// #endregion

// #region domain log

function domainLogList(queryString: any): any {
  if (DATA.domainLog) {
    return genPage('domainLog', queryString, 'domain');
  }
  const res: any[] = new Array(3).fill({}).map(() => ({
    id: MOCKID++,
    time: genData(1),
    domain: genArr(['ng-alain.com', 'aliyun.com', 'ant.design']),
    message: genArr(['修改备注', '	添加到域名解析列表']),
  }));
  DATA.domainLog = res;
  return genPage('domainLog', queryString, 'domain');
}

// #endregion

// #region dns

function dnsGroup(): any {
  if (DATA.dnsGroup) {
    return DATA.dnsGroup;
  }
  DATA.dnsGroup = [
    { name: '全部域名', id: 0, count: 2 },
    { name: 'test', id: ++MOCKID, count: 0 },
  ];
  return DATA.dnsGroup;
}

// #endregion

// #region dnsSetting

function dnsSettingList(queryString: any): any {
  if (DATA.dnsSetting) {
    return genPage('dnsSetting', queryString, 'rR');
  }
  const res: any[] = new Array(2).fill({}).map(() => ({
    id: MOCKID++,
    type: genArr(['A', 'CNAME']),
    rR: Random.title(1, 1),
    line: 'default',
    value: Random.ip(),
    tTL: 600,
    status: genArr(['ENABLE']),
  }));
  // labels
  DATA.dnsSetting = res;
  return genPage('dnsSetting', queryString, 'rR');
}

function dnsSettingGet(id: number): any {
  const idx = getIdx('dnsSetting', id || 0);
  const item = { ...DATA.dnsSetting[idx] };
  return item;
}

// #endregion

// #region apis

function apisList(queryString: any): any {
  if (DATA.apis) {
    return genPage('apis', queryString, 'name');
  }
  const res: any[] = new Array(3).fill({}).map(() => ({
    id: MOCKID++,
    name: genArr(['User', 'DNS', 'API', 'Log']),
    visibility: genArr(['PUBLIC', 'PRIVATE']),
    group: genArr(['公共组', '用户组']),
    description: genArr(['User desc', 'DNS desc', 'API desc', 'Log desc']),
    deployed: genArr(['线上', '测试', '预发'], 2),
    modified: genData(10),
  }));
  // labels
  DATA.apis = res;
  return genPage('apis', queryString, 'name');
}

// #endregion

function claimsList(queryString: any): any {
  if (DATA.claims) {
    return genPage('claims', queryString, 'name');
  }
  const res: any[] = new Array(22).fill({}).map(() => ({
    id: MOCKID++,
    name: genArr([
      'Keitch',
      'Matlock',
      'Bradbury',
      'Strangeway',
      'Winters',
      'Camacho',
      'Copeland',
      'Estes',
      'Harper',
      'Helen',
      'Henderson',
    ]),
    address: genArr(['933 8th Street Stamford, CT 06902', '906 Valley Road Michigan City, IN 46360']),
    contact: genArr(['+1-202-555-0141', '+1-202-555-0196']),
    postal: genArr(['500600', '500700', '500800', '500900']),
    partner: genArr([true, false]),
    amount: genArr([55.6, 77.55, 89.5, 14.65]),
    status: genArr(['withdraw', 'approved', 'rejected', 'settled', 'pending', 'new']),
    company: genArr(['ranex', 'Geocon', 'Dalthex']),
    treatment: genArr(['outpatient', 'inpatient']),
    remark: genArr(['Lorem ipsum dolor sit amet.', 'Ut enim ad minim veniam.', 'Duis aute irure dolor.']),
    claimDate: moment(new Date(+new Date() - Math.floor(Math.random() * 10000000000))).format('MM/DD/YYYY'),
    createdAt: moment(new Date(+new Date() - Math.floor(Math.random() * 10000000000))).format('MM/DD/YYYY'),
    updatedAt: moment(new Date(+new Date() - Math.floor(Math.random() * 10000000000))).format('MM/DD/YYYY'),
  }));
  // labels
  DATA.claims = res;
  return genPage('claims', queryString, 'name');
}

function claimGet(id: number): any {
  const idx = getIdx('claims', id || 0);
  const item = { ...DATA.claims[idx] };
  return item;
}
// #region help

// >> categories

function helpCategories(): any {
  if (DATA.help_categories) {
    return DATA.help_categories;
  }
  let point = 0;
  DATA.help_categories = new Array(4 * 3).fill({}).map((p, idx) => {
    return {
      title: genArr(['Ng Alain', '用户中心', '账号', '安全'], 1),
      icon: genArr(['cloud', 'meh', 'smile', 'mail'], 1),
      list: new Array(Random.integer(5, 12)).fill({}).map((i, iidx) => ({
        id: ++point,
        title: Random.ctitle(3, 8),
        link: `/help/${point}`,
      })),
    };
  });
  return DATA.help_categories;
}

function helpCategoriesGet(id: number): any {
  const cate = (helpCategories() as any[]).reduce((p: any[], c: any) => (p = p.concat(c.list)), []).find((w) => w.id === id);

  return {
    id,
    cate,
    title: Random.ctitle(5, 10),
    time: genData(-2),
    content: genContent(),
    prev: Random.boolean() && Random.ctitle(5, 10),
    next: Random.ctitle(5, 10),
    recommended: new Array(Random.integer(0, 5)).fill({}).map((i, idx) => ({ id: idx + 1, title: Random.ctitle(5, 10) })),
  };
}

function helpMenu(): any {
  let point = 0;
  return [
    {
      id: ++point,
      title: '注册账号',
      children: [
        { id: ++point, title: '个人实名认证', link: `/help/${point}` },
        { id: ++point, title: '企业实名认证', link: `/help/${point}` },
      ],
    },
    {
      id: ++point,
      title: '常见问题1',
      children: [
        {
          id: ++point,
          title: '菜单项一',
          children: [
            { id: ++point, title: '子项1', link: `/help/${point}` },
            { id: ++point, title: '子项2', link: `/help/${point}` },
          ],
        },
        { id: ++point, title: '个人实名认证', link: `/help/${point}` },
        { id: ++point, title: '企业实名认证', link: `/help/${point}` },
      ],
    },
    {
      id: ++point,
      title: '常见问题2',
      children: [
        { id: ++point, title: '菜单项一', link: `/help/${point}` },
        { id: ++point, title: '个人实名认证', link: `/help/${point}` },
        { id: ++point, title: '企业实名认证', link: `/help/${point}` },
      ],
    },
    {
      id: ++point,
      title: '常见问题3',
      children: [
        { id: ++point, title: '菜单项一', link: `/help/${point}` },
        { id: ++point, title: '个人实名认证', link: `/help/${point}` },
        { id: ++point, title: '企业实名认证', link: `/help/${point}` },
      ],
    },
    {
      id: ++point,
      title: '常见问题4',
      children: [
        { id: ++point, title: '菜单项一', link: `/help/${point}` },
        { id: ++point, title: '个人实名认证', link: `/help/${point}` },
        { id: ++point, title: '企业实名认证', link: `/help/${point}` },
      ],
    },
    { id: ++point, title: '服务', link: `/help/${point}` },
    {
      id: ++point,
      title: '通用参考',
      line: true,
      children: [
        { id: ++point, title: '菜单项一', link: `/help/${point}` },
        { id: ++point, title: '个人实名认证', link: `/help/${point}` },
        { id: ++point, title: '企业实名认证', link: `/help/${point}` },
      ],
    },
  ];
}

// #endregion

// #region notifications

function messageInnerList(queryString: any): any {
  const callback = (data: any[]) => {
    switch (queryString.type) {
      case 'read':
        return (data = data.filter((item) => item.read));
      case 'unread':
        return (data = data.filter((item) => !item.read));
      default:
        return data;
    }
  };
  if (DATA.notifications) {
    return genPage('notifications', queryString, 'type', callback);
  }
  const res: any[] = new Array(20).fill({}).map(() => ({
    id: MOCKID++,
    time: new Date(),
    title: Random.ctitle(),
    read: Random.boolean() && Random.boolean(),
    type: genArr(['优惠活动', '产品消息', '服务消息', '用户消息']),
  }));
  DATA.notifications = res;
  return genPage('notifications', queryString, 'type', callback);
}

function messageInnerCount(): any {
  messageInnerList({});
  const res: any[] = [];
  (DATA.notifications as any[]).forEach((i) => {
    let type = res.find((t) => t.type === i.type);
    if (!type) {
      type = { type: i.type, read: 0, unread: 0, count: 0 };
      res.push(type);
    }
    i.read ? ++type.read : ++type.unread;
    ++type.count;
  });
  return res;
}

function messageInnerGet(id: number): any {
  const idx = getIdx('notifications', id || 0);
  const item = { ...DATA.notifications[idx], content: genContent() };
  return item;
}

// #endregion

// #region user

function user(): any {
  if (DATA.user) {
    return DATA.user;
  }
  DATA.user = {
    id: 1,
    enterpriseYear: 3,
    enterpriseSize: 1,
    mainBizCategory: 1,
    biz: 'ng-alain',
    website: 'https://ng-alain.com/',
    nationalityCode: '中国',
    geo: '310105',
  };
  return DATA.user;
}

function userDefaultAvatar(): any {
  if (DATA.userDefaultAvatar) {
    return DATA.userDefaultAvatar;
  }
  DATA.userDefaultAvatar = new Array(6).fill({}).map((i, idx) => ({ mp: genMp(idx) }));
  return DATA.userDefaultAvatar;
}

// #endregion

// #region finance

function financeList(queryString: any): any {
  if (DATA.finance) {
    return genPage('finance', queryString, 'subject');
  }
  const res: any[] = new Array(11).fill({}).map(() => ({
    id: MOCKID++,
    month: `${new Date().getFullYear()}-01`,
    email: Random.email(),
    type: genArr(['项目一', '项目二', '项目三', '项目四']),
    charge: genArr(['预付', '现付']),
    name: genArr(['商品名一', '商品名二', '商品名三', '商品名四']),
    price1: Random.boolean() ? 0 : Random.integer(0, 100),
    price2: Random.boolean() ? 0 : Random.integer(0, 200),
    price3: Random.boolean() ? 0 : Random.integer(0, 300),
    price4: Random.integer(0, 400),
    price5: Random.integer(0, 500),
    price6: Random.integer(0, 600),
    enabled: Random.boolean(),
    status: Random.integer(1, 3),
    ent_name: Random.ctitle(),
    time: Random.time(),
  }));
  // labels
  DATA.finance = res;
  return genPage('finance', queryString, 'subject');
}

function financeGet(id: number): any {
  const idx = getIdx('finance', id || 0);
  const item = { ...DATA.finance[idx] };
  return item;
}

// #endregion

// #region Thet Lwin

function orgList(queryString: any): any {
  if (DATA.organisation) {
    // return genPage('organisation', queryString, 'subject');
    return DATA.organisation;
  }
  const res: any[] = [
    {
      id: 3,
      department: 'Finance',
      title: 'Finance',
      office: [{ faxNumber: '6235 2518' }],
      childs: [
        {
          name: 'Yap Bock Seng',
          // cssClass: 'ngx-org-ceo',
          team: '',
          image: '',
          title: 'Head - Finance & Admin',
          office: [{ phoneNumber: '6733 1517', extension: 228 }, { phoneNumber: '6305 3228' }],
          mobile: [],
          email: 'bs.yap@shaw.com.sg',
          leave: [
            { startDate: new Date('2020-04-01T00:00:00Z'), endDate: new Date('2020-04-07T00:00:00Z'), leaveType: 'annual_leave' },
            { startDate: new Date('2020-04-09T00:00:00Z'), endDate: new Date('2020-04-15T00:00:00Z'), leaveType: 'paternity_leave' },
          ],
          childs: [
            {
              name: 'Low Huan Swee, Maggie',
              // cssClass: 'ngx-org-ceo',
              team: '',
              image: '',
              title: 'Confidential Secretary',
              office: [{ phoneNumber: '6305 3215', extension: 215 }],
              email: 'maggie.low@shaw.com.sg',
              leave: [{ startDate: new Date('2020-04-07T00:00:00Z'), endDate: new Date('2020-04-09T00:00:00Z'), leaveType: 'sick_leave' }],
              childs: [],
            },
            {
              name: 'Chung Thong Seg',
              // cssClass: 'ngx-org-ceo',
              team: '',
              image: '',
              title: 'Assistant Head - Finance & Admin',
              office: [{ phoneNumber: '6305 3294', extension: 272 }],
              mobile: [],
              email: 'chung.ts@shaw.com.sg',
              leave: [
                { startDate: new Date('2020-04-10T00:00:00Z'), endDate: new Date('2020-04-11T00:00:00Z'), leaveType: 'annual_leave' },
              ],
              childs: [
                {
                  name: 'Liew Hock Chye, Gorlden',
                  // cssClass: 'ngx-org-ceo',
                  team: '',
                  image: '',
                  title: 'Chief Accountant',
                  office: [{ phoneNumber: '6305 3281', extension: 281 }],
                  email: 'gliew@shaw.com.sg',
                  leave: [
                    { startDate: new Date('2020-04-26T00:00:00Z'), endDate: new Date('2020-04-29T00:00:00Z'), leaveType: 'annual_leave' },
                  ],
                  childs: [],
                },
              ],
            },
          ],
        },
        {
          name: '',
          // cssClass: 'ngx-org-head',
          image: '',
          title: 'Accountants',
          type: 'header',
          office: '',
          email: '',
          childs: [
            {
              name: 'Ouyang Yingzhi, Joanne',
              // cssClass: 'ngx-org-ceo',
              team: '',
              image: '',
              title: 'Accountant',
              office: [{ phoneNumber: '6305 3250', extension: 250 }],
              email: 'joanne.ouyang@shaw.com.sg',
              leave: [{ startDate: new Date('2020-04-10T00:00:00Z'), endDate: new Date('2020-04-13T00:00:00Z'), leaveType: 'sick_leave' }],
              childs: [],
            },
            {
              name: 'Puah Cheow Khoon',
              // cssClass: 'ngx-org-ceo',
              team: '',
              image: '',
              title: 'Accountant',
              office: [{ phoneNumber: '6305 3219', extension: 219 }],
              email: 'ck.puah@shaw.com.sg',
              leave: [
                { startDate: new Date('2020-04-04T00:00:00Z'), endDate: new Date('2020-04-05T00:00:00Z'), leaveType: 'annual_leave' },
              ],
              childs: [],
            },
            {
              name: 'Chee Hock Meng',
              // cssClass: 'ngx-org-ceo',
              team: '',
              image: '',
              title: 'Accountant',
              office: [{ phoneNumber: '6305 3229', extension: 229 }],
              email: 'hm.chee@shaw.com.sg',
              leave: [
                { startDate: new Date('2020-04-05T00:00:00Z'), endDate: new Date('2020-04-10T00:00:00Z'), leaveType: 'leave_of_absence' },
              ],
              childs: [],
            },
            {
              name: 'Goh Choo Kuan, Andrew',
              // cssClass: 'ngx-org-ceo',
              team: '',
              image: '',
              title: 'Accountant',
              office: [{ phoneNumber: '6305 3236', extension: 236 }],
              email: 'andrew.goh@shaw.com.sg',
              leave: [
                { startDate: new Date('2020-04-20T00:00:00Z'), endDate: new Date('2020-04-24T00:00:00Z'), leaveType: 'business_trip' },
              ],
              childs: [],
            },
            {
              name: 'Moon Chee Hong',
              // cssClass: 'ngx-org-ceo',
              team: '',
              image: '',
              title: 'Accountant',
              office: [{ phoneNumber: '6305 3262', extension: 162 }],
              email: 'cheehong.moon@shaw.com.sg',
              leave: [
                { startDate: new Date('2020-04-13T00:00:00Z'), endDate: new Date('2020-04-18T00:00:00Z'), leaveType: 'paternity_leave' },
              ],
              childs: [],
            },
          ],
        },
        {
          name: '',
          // cssClass: 'ngx-org-head',
          image: '',
          title: 'Assistant Accountants',
          type: 'header',
          office: '',
          email: '',
          childs: [
            {
              name: 'Tan Bee Hoon, Annie',
              // cssClass: 'ngx-org-ceo',
              team: '',
              image: '',
              title: 'Assistant Accountant',
              office: [{ phoneNumber: '6305 3268', extension: 268 }],
              email: 'annie.tan@shaw.com.sg',
              leave: [
                { startDate: new Date('2020-02-05T00:00:00Z'), endDate: new Date('2020-02-10T00:00:00Z'), leaveType: 'paternity_leave' },
              ],
              childs: [],
            },
            {
              name: 'Ong Meng Biau, George',
              // cssClass: 'ngx-org-ceo',
              team: '',
              image: '',
              title: 'Assistant Accountant',
              office: [{ phoneNumber: '6305 3266', extension: 157 }],
              email: 'george.ong@shaw.com.sg',
              leave: [],
              childs: [],
            },
            {
              name: 'Wang Binbin, Rain',
              // cssClass: 'ngx-org-ceo',
              team: '',
              image: '',
              title: 'Assistant Accountant',
              office: [{ extension: 287 }],
              email: 'rain.wang@shaw.com.sg',
              leave: [],
              childs: [],
            },
            {
              name: 'Leem Chye Cheng, Jassyne',
              // cssClass: 'ngx-org-ceo',
              team: '',
              image: '',
              title: 'Assistant Accountant',
              office: [{ phoneNumber: '6305 3272', extension: 168 }],
              email: 'jassyne.leem@shaw.com.sg',
              leave: [],
              childs: [],
            },
          ],
        },
        {
          name: '',
          // cssClass: 'ngx-org-head',
          image: '',
          title: 'Account Executives',
          type: 'header',
          office: '',
          email: '',
          childs: [
            {
              name: 'Tan Bee Luan, Helen',
              // cssClass: 'ngx-org-ceo',
              team: '',
              image: '',
              title: 'Accounts Executive',
              office: [{ phoneNumber: '6305 3252', extension: 253 }],
              email: 'helen.tan@shaw.com.sg',
              leave: [],
              childs: [],
            },
            {
              name: 'Yap Cheng Hong, Jessy',
              // cssClass: 'ngx-org-ceo',
              team: '',
              image: '',
              title: 'Accounts Executive',
              office: [{ phoneNumber: '6305 3244', extension: 244 }],
              email: 'jessy.yap@shaw.com.sg',
              leave: [],
              childs: [],
            },
            {
              name: 'Ow Wai Ching, Shirley',
              // cssClass: 'ngx-org-ceo',
              team: '',
              image: '',
              title: 'Accounts Executive',
              office: [{ phoneNumber: '6305 3264', extension: 264 }],
              email: 'shirley.ow@shaw.com.sg',
              leave: [],
              childs: [],
            },
            {
              name: 'Er Mui Choo, Elizabeth',
              // cssClass: 'ngx-org-ceo',
              team: '',
              image: '',
              title: 'Accounts Executive',
              office: [{ phoneNumber: '6305 3251', extension: 251 }],
              email: 'elizabeth.er@shaw.com.sg',
              leave: [],
              childs: [],
            },
            {
              name: 'Ho Lee Chin Elsie',
              // cssClass: 'ngx-org-ceo',
              team: '',
              image: '',
              title: 'Accounts Executive',
              office: [{ phoneNumber: '6305 3225', extension: 225 }],
              email: 'elsie.ho@shaw.com.sg',
              leave: [],
              childs: [],
            },
            {
              name: 'Chew Bee Lan',
              // cssClass: 'ngx-org-ceo',
              team: '',
              image: '',
              title: 'Accounts Executive',
              office: [{ phoneNumber: '6305 3274', extension: 179 }],
              email: 'lilian.chew@shaw.com.sg',
              leave: [],
              childs: [],
            },
            {
              name: 'Goh Pau Shun, Pearl',
              // cssClass: 'ngx-org-ceo',
              team: '',
              image: '',
              title: 'Accounts Executive',
              office: [{ phoneNumber: '6305 3299', extension: 177 }],
              email: 'pearl.goh@shaw.com.sg',
              leave: [],
              childs: [],
            },
          ],
        },
        {
          name: '',
          // cssClass: 'ngx-org-head',
          image: '',
          title: 'Accounts Assistant',
          type: 'header',
          office: '',
          email: '',
          childs: [
            {
              name: 'Goh Boon Gee, Eddie',
              // cssClass: 'ngx-org-ceo',
              team: '',
              image: '',
              title: 'Accounts Assistant',
              office: [{ phoneNumber: '6305 3260', extension: 260 }],
              email: 'eddie.goh@shaw.com.sg',
              leave: [],
              childs: [],
            },
            {
              name: 'Foong Wai Chue, Eunice',
              // cssClass: 'ngx-org-ceo',
              team: '',
              image: '',
              title: 'Accounts Assistant',
              office: [{ phoneNumber: '6305 3278', extension: 178 }],
              email: 'eunice.foong@shaw.com.sg',
              leave: [],
              childs: [],
            },
            {
              name: 'Ho Li Fong, Joey',
              // cssClass: 'ngx-org-ceo',
              team: '',
              image: '',
              title: 'Accounts Assistant',
              office: [{ extension: 213 }],
              email: 'joey.ho@shaw.com.sg',
              leave: [],
              childs: [],
            },
            {
              name: 'Tay Eng Leong, Andrew',
              // cssClass: 'ngx-org-ceo',
              team: '',
              image: '',
              title: 'Accounts Assistant',
              office: [{ phoneNumber: '6305 3231', extension: 181 }],
              email: '',
              leave: [],
              childs: [],
            },
            {
              name: 'Lee Juin Wen, Vabien',
              // cssClass: 'ngx-org-ceo',
              team: '',
              image: '',
              title: 'Accounts Assistant',
              office: [{ phoneNumber: '6305 3242', extension: 163 }],
              email: 'lee.jw@shaw.com.sg',
              leave: [],
              childs: [],
            },
            {
              name: 'Chin Jia Lun(Alan)',
              // cssClass: 'ngx-org-ceo',
              team: '',
              image: '',
              title: 'Accounts Assistant',
              office: [{ extension: 146 }],
              email: 'jialun.chin@shaw.com.sg',
              leave: [],
              childs: [],
            },
          ],
        },
      ],
    },
    {
      id: 19,
      department: 'Asian Films(Distriubtion & Marketing)',
      title: 'Asian Films(Distriubtion & Marketing)',
      office: [{ faxNumber: '6735 5039' }],
      childs: [
        {
          name: 'Toh Lan Sin',
          // cssClass: 'ngx-org-ceo team-b',
          team: 'B',
          image: '',
          title: 'VP - Asian Films',
          office: [{ phoneNumber: '6305 3238', extension: 238 }],
          mobile: ['8282 2268'],
          email: 'lansin.toh@shaw.com.sg',
          leave: [],
          childs: [
            {
              name: 'Chay Sook Fun, Carol',
              // cssClass: 'ngx-org-ceo team-a',
              team: 'A',
              image: '',
              title: 'Senior Manager(Distribution & Marketing)',
              office: [{ phoneNumber: '6235 5785', extension: 227 }],
              mobile: ['9090 4366'],
              email: 'carol.chay@shaw.com.sg',
              leave: [],
              childs: [
                {
                  name: 'Au Jia Min, Jaslyn',
                  // cssClass: 'ngx-org-ceo team-a',
                  team: 'A',
                  image: '',
                  title: 'Executive (Distribution & Marketing)',
                  office: [{ phoneNumber: '6305 3298', extension: 147 }],
                  mobile: [],
                  email: 'jaslyn.au@shaw.com.sg',
                  leave: [],
                  childs: [
                    {
                      name: 'Angelica Chong Mei Qi',
                      // cssClass: 'ngx-org-ceo team-b',
                      team: 'B',
                      image: '',
                      title: 'Assitant Exective(Distribution & Marketing)',
                      office: [{ phoneNumber: '6305 3288', extension: 295 }],
                      mobile: [],
                      email: 'angelica.chong@shaw.com.sg',
                      leave: [],
                      childs: [],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 4,
      department: 'Marketing - Sales',
      title: 'Marketing - Sales',
      office: [{ faxNumber: '6734 8831' }],
      childs: [
        {
          name: 'Marianto Rudy',
          // cssClass: 'ngx-org-ceo',
          team: '',
          image: '',
          title: 'HOD - Marketing & Sales',
          office: [{ phoneNumber: '6733 4204', extension: 217 }],
          mobile: ['9880 2063'],
          email: 'rudy@shaw.com.sg',
          leave: [],
          childs: [
            {
              name: 'Chang Sin Ling',
              // cssClass: 'ngx-org-ceo team-a',
              team: 'A',
              image: '',
              title: 'Senior Manager - Theatres, Marketing & Corporate Sales',
              office: [{ phoneNumber: '6887 3349', extension: 214 }],
              mobile: ['9691 1067'],
              email: 'sinling.chang@shaw.com.sg',
              leave: [],
              childs: [
                {
                  name: 'Toh Chaun Lin, Agnes',
                  // cssClass: 'ngx-org-ceo team-b',
                  team: 'B',
                  image: '',
                  title: 'Manager - Theatres & Concessions Marketing',
                  office: [{ phoneNumber: '6305 3232', extension: 280 }],
                  mobile: ['9815 5086'],
                  email: 'agnes.toh@shaw.com.sg',
                  leave: [],
                  childs: [
                    {
                      name: '',
                      // cssClass: 'ngx-org-ceo',
                      team: '',
                      image: '',
                      title: 'Senior Executive - Theatres & Concessions Marketing',
                      office: [{ phoneNumber: '6305 3212', extension: 135 }],
                      mobile: [],
                      email: '',
                      leave: [],
                      childs: [],
                    },
                  ],
                },
                {
                  name: 'Phua Xing Yi, Isabella',
                  // cssClass: 'ngx-org-ceo team-b',
                  team: 'B',
                  image: '',
                  title: 'Senior Executive - Corporate Sales',
                  office: [{ extension: 232 }],
                  mobile: ['9112 3650'],
                  email: 'isabella.phua@shaw.com.sg',
                  leave: [],
                  childs: [
                    {
                      name: 'Loh Su Ching, Sharolyn',
                      // cssClass: 'ngx-org-ceo team-a',
                      team: 'A',
                      image: '',
                      title: 'Executive - Corporate Sales',
                      office: [{ extension: 285 }],
                      mobile: [],
                      email: 'sharolyn.loh@shaw.com.sg',
                      leave: [],
                      childs: [],
                    },
                    {
                      name: 'Amran bin Mohd',
                      // cssClass: 'ngx-org-ceo team-b',
                      team: 'B',
                      image: '',
                      title: 'Executive - Corporate Sales',
                      office: [{ phoneNumber: '6305 3227', extension: 212 }],
                      mobile: ['9384 8120'],
                      email: 'amran.bm@shaw.com.sg',
                      leave: [],
                      childs: [],
                    },
                  ],
                },
                {
                  name: 'Toong Kai Jun',
                  // cssClass: 'ngx-org-ceo team-a',
                  team: 'A',
                  image: '',
                  title: 'Executive - Marketing & Promotion',
                  office: [{ phoneNumber: '6305 3240', extension: 155 }],
                  mobile: [],
                  email: 'toong.kj@shaw.com.sg',
                  leave: [],
                  childs: [],
                },
              ],
            },
            {
              name: 'Tee Yuying, Amanda',
              // cssClass: 'ngx-org-ceo team-a',
              team: 'A',
              image: '',
              title: 'Manager, Premium Theatres, Marketing & Sales',
              office: [{ extension: 135 }],
              mobile: ['9173 5335'],
              email: 'amanda.tee@shaw.com.sg',
              leave: [],
              childs: [],
            },
          ],
        },
      ],
    },

    {
      id: 1,
      department: 'IT',
      title: 'IT',
      childs: [
        {
          name: 'Tham Mei Lean, Amelia',
          // cssClass: 'ngx-org-ceo',
          team: '',
          image: '',
          title: 'HOD-IT',
          office: [{ phoneNumber: '6305 3259', extension: 259 }],
          mobile: ['9459 8076'],
          email: 'amelia@shaw.com.sg',
          leave: [],
          childs: [
            {
              name: 'Tan Kok Heng',
              // cssClass: 'ngx-org-ceo team-b',
              team: 'B',
              image: '',
              title: 'IT Executive',
              office: [{ phoneNumber: '6305 3221', extension: 299 }],
              mobile: ['9788 6884'],
              email: 'derrick.tan@shaw.com.sg',
              leave: [],
              childs: [
                {
                  name: 'Sow Yong Zhou, Andreas',
                  // cssClass: 'ngx-org-ceo team-a',
                  team: 'A',
                  image: '',
                  title: 'IT Support',
                  office: [{ phoneNumber: '6305 3230', extension: 134 }],
                  email: 'andreas.sow@shaw.com.sg',
                  leave: [],
                  childs: [],
                },
              ],
            },
            {
              name: 'Chong Yoke Wah, Janson',
              // cssClass: 'ngx-org-ceo team-b',
              team: 'B',
              image: '',
              title: 'IT Executive',
              office: [{ phoneNumber: '6305 3223', extension: 223 }],
              mobile: ['9836 3721'],
              email: 'janson.chong@shaw.com.sg',
              leave: [],
              childs: [],
            },

            {
              name: 'Myat Min',
              // cssClass: 'ngx-org-ceo team-a',
              team: 'A',
              image: '',
              title: 'Senior Application Developer',
              office: [{ phoneNumber: '6305 3216', extension: 216 }],
              mobile: ['9150 3764'],
              email: 'myat.min@shaw.com.sg',
              leave: [],
              childs: [
                {
                  name: 'Sitt Shein',
                  // cssClass: 'ngx-org-ceo team-a',
                  team: 'A',
                  image: '',
                  title: 'Application Developer',
                  office: [{ extension: 298 }],
                  mobile: ['8723 5393'],
                  email: 'sitt.shein@shaw.com.sg',
                  leave: [],
                  childs: [],
                },
                {
                  name: 'Thet Lwin Oo',
                  // cssClass: 'ngx-org-ceo team-b',
                  team: 'B',
                  image: '',
                  title: 'Application Developer',
                  office: [{ extension: 531 }],
                  mobile: [],
                  email: 'thet.lwin@shaw.com.sg',
                  leave: [],
                  childs: [],
                },
                {
                  name: 'Soe Sanda Nwe',
                  // cssClass: 'ngx-org-ceo team-b',
                  team: 'B',
                  image: '',
                  title: 'Application Developer',
                  office: [{ phoneNumber: '6305 3273', extension: 273 }],
                  mobile: [],
                  email: 'soe.sanda@shaw.com.sg',
                  leave: [],
                  childs: [],
                },
              ],
            },
            {
              name: 'Wee Cheng Bok',
              // cssClass: 'ngx-org-ceo team-a',
              team: 'A',
              image: '',
              title: 'Senior Computer Operator',
              office: [{ extension: 152 }],
              email: 'wee.cb@shaw.com.sg',
              leave: [],
              childs: [],
            },
            {
              name: 'Koh Kah Siang',
              // cssClass: 'ngx-org-ceo team-b',
              team: 'B',
              image: '',
              title: 'Senior Computer Operator',
              office: [{ extension: 164 }],
              email: 'koh.ks@shaw.com.sg',
              leave: [],
              childs: [],
            },
            {
              name: 'Siau Li Nang, Alicia',
              // cssClass: 'ngx-org-ceo team-a',
              team: 'A',
              image: '',
              title: 'Administrative Assistant',
              office: [{ extension: 111 }],
              email: 'alicia.siau@shaw.com.sg',
              leave: [],
              childs: [],
            },
            {
              name: 'Poon Poh Yoke, Catherine',
              // cssClass: 'ngx-org-ceo team-b',
              team: 'B',
              image: '',
              title: 'Senior Key Puncher',
              office: [{ phoneNumber: '6305 3276', extension: 186 }],
              email: 'catherine.poon@shaw.com.sg',
              leave: [],
              childs: [],
            },
          ],
        },
      ],
    },
    {
      id: 9,
      department: 'Real Estate',
      title: 'Real Estate',
      office: [{ faxNumber: '6737 6812' }],
      childs: [
        {
          name: 'Lim Kee Teck, George',
          // cssClass: 'ngx-org-ceo',
          team: '',
          image: '',
          title: 'Head, Real Estate',
          office: [{ phoneNumber: '6238 0807', extension: 270 }],
          mobile: [],
          email: 'georgelim@shaw.com.sg',
          leave: [],
          childs: [
            {
              name: 'Tan Lye Kim, Katherine',
              // cssClass: 'ngx-org-ceo team-b',
              team: 'B',
              image: '',
              title: 'Assistant Leasing Manager(Residential)',
              office: [{ phoneNumber: '6305 3277', extension: 277 }],
              mobile: [],
              email: 'katherine@shaw.com.sg',
              leave: [],
              childs: [],
            },
            {
              name: 'Teo Gek Neo, Florence',
              // cssClass: 'ngx-org-ceo team-a',
              team: 'A',
              image: '',
              title: 'Assistant Leasing Manager(Commercial)',
              office: [{ phoneNumber: '6305 3254', extension: 254 }],
              mobile: [],
              email: 'florence.teo@shaw.com.sg',
              leave: [],
              childs: [
                {
                  name: 'Tay Xiang Rong, Joyce',
                  // cssClass: 'ngx-org-ceo team-a',
                  team: 'A',
                  image: '',
                  title: 'Senior Leasing Executive',
                  office: [{ phoneNumber: '6305 3209', extension: 209 }],
                  mobile: [],
                  email: 'joyce.tay@shaw.com.sg',
                  leave: [],
                  childs: [],
                },
                {
                  name: 'Tan Jia-Sheng, Marcus',
                  // cssClass: 'ngx-org-ceo team-b',
                  team: 'B',
                  image: '',
                  title: 'Senior Leasing Executive',
                  office: [{ phoneNumber: '6305 3280', extension: 261 }],
                  mobile: [],
                  email: 'marcus.tan@shaw.com.sg',
                  leave: [],
                  childs: [],
                },
                {
                  name: 'Desmond Tan',
                  // cssClass: 'ngx-org-ceo team-a',
                  team: 'A',
                  image: '',
                  title: 'Tenant Relations Officer',
                  office: [{ extension: 119 }],
                  mobile: [],
                  email: 'desmond.tan@shaw.com.sg',
                  leave: [],
                  childs: [],
                },
              ],
            },
            {
              name: 'Chong Xan Lung, Sunny',
              // cssClass: 'ngx-org-ceo team-a',
              team: 'A',
              image: '',
              title: 'Assistant Manager - A&P Events',
              office: [{ phoneNumber: '6305 3285', extension: 131 }],
              mobile: ['8481 2125'],
              email: 'sunny.chong@shaw.com.sg',
              leave: [],
              childs: [],
            },
            {
              name: 'Myona Emmie Ling Lai Yee',
              // cssClass: 'ngx-org-ceo team-b',
              team: 'B',
              image: '',
              title: 'Manager - A&P Events',
              office: [{ phoneNumber: '6305 3255', extension: 255 }],
              mobile: ['9683 6313'],
              email: 'emmie@shaw.com.sg',
              leave: [],
              childs: [],
            },
          ],
        },
      ],
    },
    {
      id: 11,
      department: 'Theatre',
      title: 'Theatre',
      office: [{ faxNumber: '6836 2546' }],
      childs: [
        {
          name: 'Chiam Weng Wah, Franky',
          // cssClass: 'ngx-org-ceo',
          team: '',
          image: '',
          title: 'HOD - Theatre Operations',
          office: [{ phoneNumber: '6305 3292', extension: 133 }],
          mobile: ['9737 6066'],
          email: 'franky.chiam@shaw.com.sg',
          leave: [],
          childs: [
            {
              name: 'Tan Ying Ying, Tricia',
              // cssClass: 'ngx-org-ceo team-a',
              team: 'A',
              image: '',
              title: 'Manager - Theatre Operations',
              office: [{ phoneNumber: '6305 3239', extension: 239 }],
              mobile: ['9459 8805'],
              email: 'tricia.tan@shaw.com.sg',
              leave: [],
              childs: [
                {
                  name: 'Amy Vu Dac',
                  // cssClass: 'ngx-org-ceo team-b',
                  team: 'B',
                  image: '',
                  title: 'Assistant Operation Manager',
                  office: [{ extension: 169 }],
                  mobile: [],
                  email: 'amy.vu@shaw.com.sg',
                  leave: [],
                  childs: [
                    {
                      name: 'Jenn Poh Ping Zhi',
                      // cssClass: 'ngx-org-ceo team-b',
                      team: 'B',
                      image: '',
                      title: 'Theatre Administrative Executive',
                      office: [{ extension: 532 }],
                      mobile: [],
                      email: 'jenn.poh@shaw.com.sg',
                      leave: [],
                      childs: [
                        {
                          name: 'Wong Xin Yi, Isbelle',
                          // cssClass: 'ngx-org-ceo',
                          team: '',
                          image: '',
                          title: 'Customer Ralations Officer',
                          office: [{ phoneNumber: '6305 3297', extension: 149 }],
                          mobile: ['8551 8443'],
                          email: 'isabelle.wong@shaw.com.sg',
                          leave: [],
                          childs: [],
                        },
                        {
                          name: 'Filza Nazzira Binte Mohd Nassir',
                          // cssClass: 'ngx-org-ceo',
                          team: '',
                          image: '',
                          title: 'Customer Relations Officer',
                          office: [{ phoneNumber: '6305 3261', extension: 165 }],
                          mobile: ['8388 8874'],
                          email: 'filza.nassir@shaw.com.sg',
                          leave: [],
                          childs: [],
                        },
                      ],
                    },
                    {
                      name: 'Shahrom Bin Abdul Razak',
                      // cssClass: 'ngx-org-ceo team-a',
                      team: 'A',
                      image: '',
                      title: 'Digital Content Executive',
                      office: [{ phoneNumber: '6305 5615', extension: 535 }],
                      mobile: [],
                      email: 'shahrom@shaw.com.sg',
                      leave: [],
                      childs: [],
                    },
                    {
                      name: 'Ravello James Jasmin',
                      // cssClass: 'ngx-org-ceo team-a',
                      team: 'A',
                      image: '',
                      title: 'F&B Executive',
                      office: [{ extension: 296 }],
                      mobile: [],
                      email: 'james.ravello@shaw.com.sg',
                      leave: [],
                      childs: [],
                    },
                  ],
                },
              ],
            },
            {
              name: 'Thor Eu Lee',
              // cssClass: 'ngx-org-ceo team-b',
              team: 'B',
              image: '',
              title: 'Technical Manager',
              office: [{ extension: 138 }],
              mobile: ['9177 4258'],
              email: 'eulee.thor@shaw.com.sg',
              leave: [],
              childs: [
                {
                  name: 'Chiang Chen Ee, Tommy',
                  // cssClass: 'ngx-org-ceo team-b',
                  team: 'B',
                  image: '',
                  title: 'Maintenance Officer',
                  office: [{ extension: 138 }],
                  mobile: ['9621 4267'],
                  email: 'tommy.chiang@shaw.com.sg',
                  leave: [],
                  childs: [],
                },
                {
                  name: 'Ng Kah Leong',
                  // cssClass: 'ngx-org-ceo team-a',
                  team: 'A',
                  image: '',
                  title: 'Technician',
                  office: [{ extension: 138 }],
                  mobile: [],
                  email: 'kahleong.ng@shaw.com.sg',
                  leave: [],
                  childs: [],
                },
                {
                  name: 'Poh Yee Pan Vincent',
                  // cssClass: 'ngx-org-ceo team-a',
                  team: 'A',
                  image: '',
                  title: 'Cinema Technical Officer',
                  office: [{ extension: 138 }],
                  mobile: ['9876 4870'],
                  email: 'vincent.poh@shaw.com.sg',
                  leave: [],
                  childs: [],
                },
                {
                  name: 'Muhammad Fathurrazi',
                  // cssClass: 'ngx-org-ceo team-a',
                  team: 'A',
                  image: '',
                  title: 'Senior Cinema Technical Officer',
                  office: [{ extension: 138 }],
                  mobile: ['9023 0449'],
                  email: 'fathurrazi@shaw.com.sg',
                  leave: [],
                  childs: [],
                },
                {
                  name: 'Adnan Bin Ahmad',
                  // cssClass: 'ngx-org-ceo team-b',
                  team: 'B',
                  image: '',
                  title: 'Cinema Technical Officer',
                  office: [{ extension: 138 }],
                  mobile: ['8139 5804'],
                  email: 'adnan.ahmad@shaw.com.sg',
                  leave: [],
                  childs: [],
                },
              ],
            },
            {
              name: 'Huang Shiyu',
              // cssClass: 'ngx-org-ceo team-b',
              team: 'B',
              image: '',
              title: 'Manager - Concessions',
              office: [{ phoneNumber: '6305 3271', extension: 271 }],
              mobile: ['9173 5457'],
              email: 'shiyu.huang@shaw.com.sg',
              leave: [],
              childs: [
                {
                  name: 'Lee Jia Ern, Joleen',
                  // cssClass: 'ngx-org-ceo team-a',
                  team: 'A',
                  image: '',
                  title: 'F&B Admin Executive',
                  office: [{ phoneNumber: '6305 3296', extension: 151 }],
                  email: 'joleen.lee@shaw.com.sg',
                  leave: [],
                  childs: [],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 5,
      department: 'i.c.e',
      title: 'i.c.e',
      office: [{ faxNumber: '6732 3961' }],
      childs: [
        {
          name: 'Terence Heng',
          // cssClass: 'ngx-org-ceo',
          team: '',
          image: '',
          title: 'VP - I.C.E',
          office: [{ phoneNumber: '6305 3263', extension: 263 }],
          mobile: ['9768 2655'],
          email: 'terence@shaw.com.sg',
          leave: [],
          childs: [
            {
              name: 'Khoo Lee Kok',
              // cssClass: 'ngx-org-ceo',
              team: '',
              image: '',
              title: 'Media Assistant',
              office: [{ phoneNumber: '6305 3279', extension: 279 }],
              mobile: ['8399 3899'],
              email: 'khoo@shaw.com.sg',
              leave: [],
              childs: [],
            },
            {
              name: 'Gonzales Arnie Cadiang',
              // cssClass: 'ngx-org-ceo team-b',
              team: 'B',
              image: '',
              title: 'Infrastructure Lead',
              office: [{ extension: 505 }],
              mobile: ['8569 4854'],
              email: 'arnie.gonzales@sbmedia.sg',
              leave: [],
              childs: [
                {
                  name: 'Wiyanto Jacob',
                  // cssClass: 'ngx-org-ceo',
                  team: '',
                  image: '',
                  title: 'Infrastructure Engineer',
                  office: [{ extension: 523 }],
                  mobile: ['9682 6645'],
                  email: 'jacob.wiyanto@sbmedia.sg',
                  leave: [],
                  childs: [],
                },
              ],
            },
            {
              name: 'Ashok Kumar Aravind',
              // cssClass: 'ngx-org-ceo team-a',
              team: 'A',
              image: '',
              title: 'Application Lead',
              office: [{ extension: 524 }],
              mobile: [],
              email: 'aravind.ashokkumar@sbmedia.sg',
              leave: [],
              childs: [
                {
                  name: 'Flores Kelvin Bondoc',
                  // cssClass: 'ngx-org-ceo team-a',
                  team: 'A',
                  image: '',
                  title: 'Development Engineer',
                  office: [{ extension: 150 }],
                  mobile: [],
                  email: 'kelvinflores@sbmedia.sg',
                  leave: [],
                  childs: [],
                },
                {
                  name: 'Davin Tan Chin Wee',
                  // cssClass: 'ngx-org-ceo team-a',
                  team: 'A',
                  image: '',
                  title: 'Support Engineer',
                  office: [{ extension: 150 }],
                  mobile: [],
                  email: 'davin.tan@sbmedia.sg',
                  leave: [],
                  childs: [],
                },
                {
                  name: 'Allan Tay Khim Meng',
                  // cssClass: 'ngx-org-ceo team-b',
                  team: 'B',
                  image: '',
                  title: 'Support Engineer',
                  office: [{ extension: 150 }],
                  mobile: [],
                  email: 'allantay@sbmedia.sg',
                  leave: [],
                  childs: [],
                },

                {
                  name: 'Mon Thu Thu Htet',
                  // cssClass: 'ngx-org-ceo team-b',
                  team: 'B',
                  image: '',
                  title: 'Application Developer',
                  office: [{ extension: 524 }],
                  mobile: ['9137 0990'],
                  email: 'mon.thu@sbmedia.sg',
                  leave: [],
                  childs: [],
                },
                {
                  name: 'David Samuel Jan Mangubat',
                  // cssClass: 'ngx-org-ceo team-b',
                  team: 'B',
                  image: '',
                  title: 'Application Developer',
                  office: [{ extension: 150 }],
                  mobile: [],
                  email: 'samuel.danao@sbmedia.sg',
                  leave: [],
                  childs: [],
                },
                {
                  name: 'Lopez Sheila Goldelaine',
                  // cssClass: 'ngx-org-ceo team-b',
                  team: 'B',
                  image: '',
                  title: 'IT Support',
                  office: [{ extension: 150 }],
                  mobile: [],
                  email: 'sheilalopez@sbmedia.sg',
                  leave: [],
                  childs: [],
                },
              ],
            },
            {
              name: 'Rupasinghege Videsh Akalanka',
              // cssClass: 'ngx-org-ceo team-a',
              team: 'A',
              image: '',
              title: 'Technology Manager',
              office: [{ extension: 502 }],
              mobile: [],
              email: 'videshakalanka@sbmedia.sg',
              leave: [],
              childs: [],
            },
            {
              name: 'Chee Li Feng, Jasmine',
              // cssClass: 'ngx-org-ceo team-a',
              team: 'A',
              image: '',
              title: 'Manager - Online Media',
              office: [{ phoneNumber: '6305 3270', extension: 160 }],
              mobile: [],
              email: 'jasmine.chee@shaw.com.sg',
              leave: [],
              childs: [
                {
                  name: 'Winnie Goh',
                  // cssClass: 'ngx-org-ceo team-b',
                  team: 'B',
                  image: '',
                  title: 'Media Assistant',
                  office: [{ extension: 549 }],
                  mobile: [],
                  email: 'winnie.goh@sbmedia.sg',
                  leave: [],
                  childs: [],
                },
              ],
            },
            {
              name: 'Bai Jiaying',
              // cssClass: 'ngx-org-ceo team-a',
              team: 'A',
              image: '',
              title: 'Media Executive',
              office: [{ extension: 161 }],
              mobile: [],
              email: 'jiaying.bai@shaw.com.sg',
              leave: [],
              childs: [
                {
                  name: 'Sonia Lee Wei Ling',
                  // cssClass: 'ngx-org-ceo team-b',
                  team: 'B',
                  image: '',
                  title: 'Media Assistant',
                  office: [{ extension: 282 }],
                  mobile: [],
                  email: 'sonialee@sbmedia.sg',
                  leave: [],
                  childs: [],
                },
              ],
            },
            {
              name: 'Loh Guan Chew, Edmund',
              // cssClass: 'ngx-org-ceo team-a',
              team: 'A',
              image: '',
              title: 'Senior Graphic Designer',
              office: [{ phoneNumber: '6305 3265', extension: 265 }],
              mobile: [],
              email: 'edmund.loh@shaw.com.sg',
              leave: [],
              childs: [
                {
                  name: 'Chia Wei Fook, Real',
                  // cssClass: 'ngx-org-ceo team-b',
                  team: 'B',
                  image: '',
                  title: 'Graphic Artist',
                  office: [{ extension: 286 }],
                  mobile: [],
                  email: 'real.chia@shaw.com.sg',
                  leave: [],
                  childs: [],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 2,
      department: 'Film Admin & Support',
      title: 'Film Admin & Support',
      childs: [
        {
          name: 'Frans Sianta Ali Lie',
          // cssClass: 'ngx-org-ceo team-a',
          team: 'A',
          image: '',
          title: 'Manager - Film Admin & Support',
          office: [{ phoneNumber: '6732 9894', extension: 266 }],
          mobile: [],
          email: 'frans@shaw.com.sg',
          leave: [],
          childs: [
            {
              name: 'Chia Ah Nee Ann',
              // cssClass: 'ngx-org-ceo',
              team: '',
              image: '',
              title: 'Clerk',
              office: [{ phoneNumber: '6305 3211', extension: 211 }],
              mobile: [],
              email: 'ann.chia@shaw.com.sg',
              leave: [],
              childs: [],
            },
            {
              name: 'Osman Bin Hussien',
              // cssClass: 'ngx-org-ceo team-b',
              team: 'B',
              image: '',
              title: 'Despatch cum Driver',
              office: [{ extension: 103 }],
              mobile: [],
              email: '',
              leave: [],
              childs: [],
            },
            {
              name: 'Mohamed Al Amin',
              // cssClass: 'ngx-org-ceo',
              team: '',
              image: '',
              title: 'Despatch cum Driver',
              office: [],
              mobile: [],
              email: '',
              leave: [],
              childs: [],
            },
            {
              name: 'Teo Leng Siang',
              // cssClass: 'ngx-org-ceo team-a',
              team: 'A',
              image: '',
              title: 'Despatch cum Driver',
              office: [],
              mobile: ['9754 3408'],
              email: '',
              leave: [],
              childs: [],
            },
            {
              name: 'Lim Beng Hock',
              // cssClass: 'ngx-org-ceo team-a',
              team: 'A',
              image: '',
              title: 'Senior Storekeeper',
              office: [{ extension: 159 }],
              mobile: ['9062 4315'],
              email: '',
              leave: [],
              childs: [],
            },
          ],
        },
        {
          name: 'Huang Shoo Yar, Evelyn',
          // cssClass: 'ngx-org-ceo',
          team: '',
          image: '',
          title: 'Manager - Video Distribution',
          office: [{ phoneNumber: '6305 3245', extension: 245 }],
          mobile: [],
          email: 'evelyn.huang@shaw.com.sg',
          leave: [],
          childs: [],
        },
      ],
    },
    {
      id: 12,
      department: 'Office Support',
      title: 'Office Support',
      childs: [
        {
          name: 'Roszainy Bin Osman',
          // cssClass: 'ngx-org-ceo',
          team: '',
          image: '',
          title: 'Supervisor-Despatch cum Driver',
          office: [{ phoneNumber: '6305 3251', extension: 251 }],
          mobile: ['9270 2874'],
          email: '',
          leave: [],
          childs: [
            {
              name: 'Kong Tick Sing',
              // cssClass: 'ngx-org-ceo',
              team: '',
              image: '',
              title: 'Despatch Clerk',
              office: [{ extension: 141 }],
              mobile: [],
              email: '',
              leave: [],
              childs: [],
            },
            {
              name: 'Shahmad Bin Mashuri',
              // cssClass: 'ngx-org-ceo',
              team: '',
              image: '',
              title: 'Despatch Clerk',
              office: [{ extension: 159 }],
              mobile: ['9082 6597'],
              email: '',
              leave: [],
              childs: [],
            },
            {
              name: 'Abdul Rahman Hidayah',
              // cssClass: 'ngx-org-ceo',
              team: '',
              image: '',
              title: 'Clerk',
              office: [{ phoneNumber: '6305 3251', extension: 251 }],
              mobile: ['9666 8515'],
              email: '',
              leave: [],
              childs: [],
            },
            {
              name: 'Muhd Herry Bin Amin',
              // cssClass: 'ngx-org-ceo',
              team: '',
              image: '',
              title: 'Despatch cum Driver',
              office: [{ extension: 275 }],
              mobile: ['9347 0696'],
              email: '',
              leave: [],
              childs: [],
            },
          ],
        },
        {
          name: 'Nonis Jacinta Carolina',
          // cssClass: 'ngx-org-ceo',
          team: '',
          image: '',
          title: 'Telephone Operator',
          office: [{ phoneNumber: '6305 3210', extension: 210 }],
          mobile: [],
          email: 'carol.nonis@shaw.com.sg',
          leave: [],
          childs: [],
        },
        {
          name: 'Koh Mui Lan, Annie',
          // cssClass: 'ngx-org-ceo',
          team: '',
          image: '',
          title: 'Receptionist',
          office: [{ phoneNumber: '6305 3222', extension: 222 }],
          mobile: [],
          email: '',
          leave: [],
          childs: [],
        },
      ],
    },
    {
      id: 13,
      department: 'Admin Support',
      title: 'Admin Support',
      childs: [
        {
          name: 'Maria Chan May Lee',
          // cssClass: 'ngx-org-ceo',
          team: '',
          image: '',
          title: 'Secretary',
          office: [{ phoneNumber: '6305 3202', extension: 202 }],
          mobile: [],
          email: 'maria.koh@shaw.com.sg',
          leave: [],
          childs: [],
        },
        {
          name: 'Ng Boon Hoon, Pansy',
          // cssClass: 'ngx-org-ceo',
          team: '',
          image: '',
          title: 'Secretary',
          office: [{ phoneNumber: '6733 6813', extension: 269 }],
          mobile: [],
          email: 'pansy.ng@shaw.com.sg',
          leave: [],
          childs: [],
        },
        {
          name: 'Phay Hwe Hung, Theresa',
          // cssClass: 'ngx-org-ceo',
          team: 'A',
          image: '',
          title: 'Secretary',
          office: [{ phoneNumber: '6305 3237', extension: 237 }],
          mobile: [],
          email: 'theresa.phay@shaw.com.sg',
          leave: [],
          childs: [],
        },
        {
          name: 'Carine Looi Wai Leng',
          // cssClass: 'ngx-org-ceo',
          team: 'B',
          image: '',
          title: 'Secretary',
          office: [{ phoneNumber: '6305 3214', extension: 148 }],
          mobile: [],
          email: 'carine.looi@shaw.com.sg',
          leave: [],
          childs: [],
        },
        {
          name: 'Seow Hwee Ling, Aedal',
          // cssClass: 'ngx-org-ceo',
          team: '',
          image: '',
          title: 'Secretary',
          office: [{ phoneNumber: '6305 3287', extension: 182 }],
          mobile: [],
          email: 'aedal.seow@shaw.com.sg',
          leave: [],
          childs: [],
        },
        {
          name: 'Koh May Lin, Pauline',
          // cssClass: 'ngx-org-ceo',
          team: '',
          image: '',
          title: 'Secretary',
          office: [{ phoneNumber: '6305 3246', extension: 246 }],
          mobile: [],
          email: 'pauline.koh@shaw.com.sg',
          leave: [],
          childs: [],
        },
        {
          name: 'Huin Xin Yee',
          // cssClass: 'ngx-org-ceo',
          team: '',
          image: '',
          title: 'Secretary',
          office: [{ phoneNumber: '6305 3205', extension: 205 }],
          mobile: [],
          email: 'huin.xinyee@shaw.com.sg',
          leave: [],
          childs: [],
        },
      ],
    },
    {
      id: 7,
      department: 'Project',
      title: 'Project',
      childs: [
        {
          name: 'Chow Mun Keang',
          // cssClass: 'ngx-org-ceo',
          team: '',
          image: '',
          title: 'HOD-Project',
          office: [{ phoneNumber: '6835 3719', extension: 240 }],
          mobile: [],
          email: 'mkchow@shaw.com.sg',
          leave: [],
          childs: [
            {
              name: 'Ma Yaping',
              // cssClass: 'ngx-org-ceo team-a',
              team: 'A',
              image: '',
              title: 'Assistant Project Manager',
              office: [{ phoneNumber: '6305 3248', extension: 136 }],
              mobile: ['9658 8122'],
              email: 'yaping.ma@shaw.com.sg',
              leave: [],
              childs: [
                {
                  name: 'Goh Kheng Choon',
                  // cssClass: 'ngx-org-ceo team-a',
                  team: 'A',
                  image: '',
                  title: 'Admin Assistant',
                  office: [{ phoneNumber: '6305 3256', extension: 256 }],
                  mobile: ['9297 5739'],
                  email: 'purchase@shaw.com.sg',
                  leave: [],
                  childs: [],
                },
                {
                  name: 'Heng Nan Siang, Doris',
                  // cssClass: 'ngx-org-ceo team-b',
                  team: 'B',
                  image: '',
                  title: 'Clerk',
                  office: [{ phoneNumber: '6305 3286', extension: 132 }],
                  mobile: [],
                  email: '',
                  leave: [],
                  childs: [],
                },
                {
                  name: 'Lim Chin Kiat, Angie',
                  // cssClass: 'ngx-org-ceo team-a',
                  team: 'A',
                  image: '',
                  title: 'Clerk',
                  office: [{ phoneNumber: '6305 3249', extension: 249 }],
                  mobile: [],
                  email: '',
                  leave: [],
                  childs: [],
                },
                {
                  name: 'Kwek Seow Long',
                  // cssClass: 'ngx-org-ceo team-b',
                  team: 'B',
                  image: '',
                  title: 'Senior Clerk',
                  office: [{ phoneNumber: '6305 3289', extension: 137 }],
                  mobile: [],
                  email: '',
                  leave: [],
                  childs: [],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 6,
      department: 'Programming',
      title: 'Programming',
      childs: [
        {
          name: 'Yap Swee Peng, Claudia',
          // cssClass: 'ngx-org-ceo team-a',
          team: 'A',
          image: '',
          title: 'HOD-Programming',
          office: [{ phoneNumber: '6738 2657', extension: 226 }],
          mobile: ['9753 3093'],
          email: 'claudia.yap@shaw.com.sg',
          leave: [],
          childs: [
            {
              name: 'Chong Siwe Hui, Stacy',
              // cssClass: 'ngx-org-ceo team-b',
              team: 'B',
              image: '',
              title: 'Manager-Programming',
              office: [{ phoneNumber: '6836 1586', extension: 284 }],
              mobile: [],
              email: 'stacy.chong@shaw.com.sg',
              leave: [],
              childs: [
                {
                  name: 'Teo Soon Hock, Willie',
                  // cssClass: 'ngx-org-ceo team-b',
                  team: 'B',
                  image: '',
                  title: 'Programming Executive',
                  office: [{ phoneNumber: '6305 3218', extension: 218 }],
                  mobile: [],
                  email: 'willie.teo@shaw.com.sg',
                  leave: [],
                  childs: [
                    {
                      name: 'Chong Oi Nyuk, Serene',
                      // cssClass: 'ngx-org-ceo',
                      team: '',
                      image: '',
                      title: 'Programming Assistant',
                      office: [{ phoneNumber: '6305 3226', extension: 145 }],
                      mobile: [],
                      email: 'serene.chong@shaw.com.sg',
                      leave: [],
                      childs: [],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 10,
      department: 'Building Services',
      title: 'Buildinig Services',
      childs: [
        {
          name: 'Tan Swee Huat, Steven',
          // cssClass: 'ngx-org-ceo',
          team: '',
          image: '',
          title: 'HOD-Building Services',
          office: [{ phoneNumber: '6305 3257', extension: 257 }],
          mobile: ['9237 9944'],
          email: 'steven_tan@shaw.com.sg',
          leave: [],
          childs: [
            {
              name: 'Joshua Soe',
              // cssClass: 'ngx-org-ceo team-b',
              team: 'B',
              image: '',
              title: 'Facilities Executive',
              office: [{ phoneNumber: '6305 3293', extension: 143 }],
              mobile: [],
              email: 'joshua.soe@shaw.com.sg',
              leave: [],
              childs: [],
            },
            {
              name: 'Ong Ying Fang, Doreen',
              // cssClass: 'ngx-org-ceo team-a',
              team: 'A',
              image: '',
              title: 'Facilities Executive',
              office: [{ phoneNumber: '6305 3290', extension: 153 }],
              mobile: ['8323 2933'],
              email: 'doreen.ong@shaw.com.sg',
              leave: [],
              childs: [],
            },
            {
              name: 'Syed Zain Bin Mohamed Alhadad',
              // cssClass: 'ngx-org-ceo team-b',
              team: 'B',
              image: '',
              title: 'Senior Facilities Executive',
              office: [{ phoneNumber: '6305 3258', extension: 184 }],
              mobile: ['9855 4901'],
              email: 'syed.zain@shaw.com.sg',
              leave: [],
              childs: [],
            },
          ],
        },
      ],
    },
    {
      id: 8,
      department: 'Human Resources',
      title: 'Human Resources',
      childs: [
        {
          name: 'Melody Woo',
          // cssClass: 'ngx-org-ceo',
          team: '',
          image: '',
          title: 'Manager-Corporate Secretarial & HR',
          office: [{ extension: 158 }],
          mobile: [],
          email: 'melody.woo@shaw.com.sg',
          leave: [],
          childs: [
            {
              name: 'Sivalingam Uma Maheshwaran',
              // cssClass: 'ngx-org-ceo team-a',
              team: 'A',
              image: '',
              title: 'HR Officer',
              office: [{ phoneNumber: '6305 3224', extension: 224 }],
              mobile: ['9229 0712'],
              email: 'mahesh@shaw.com.sg',
              leave: [],
              childs: [
                {
                  name: 'Amanda Toh',
                  // cssClass: 'ngx-org-ceo team-b',
                  team: 'B',
                  image: '',
                  title: 'HR Officer',
                  office: [{ extension: 190 }],
                  mobile: [],
                  email: 'amanda.toh@shaw.com.sg',
                  leave: [],
                  childs: [],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 17,
      department: 'Servicing & Publicity',
      title: 'Servicing & Publicity',
      childs: [
        {
          name: 'Chu Chiou Yi',
          // cssClass: 'ngx-org-ceo',
          team: 'B',
          image: '',
          title: 'Manager',
          office: [{ phoneNumber: '6884 4854', extension: 276 }],
          mobile: ['9646 6896'],
          email: 'chucy@shaw.com.sg',
          leave: [],
          childs: [
            {
              name: 'Neo Choon Wah',
              // cssClass: 'ngx-org-ceo',
              team: 'A',
              image: '',
              title: 'Advertising Assistant',
              office: [{ phoneNumber: '6305 3233', extension: 233 }],
              mobile: [],
              email: 'cw.neo@shaw.com.sg',
              leave: [],
              childs: [],
            },
          ],
        },
      ],
    },
    {
      id: 21,
      department: 'Corporate Secretarial',
      title: 'Corporate Secretarial',
      childs: [
        {
          name: 'Melody Woo',
          // cssClass: 'ngx-org-ceo',
          team: '',
          image: '',
          title: 'Manager-Corporate Secretarial & HR',
          office: [{ extension: 158 }],
          email: 'melody.woo@shaw.com.sg',
          leave: [],
          childs: [],
        },
      ],
    },
    {
      id: 15,
      department: 'Film Distribution',
      title: 'Film Distribution',
      childs: [
        {
          name: 'Yeo See Heng',
          // cssClass: 'ngx-org-ceo team-a',
          team: 'A',
          image: '',
          title: 'VP-International Film Contract',
          office: [{ phoneNumber: '6305 3234', extension: 234 }],
          mobile: [],
          email: 'shyeo@shaw.com.sg',
          leave: [],
          childs: [],
        },
        {
          name: 'Roise Othman',
          // cssClass: 'ngx-org-ceo team-b',
          team: 'B',
          image: '',
          title: 'Film Distribution Assistant -  Malay Films',
          office: [{ phoneNumber: '6305 3252', extension: 180 }],
          mobile: [],
          email: 'roise.othman@shaw.com.sg',
          leave: [],
          childs: [],
        },
      ],
    },
    {
      id: 14,
      department: 'Legal & Corporate Secretarial',
      title: 'Legal & Corporate Secretarial',
      childs: [
        {
          name: 'Chin Nyoke Mui, Joan',
          // cssClass: 'ngx-org-ceo',
          team: '',
          image: '',
          title: 'Company Secretary',
          office: [{ phoneNumber: '6737 2731', extension: 201 }],
          mobile: [],
          email: 'joanchin@shaw.com.sg',
          leave: [],
          childs: [
            {
              name: 'Koh Sor Lian, Angela',
              // cssClass: 'ngx-org-ceo',
              team: '',
              image: '',
              title: 'Secretary',
              office: [{ phoneNumber: '6305 3267', extension: 267 }],
              mobile: [],
              email: 'angela.koh@shaw.com.sg',
              leave: [],
              childs: [],
            },
          ],
        },
      ],
    },
  ];
  // labels
  DATA.organisation = res;
  // return genPage('organisation', queryString, 'subject');
  return res;
}

function orgGet(id: number): any {
  const idx = getIdx('organisation', id || 0);
  const item = { ...DATA.organisation[idx] };
  return item;
}

function leaveList(queryString: any): any {
  if (DATA.leaves) {
    return DATA.leaves
      .map((item: any) => {
        return {
          empName: item.EMP_NAME,
          startTime: moment(item.DATE_FROM).valueOf(),
          endTime:
            item.DATE_TO === ''
              ? moment(item.DATE_FROM).add(item.DAYS_TAKEN, 'days').valueOf()
              : moment(item.DATE_TO).add(1, 'days').valueOf(),
          leaveCode: item.LEAVE_CODE,
        };
      })
      .filter((item: any) => {
        return item.startTime >= queryString.start && item.endTime <= queryString.end;
      });
  }

  DATA.leaves = [
    {
      EMP_NAME: 'KOH KAH SIANG',
      DEPARTMENT: '01 - IT',
      LEAVE_CODE: 'Off In Lieu',
      DATE_APPLIED: '',
      DATE_FROM: '25-Jun-21',
      DATE_TO: '25-Jun-21',
      DAYS_TAKEN: 1,
      LEAVE_YEAR: 2021,
    },
    {
      EMP_NAME: 'KOH KAH SIANG',
      DEPARTMENT: '01 - IT',
      LEAVE_CODE: 'Annual Leave',
      DATE_APPLIED: '20-Jun-21',
      DATE_FROM: '29-Jun-21',
      DATE_TO: '30-Jun-21',
      DAYS_TAKEN: 2,
      LEAVE_YEAR: 2021,
    },
    {
      EMP_NAME: 'KOH KAH SIANG',
      DEPARTMENT: '01 - IT',
      LEAVE_CODE: 'Annual Leave',
      DATE_APPLIED: '11-Jul-21',
      DATE_FROM: '13-Jul-21',
      DATE_TO: '13-Jul-21',
      DAYS_TAKEN: 1,
      LEAVE_YEAR: 2021,
    },
    {
      EMP_NAME: 'POON POH YOKE',
      DEPARTMENT: '01 - IT',
      LEAVE_CODE: 'Off In Lieu',
      DATE_APPLIED: '',
      DATE_FROM: '25-Jun-21',
      DATE_TO: '',
      DAYS_TAKEN: 1,
      LEAVE_YEAR: 2021,
    },
    {
      EMP_NAME: 'POON POH YOKE',
      DEPARTMENT: '01 - IT',
      LEAVE_CODE: 'Sick Leave',
      DATE_APPLIED: '7-Aug-21',
      DATE_FROM: '7-Aug-21',
      DATE_TO: '7-Aug-21',
      DAYS_TAKEN: 1,
      LEAVE_YEAR: 2021,
    },
    {
      EMP_NAME: 'POON POH YOKE',
      DEPARTMENT: '01 - IT',
      LEAVE_CODE: 'Sick Leave',
      DATE_APPLIED: '13-Aug-21',
      DATE_FROM: '13-Aug-21',
      DATE_TO: '14-Aug-21',
      DAYS_TAKEN: 2,
      LEAVE_YEAR: 2021,
    },
    {
      EMP_NAME: 'POON POH YOKE',
      DEPARTMENT: '01 - IT',
      LEAVE_CODE: 'Sick Leave',
      DATE_APPLIED: '17-Aug-21',
      DATE_FROM: '17-Aug-21',
      DATE_TO: '21-Aug-21',
      DAYS_TAKEN: 5,
      LEAVE_YEAR: 2021,
    },
    {
      EMP_NAME: 'THET LWIN OO',
      DEPARTMENT: '01 - IT',
      LEAVE_CODE: 'Annual Leave',
      DATE_APPLIED: '21-Oct-21',
      DATE_FROM: '30-Dec-21',
      DATE_TO: '17-Jun-22',
      DAYS_TAKEN: 14,
      LEAVE_YEAR: 2021,
    },
    {
      EMP_NAME: 'THET LWIN OO',
      DEPARTMENT: '01 - IT',
      LEAVE_CODE: 'Off In Lieu',
      DATE_APPLIED: '',
      DATE_FROM: '28-Jun-21',
      DATE_TO: '29-Jun-21',
      DAYS_TAKEN: 2,
      LEAVE_YEAR: 2021,
    },
    {
      EMP_NAME: 'THET LWIN OO',
      DEPARTMENT: '01 - IT',
      LEAVE_CODE: 'Sick Leave',
      DATE_APPLIED: '18-Jul-21',
      DATE_FROM: '16-Apr-21',
      DATE_TO: '17-Apr-21',
      DAYS_TAKEN: 2,
      LEAVE_YEAR: 2021,
    },
    {
      EMP_NAME: 'CHONG YOKE WAH, JANSON',
      DEPARTMENT: '01 - IT',
      LEAVE_CODE: 'Annual Leave',
      DATE_APPLIED: '14-Jun-21',
      DATE_FROM: '23-Jun-21',
      DATE_TO: '29-Jun-21',
      DAYS_TAKEN: 4,
      LEAVE_YEAR: 2021,
    },
    {
      EMP_NAME: 'CHONG YOKE WAH, JANSON',
      DEPARTMENT: '01 - IT',
      LEAVE_CODE: 'Off In Lieu',
      DATE_APPLIED: '',
      DATE_FROM: '25-Jun-21',
      DATE_TO: '',
      DAYS_TAKEN: 1,
      LEAVE_YEAR: 2021,
    },
    {
      EMP_NAME: 'MYAT MIN',
      DEPARTMENT: '01 - IT',
      LEAVE_CODE: 'Off In Lieu',
      DATE_APPLIED: '',
      DATE_FROM: '25-Jun-21',
      DATE_TO: '',
      DAYS_TAKEN: 1,
      LEAVE_YEAR: 2021,
    },
    {
      EMP_NAME: 'MYAT MIN',
      DEPARTMENT: '01 - IT',
      LEAVE_CODE: 'Annual Leave',
      DATE_APPLIED: '27-Jul-21',
      DATE_FROM: '20-Apr-21',
      DATE_TO: '20-Apr-21',
      DAYS_TAKEN: 1,
      LEAVE_YEAR: 2021,
    },
    {
      EMP_NAME: 'MYAT MIN',
      DEPARTMENT: '01 - IT',
      LEAVE_CODE: 'Annual Leave',
      DATE_APPLIED: '27-Jul-21',
      DATE_FROM: '23-Apr-21',
      DATE_TO: '23-Apr-21',
      DAYS_TAKEN: 1,
      LEAVE_YEAR: 2021,
    },
    {
      EMP_NAME: 'SIAU LI NANG ALICIA',
      DEPARTMENT: '01 - IT',
      LEAVE_CODE: 'Annual Leave',
      DATE_APPLIED: '16-Dec-21',
      DATE_FROM: '10-Jun-21',
      DATE_TO: '10-Jun-21',
      DAYS_TAKEN: 1,
      LEAVE_YEAR: 2021,
    },
    {
      EMP_NAME: 'SIAU LI NANG ALICIA',
      DEPARTMENT: '01 - IT',
      LEAVE_CODE: 'Sick Leave',
      DATE_APPLIED: '16-Jun-21',
      DATE_FROM: '15-Jun-21',
      DATE_TO: '15-Jun-21',
      DAYS_TAKEN: 1,
      LEAVE_YEAR: 2021,
    },
    {
      EMP_NAME: 'SIAU LI NANG ALICIA',
      DEPARTMENT: '01 - IT',
      LEAVE_CODE: 'Sick Leave',
      DATE_APPLIED: '21-Jun-21',
      DATE_FROM: '20-Jun-21',
      DATE_TO: '20-Jun-21',
      DAYS_TAKEN: 1,
      LEAVE_YEAR: 2021,
    },
    {
      EMP_NAME: 'SIAU LI NANG ALICIA',
      DEPARTMENT: '01 - IT',
      LEAVE_CODE: 'Off In Lieu',
      DATE_APPLIED: '',
      DATE_FROM: '25-Jun-21',
      DATE_TO: '',
      DAYS_TAKEN: 1,
      LEAVE_YEAR: 2021,
    },
    {
      EMP_NAME: 'SIAU LI NANG ALICIA',
      DEPARTMENT: '01 - IT',
      LEAVE_CODE: 'Annual Leave',
      DATE_APPLIED: '17-Dec-21',
      DATE_FROM: '30-Jun-21',
      DATE_TO: '31-Jun-21',
      DAYS_TAKEN: 2,
      LEAVE_YEAR: 2021,
    },
    {
      EMP_NAME: 'SIAU LI NANG ALICIA',
      DEPARTMENT: '01 - IT',
      LEAVE_CODE: 'Annual Leave',
      DATE_APPLIED: '17-Jun-21',
      DATE_FROM: '31-Jun-21',
      DATE_TO: '31-Jun-21',
      DAYS_TAKEN: 1,
      LEAVE_YEAR: 2021,
    },
    {
      EMP_NAME: 'SIAU LI NANG ALICIA',
      DEPARTMENT: '01 - IT',
      LEAVE_CODE: 'Sick Leave',
      DATE_APPLIED: '6-Aug-21',
      DATE_FROM: '5-Aug-21',
      DATE_TO: '5-Aug-21',
      DAYS_TAKEN: 1,
      LEAVE_YEAR: 2021,
    },
    {
      EMP_NAME: 'SIAU LI NANG ALICIA',
      DEPARTMENT: '01 - IT',
      LEAVE_CODE: 'Sick Leave',
      DATE_APPLIED: '7-Aug-21',
      DATE_FROM: '7-Aug-21',
      DATE_TO: '7-Aug-21',
      DAYS_TAKEN: 1,
      LEAVE_YEAR: 2021,
    },
    {
      EMP_NAME: 'SIAU LI NANG ALICIA',
      DEPARTMENT: '01 - IT',
      LEAVE_CODE: 'Sick Leave',
      DATE_APPLIED: '11-Aug-21',
      DATE_FROM: '11-Aug-21',
      DATE_TO: '12-Aug-21',
      DAYS_TAKEN: 2,
      LEAVE_YEAR: 2021,
    },
    {
      EMP_NAME: 'SIAU LI NANG ALICIA',
      DEPARTMENT: '01 - IT',
      LEAVE_CODE: 'Annual Leave',
      DATE_APPLIED: '17-Jun-21',
      DATE_FROM: '14-Aug-21',
      DATE_TO: '14-Aug-21',
      DAYS_TAKEN: 1,
      LEAVE_YEAR: 2021,
    },
    {
      EMP_NAME: 'SIAU LI NANG ALICIA',
      DEPARTMENT: '01 - IT',
      LEAVE_CODE: 'Sick Leave',
      DATE_APPLIED: '17-Aug-21',
      DATE_FROM: '17-Aug-21',
      DATE_TO: '21-Aug-21',
      DAYS_TAKEN: 5,
      LEAVE_YEAR: 2021,
    },
    {
      EMP_NAME: 'SITT SHEIN',
      DEPARTMENT: '01 - IT',
      LEAVE_CODE: 'Annual Leave',
      DATE_APPLIED: '9-Jun-21',
      DATE_FROM: '8-Jun-21',
      DATE_TO: '8-Jun-21',
      DAYS_TAKEN: 1,
      LEAVE_YEAR: 2021,
    },
    {
      EMP_NAME: 'SITT SHEIN',
      DEPARTMENT: '01 - IT',
      LEAVE_CODE: 'Sick Leave',
      DATE_APPLIED: '3-Aug-21',
      DATE_FROM: '23-Jun-21',
      DATE_TO: '24-Jun-21',
      DAYS_TAKEN: 2,
      LEAVE_YEAR: 2021,
    },
    {
      EMP_NAME: 'SITT SHEIN',
      DEPARTMENT: '01 - IT',
      LEAVE_CODE: 'Off In Lieu',
      DATE_APPLIED: '',
      DATE_FROM: '25-Jun-21',
      DATE_TO: '',
      DAYS_TAKEN: 1,
      LEAVE_YEAR: 2021,
    },
    {
      EMP_NAME: 'SITT SHEIN',
      DEPARTMENT: '01 - IT',
      LEAVE_CODE: 'Annual Leave',
      DATE_APPLIED: '17-Jun-21',
      DATE_FROM: '29-Jun-21',
      DATE_TO: '31-Jun-21',
      DAYS_TAKEN: 4,
      LEAVE_YEAR: 2021,
    },
    {
      EMP_NAME: 'SITT SHEIN',
      DEPARTMENT: '01 - IT',
      LEAVE_CODE: 'Annual Leave',
      DATE_APPLIED: '2-Jul-21',
      DATE_FROM: '3-Jul-21',
      DATE_TO: '3-Jul-21',
      DAYS_TAKEN: 1,
      LEAVE_YEAR: 2021,
    },
    {
      EMP_NAME: 'SITT SHEIN',
      DEPARTMENT: '01 - IT',
      LEAVE_CODE: 'Sick Leave',
      DATE_APPLIED: '9-Jul-21',
      DATE_FROM: '6-Jul-21',
      DATE_TO: '6-Jul-21',
      DAYS_TAKEN: 1,
      LEAVE_YEAR: 2021,
    },
    {
      EMP_NAME: 'SITT SHEIN',
      DEPARTMENT: '01 - IT',
      LEAVE_CODE: 'Sick Leave',
      DATE_APPLIED: '13-Jul-21',
      DATE_FROM: '12-Jul-21',
      DATE_TO: '12-Jul-21',
      DAYS_TAKEN: 1,
      LEAVE_YEAR: 2021,
    },
    {
      EMP_NAME: 'SOE SANDA NWE',
      DEPARTMENT: '01 - IT',
      LEAVE_CODE: 'Annual Leave',
      DATE_APPLIED: '13-Jun-21',
      DATE_FROM: '20-Jun-21',
      DATE_TO: '20-Jun-21',
      DAYS_TAKEN: 1,
      LEAVE_YEAR: 2021,
    },
    {
      EMP_NAME: 'SOE SANDA NWE',
      DEPARTMENT: '01 - IT',
      LEAVE_CODE: 'Off In Lieu',
      DATE_APPLIED: '',
      DATE_FROM: '25-Jun-21',
      DATE_TO: '',
      DAYS_TAKEN: 1,
      LEAVE_YEAR: 2021,
    },
    {
      EMP_NAME: 'SOE SANDA NWE',
      DEPARTMENT: '01 - IT',
      LEAVE_CODE: 'Sick Leave',
      DATE_APPLIED: '4-Aug-21',
      DATE_FROM: '3-Aug-21',
      DATE_TO: '3-Aug-21',
      DAYS_TAKEN: 1,
      LEAVE_YEAR: 2021,
    },
    {
      EMP_NAME: 'SOE SANDA NWE',
      DEPARTMENT: '01 - IT',
      LEAVE_CODE: 'Off In Lieu',
      DATE_APPLIED: '10-Aug-21',
      DATE_FROM: '19-Aug-21',
      DATE_TO: '19-Aug-21',
      DAYS_TAKEN: 1,
      LEAVE_YEAR: 2021,
    },
    {
      EMP_NAME: 'SOE SANDA NWE',
      DEPARTMENT: '01 - IT',
      LEAVE_CODE: 'Sick Leave',
      DATE_APPLIED: '10-Jul-21',
      DATE_FROM: '10-Jul-21',
      DATE_TO: '12-Jul-21',
      DAYS_TAKEN: 3,
      LEAVE_YEAR: 2021,
    },
    {
      EMP_NAME: 'SOE SANDA NWE',
      DEPARTMENT: '01 - IT',
      LEAVE_CODE: 'Annual Leave',
      DATE_APPLIED: '16-Jul-21',
      DATE_FROM: '30-Jul-21',
      DATE_TO: '30-Jul-21',
      DAYS_TAKEN: 1,
      LEAVE_YEAR: 2021,
    },
    {
      EMP_NAME: 'SOW YONG ZHOU ANDREAS',
      DEPARTMENT: '01 - IT',
      LEAVE_CODE: 'Sick Leave',
      DATE_APPLIED: '8-Jun-21',
      DATE_FROM: '6-Jun-21',
      DATE_TO: '7-Jun-21',
      DAYS_TAKEN: 2,
      LEAVE_YEAR: 2021,
    },
    {
      EMP_NAME: 'SOW YONG ZHOU ANDREAS',
      DEPARTMENT: '01 - IT',
      LEAVE_CODE: 'Annual Leave',
      DATE_APPLIED: '5-Dec-21',
      DATE_FROM: '23-Jun-21',
      DATE_TO: '24-Jun-21',
      DAYS_TAKEN: 2,
      LEAVE_YEAR: 2021,
    },
    {
      EMP_NAME: 'SOW YONG ZHOU ANDREAS',
      DEPARTMENT: '01 - IT',
      LEAVE_CODE: 'Annual Leave',
      DATE_APPLIED: '23-Jun-21',
      DATE_FROM: '23-Jun-21',
      DATE_TO: '23-Jun-21',
      DAYS_TAKEN: 1,
      LEAVE_YEAR: 2021,
    },
    {
      EMP_NAME: 'SOW YONG ZHOU ANDREAS',
      DEPARTMENT: '01 - IT',
      LEAVE_CODE: 'Annual Leave',
      DATE_APPLIED: '14-Jun-21',
      DATE_FROM: '24-Jun-21',
      DATE_TO: '24-Jun-21',
      DAYS_TAKEN: 1,
      LEAVE_YEAR: 2021,
    },
    {
      EMP_NAME: 'SOW YONG ZHOU ANDREAS',
      DEPARTMENT: '01 - IT',
      LEAVE_CODE: 'Off In Lieu',
      DATE_APPLIED: '',
      DATE_FROM: '25-Jun-21',
      DATE_TO: '',
      DAYS_TAKEN: 1,
      LEAVE_YEAR: 2021,
    },
    {
      EMP_NAME: 'SOW YONG ZHOU ANDREAS',
      DEPARTMENT: '01 - IT',
      LEAVE_CODE: 'Off In Lieu',
      DATE_APPLIED: '',
      DATE_FROM: '25-Jun-21',
      DATE_TO: '',
      DAYS_TAKEN: 1,
      LEAVE_YEAR: 2021,
    },
    {
      EMP_NAME: 'SOW YONG ZHOU ANDREAS',
      DEPARTMENT: '01 - IT',
      LEAVE_CODE: 'Off In Lieu',
      DATE_APPLIED: '',
      DATE_FROM: '25-Jun-21',
      DATE_TO: '',
      DAYS_TAKEN: 1,
      LEAVE_YEAR: 2021,
    },
    {
      EMP_NAME: 'SOW YONG ZHOU ANDREAS',
      DEPARTMENT: '01 - IT',
      LEAVE_CODE: 'Sick Leave',
      DATE_APPLIED: '6-Aug-21',
      DATE_FROM: '6-Aug-21',
      DATE_TO: '7-Aug-21',
      DAYS_TAKEN: 2,
      LEAVE_YEAR: 2021,
    },
    {
      EMP_NAME: 'SOW YONG ZHOU ANDREAS',
      DEPARTMENT: '01 - IT',
      LEAVE_CODE: 'Annual Leave',
      DATE_APPLIED: '29-Nov-21',
      DATE_FROM: '23-Aug-21',
      DATE_TO: '27-Aug-21',
      DAYS_TAKEN: 4,
      LEAVE_YEAR: 2021,
    },
    {
      EMP_NAME: 'SOW YONG ZHOU ANDREAS',
      DEPARTMENT: '01 - IT',
      LEAVE_CODE: 'Childcare Leave',
      DATE_APPLIED: '23-Aug-21',
      DATE_FROM: '28-Aug-21',
      DATE_TO: '28-Aug-21',
      DAYS_TAKEN: 1,
      LEAVE_YEAR: 2021,
    },
    {
      EMP_NAME: 'SOW YONG ZHOU ANDREAS',
      DEPARTMENT: '01 - IT',
      LEAVE_CODE: 'Childcare Leave',
      DATE_APPLIED: '1-Jul-21',
      DATE_FROM: '2-Jul-21',
      DATE_TO: '2-Jul-21',
      DAYS_TAKEN: 1,
      LEAVE_YEAR: 2021,
    },
    {
      EMP_NAME: 'SOW YONG ZHOU ANDREAS',
      DEPARTMENT: '01 - IT',
      LEAVE_CODE: 'Sick Leave',
      DATE_APPLIED: '3-Jul-21',
      DATE_FROM: '3-Jul-21',
      DATE_TO: '3-Jul-21',
      DAYS_TAKEN: 1,
      LEAVE_YEAR: 2021,
    },
    {
      EMP_NAME: 'TAN KOK HENG DERRICK',
      DEPARTMENT: '01 - IT',
      LEAVE_CODE: 'Sick Leave',
      DATE_APPLIED: '20-Jun-21',
      DATE_FROM: '17-Jun-21',
      DATE_TO: '17-Jun-21',
      DAYS_TAKEN: 1,
      LEAVE_YEAR: 2021,
    },
    {
      EMP_NAME: 'TAN KOK HENG DERRICK',
      DEPARTMENT: '01 - IT',
      LEAVE_CODE: 'Off In Lieu',
      DATE_APPLIED: '',
      DATE_FROM: '25-Jun-21',
      DATE_TO: '',
      DAYS_TAKEN: 1,
      LEAVE_YEAR: 2021,
    },
    {
      EMP_NAME: 'TAN KOK HENG DERRICK',
      DEPARTMENT: '01 - IT',
      LEAVE_CODE: 'Sick Leave',
      DATE_APPLIED: '30-Jun-21',
      DATE_FROM: '30-Jun-21',
      DATE_TO: '31-Jun-21',
      DAYS_TAKEN: 2,
      LEAVE_YEAR: 2021,
    },
    {
      EMP_NAME: 'TAN KOK HENG DERRICK',
      DEPARTMENT: '01 - IT',
      LEAVE_CODE: 'Sick Leave',
      DATE_APPLIED: '19-Aug-21',
      DATE_FROM: '17-Aug-21',
      DATE_TO: '18-Aug-21',
      DAYS_TAKEN: 2,
      LEAVE_YEAR: 2021,
    },
    {
      EMP_NAME: 'TAN KOK HENG DERRICK',
      DEPARTMENT: '01 - IT',
      LEAVE_CODE: 'Annual Leave',
      DATE_APPLIED: '2-Jun-21',
      DATE_FROM: '24-Aug-21',
      DATE_TO: '25-Aug-21',
      DAYS_TAKEN: 2,
      LEAVE_YEAR: 2021,
    },
    {
      EMP_NAME: 'THAM MEI LEAN,AMELIA',
      DEPARTMENT: '01 - IT',
      LEAVE_CODE: 'Off In Lieu',
      DATE_APPLIED: '',
      DATE_FROM: '25-Jun-21',
      DATE_TO: '',
      DAYS_TAKEN: 1,
      LEAVE_YEAR: 2021,
    },
    {
      EMP_NAME: 'THAM MEI LEAN,AMELIA',
      DEPARTMENT: '01 - IT',
      LEAVE_CODE: 'Sick Leave',
      DATE_APPLIED: '5-Jul-21',
      DATE_FROM: '4-Jul-21',
      DATE_TO: '4-Jul-21',
      DAYS_TAKEN: 1,
      LEAVE_YEAR: 2021,
    },
    {
      EMP_NAME: 'THAM MEI LEAN,AMELIA',
      DEPARTMENT: '01 - IT',
      LEAVE_CODE: 'Sick Leave',
      DATE_APPLIED: '16-Jul-21',
      DATE_FROM: '16-Jul-21',
      DATE_TO: '20-Jul-21',
      DAYS_TAKEN: 5,
      LEAVE_YEAR: 2021,
    },
    {
      EMP_NAME: 'THAM MEI LEAN,AMELIA',
      DEPARTMENT: '01 - IT',
      LEAVE_CODE: 'Annual Leave',
      DATE_APPLIED: '25-Jul-21',
      DATE_FROM: '27-Jul-21',
      DATE_TO: '27-Jul-21',
      DAYS_TAKEN: 1,
      LEAVE_YEAR: 2021,
    },
    {
      EMP_NAME: 'WEE CHENG BOK',
      DEPARTMENT: '01 - IT',
      LEAVE_CODE: 'Off In Lieu',
      DATE_APPLIED: '',
      DATE_FROM: '25-Jun-21',
      DATE_TO: '',
      DAYS_TAKEN: 1,
      LEAVE_YEAR: 2021,
    },
    {
      EMP_NAME: 'WEE CHENG BOK',
      DEPARTMENT: '01 - IT',
      LEAVE_CODE: 'Sick Leave',
      DATE_APPLIED: '12-Jul-21',
      DATE_FROM: '12-Jul-21',
      DATE_TO: '13-Jul-21',
      DAYS_TAKEN: 2,
      LEAVE_YEAR: 2021,
    },
    {
      EMP_NAME: 'WEE CHENG BOK',
      DEPARTMENT: '01 - IT',
      LEAVE_CODE: 'Sick Leave',
      DATE_APPLIED: '18-Jul-21',
      DATE_FROM: '16-Jul-21',
      DATE_TO: '17-Jul-21',
      DAYS_TAKEN: 2,
      LEAVE_YEAR: 2021,
    },
    {
      EMP_NAME: 'SITT SHEIN',
      DEPARTMENT: '01 - IT',
      LEAVE_CODE: 'Annual Leave',
      DATE_APPLIED: '2-Jul-21',
      DATE_FROM: '3-Jul-21',
      DATE_TO: '3-Jul-21',
      DAYS_TAKEN: 1,
      LEAVE_YEAR: 2021,
    },
    {
      EMP_NAME: 'SITT SHEIN',
      DEPARTMENT: '01 - IT',
      LEAVE_CODE: 'Sick Leave',
      DATE_APPLIED: '9-Jul-21',
      DATE_FROM: '6-Jul-21',
      DATE_TO: '6-Jul-21',
      DAYS_TAKEN: 1,
      LEAVE_YEAR: 2021,
    },
    {
      EMP_NAME: 'SITT SHEIN',
      DEPARTMENT: '01 - IT',
      LEAVE_CODE: 'Sick Leave',
      DATE_APPLIED: '13-Jul-21',
      DATE_FROM: '12-Jul-21',
      DATE_TO: '12-Jul-21',
      DAYS_TAKEN: 1,
      LEAVE_YEAR: 2021,
    },
    {
      EMP_NAME: 'SOE SANDA NWE',
      DEPARTMENT: '01 - IT',
      LEAVE_CODE: 'Sick Leave',
      DATE_APPLIED: '10-Jul-21',
      DATE_FROM: '10-Jul-21',
      DATE_TO: '12-Jul-21',
      DAYS_TAKEN: 3,
      LEAVE_YEAR: 2021,
    },
    {
      EMP_NAME: 'SOE SANDA NWE',
      DEPARTMENT: '01 - IT',
      LEAVE_CODE: 'Annual Leave',
      DATE_APPLIED: '16-Jul-21',
      DATE_FROM: '30-Jul-21',
      DATE_TO: '30-Jul-21',
      DAYS_TAKEN: 1,
      LEAVE_YEAR: 2021,
    },
    {
      EMP_NAME: 'SOW YONG ZHOU ANDREAS',
      DEPARTMENT: '01 - IT',
      LEAVE_CODE: 'Childcare Leave',
      DATE_APPLIED: '1-Jul-21',
      DATE_FROM: '2-Jul-21',
      DATE_TO: '2-Jul-21',
      DAYS_TAKEN: 1,
      LEAVE_YEAR: 2021,
    },
    {
      EMP_NAME: 'SOW YONG ZHOU ANDREAS',
      DEPARTMENT: '01 - IT',
      LEAVE_CODE: 'Sick Leave',
      DATE_APPLIED: '3-Jul-21',
      DATE_FROM: '3-Jul-21',
      DATE_TO: '3-Jul-21',
      DAYS_TAKEN: 1,
      LEAVE_YEAR: 2021,
    },
    {
      EMP_NAME: 'THAM MEI LEAN,AMELIA',
      DEPARTMENT: '01 - IT',
      LEAVE_CODE: 'Sick Leave',
      DATE_APPLIED: '5-Jul-21',
      DATE_FROM: '4-Jul-21',
      DATE_TO: '4-Jul-21',
      DAYS_TAKEN: 1,
      LEAVE_YEAR: 2021,
    },
    {
      EMP_NAME: 'THAM MEI LEAN,AMELIA',
      DEPARTMENT: '01 - IT',
      LEAVE_CODE: 'Sick Leave',
      DATE_APPLIED: '16-Jul-21',
      DATE_FROM: '16-Jul-21',
      DATE_TO: '20-Jul-21',
      DAYS_TAKEN: 5,
      LEAVE_YEAR: 2021,
    },
    {
      EMP_NAME: 'THAM MEI LEAN,AMELIA',
      DEPARTMENT: '01 - IT',
      LEAVE_CODE: 'Annual Leave',
      DATE_APPLIED: '25-Jul-21',
      DATE_FROM: '27-Jul-21',
      DATE_TO: '27-Jul-21',
      DAYS_TAKEN: 1,
      LEAVE_YEAR: 2021,
    },
    {
      EMP_NAME: 'WEE CHENG BOK',
      DEPARTMENT: '01 - IT',
      LEAVE_CODE: 'Sick Leave',
      DATE_APPLIED: '12-Jul-21',
      DATE_FROM: '12-Jul-21',
      DATE_TO: '13-Jul-21',
      DAYS_TAKEN: 2,
      LEAVE_YEAR: 2021,
    },
    {
      EMP_NAME: 'WEE CHENG BOK',
      DEPARTMENT: '01 - IT',
      LEAVE_CODE: 'Sick Leave',
      DATE_APPLIED: '18-Jul-21',
      DATE_FROM: '16-Jul-21',
      DATE_TO: '17-Jul-21',
      DAYS_TAKEN: 2,
      LEAVE_YEAR: 2021,
    },
    {
      EMP_NAME: 'KOH KAH SIANG',
      DEPARTMENT: '01 - IT',
      LEAVE_CODE: 'Annual Leave',
      DATE_APPLIED: '11-Jul-21',
      DATE_FROM: '13-Jul-21',
      DATE_TO: '13-Jul-21',
      DAYS_TAKEN: 1,
      LEAVE_YEAR: 2021,
    },
    {
      EMP_NAME: 'THET LWIN OO',
      DEPARTMENT: '01 - IT',
      LEAVE_CODE: 'Sick Leave',
      DATE_APPLIED: '18-Jul-21',
      DATE_FROM: '12-Jul-21',
      DATE_TO: '13-Jul-21',
      DAYS_TAKEN: 2,
      LEAVE_YEAR: 2021,
    },
  ];

  return DATA.leaves
    .map((item: any) => {
      return {
        empName: item.EMP_NAME,
        startTime: moment(item.DATE_FROM).valueOf(),
        endTime:
          item.DATE_TO === ''
            ? moment(item.DATE_FROM).add(item.DAYS_TAKEN, 'days').valueOf()
            : moment(item.DATE_TO).add(1, 'days').valueOf(),
        leaveCode: item.LEAVE_CODE,
      };
    })
    .filter((item: any) => {
      if (queryString.start && queryString.end) {
        return item.startTime >= queryString.start && item.endTime <= queryString.end;
      }
      return true;
    });
}

function leaveTypes(queryString: any): any {
  if (DATA.leaveTypes) {
    return DATA.leaveTypes;
  }

  DATA.leaveTypes = [
    {
      id: 1,
      leaveTypeCode: 'AL1',
      leaveTypeName: 'Annual Leave',
      colorCode: '#BDC4FF',
    },
    {
      id: 2,
      leaveTypeCode: 'CCL',
      leaveTypeName: 'Childcare Leave',
      colorCode: '#FFC0CB',
    },
    {
      id: 3,
      leaveTypeCode: 'NPL',
      leaveTypeName: 'No Pay Leave',
      colorCode: '#363D6C',
    },
    {
      id: 4,
      leaveTypeCode: 'CL',
      leaveTypeName: 'Compassionate Leave',
      colorCode: '#975794',
    },
    {
      id: 5,
      leaveTypeCode: 'SL',
      leaveTypeName: 'Sick Leave',
      colorCode: '#DC143C',
    },
    {
      id: 6,
      leaveTypeCode: 'HL',
      leaveTypeName: 'Hospitalization Leave',
      colorCode: '#398AD7',
    },
    {
      id: 7,
      leaveTypeCode: 'MAT',
      leaveTypeName: 'Maternity Leave',
      colorCode: '#FF007F',
    },
    {
      id: 8,
      leaveTypeCode: 'PAT2017',
      leaveTypeName: 'Paternity Leave',
      colorCode: '#45818E',
    },
    {
      id: 9,
      leaveTypeCode: 'LOA',
      leaveTypeName: 'Leave of Absence',
      colorCode: '#E69138',
    },
    {
      id: 10,
      leaveTypeCode: 'OIL',
      leaveTypeName: 'Off In Lieu',
      colorCode: '#D9D2E9',
    },
    {
      id: 11,
      leaveTypeCode: 'BTRIP',
      leaveTypeName: 'Business Trip',
      colorCode: '#FFF9D4',
    },
    {
      id: 12,
      leaveTypeCode: 'OC',
      leaveTypeName: 'On Course',
      colorCode: '#C0C0C0',
    },
  ];

  return DATA.leaveTypes;
}

// #endregion

export const AYS = {
  '/home': home(),
  '/domain': (req: MockRequest) => domainList(req.queryString),
  '/domain/:id': (req: MockRequest) => domainGet(+req.params.id),
  'POST /domain': (req: MockRequest) => save('domain', req.body),
  '/domain/log': (req: MockRequest) => domainLogList(req.queryString),
  '/dns/group': () => deepCopy(dnsGroup()),
  'POST /dns/group': (req: MockRequest) => save('dnsGroup', req.body),
  'DELETE /dns/group': (req: MockRequest) => del('dnsGroup', req.queryString),
  '/dns/setting': (req: MockRequest) => dnsSettingList(req.queryString),
  '/dns/setting/:id': (req: MockRequest) => dnsSettingGet(+req.params.id),
  'POST /dns/setting': (req: MockRequest) => save('dnsSetting', req.body),
  'DELETE /dns/setting': (req: MockRequest) => del('dnsSetting', req.queryString),
  '/dns/status': () => Random.boolean(),
  '/authorize/deny': () => false,
  '/authorize/grant': () => true,
  '/apis': (req: MockRequest) => apisList(req.queryString),
  'DELETE /apis': (req: MockRequest) => del('apis', req.queryString),
  '/claims': (req: MockRequest) => claimsList(req.queryString),
  '/claims/:id': (req: MockRequest) => claimGet(+req.params.id),
  'POST /claims': (req: MockRequest) => save('claims', req.body),
  'PUT /claims': (req: MockRequest) => save('claims', req.body),
  'DELETE /claims': (req: MockRequest) => del('claims', req.queryString),
  '/region': () => deepCopy(regionList()),
  '/summary/status': () => [
    { type: '运行中', num: Random.integer(1, 20), color: '#52c41a' },
    { type: '即将过期', num: Random.integer(0, 5), color: '#fa8c16' },
    { type: '已过期', num: Random.integer(0, 5), color: '#f5222d' },
    { type: '已停止', num: Random.integer(0, 5), color: '#888888' },
  ],
  '/summary/month': () =>
    Random.boolean() && Random.boolean() && Random.boolean()
      ? []
      : [
          { time: genData(2), num: Random.integer(0, 1) },
          { time: genData(4), num: 0 },
          { time: genData(5), num: Random.integer(0, 2) },
          { time: genData(10), num: 0 },
          { time: genData(12), num: Random.integer(1, 3) },
          { time: genData(18), num: Random.integer(1, 5) },
          { time: genData(22), num: Random.integer(0, 3) },
          { time: genData(27), num: Random.integer(0, 5) },
        ],
  '/summary/pay': () => [
    { text: '预付费支付', count: Random.integer(0, 5) },
    { text: '网银支付', count: Random.integer(1, 50) },
  ],
  '/summary/trade': () =>
    new Array(15).fill({}).map((i, idx) => ({
      time: genData(-idx, 'MM-dd'),
      num: Random.integer(0, 200),
    })),
  '/summary/leave': (req: MockRequest) => deepCopy(leaveList(req.queryString)),
  '/summary/leave-types': (req: MockRequest) => deepCopy(leaveTypes(req.queryString)),
  '/summary/leavetest': [
    { task: 'task0', startTime: '2018-04-18 01:17:12', endTime: '2018-04-18 01:19:10', status: 0 },
    { task: 'task1', startTime: '2018-04-18 01:18:15', endTime: '2018-04-18 01:19:20', status: 0 },
    { task: 'task2', startTime: '2018-04-18 02:11:32', endTime: '2018-04-18 02:18:50', status: 0 },
    { task: 'task3', startTime: '2018-04-18 02:18:50', endTime: '2018-04-18 03:16:38', status: 0 },
    { task: 'task4', startTime: '2018-04-18 02:19:48', endTime: '2018-04-18 02:21:57', status: 0 },
    { task: 'task5', startTime: '2018-04-18 03:16:38', endTime: '2018-04-18 03:19:38', status: 1 },
    { task: 'task6', startTime: '2018-04-18 03:19:38', endTime: '2018-04-18 03:27:49', status: 0 },
    { task: 'task7', startTime: '2018-04-18 07:29:37', endTime: '2018-04-18 07:33:01', status: 0 },
    { task: 'task8', startTime: '2018-04-18 03:27:49', endTime: '2018-04-18 04:26:05', status: 0 },
    { task: 'task9', startTime: '2018-04-18 04:26:05', endTime: '2018-04-18 06:06:36', status: 0 },
    { task: 'task10', startTime: '2018-04-18 06:06:36', endTime: '2018-04-18 06:15:15', status: 0 },
    { task: 'task11', startTime: '2018-04-18 03:27:49', endTime: '2018-04-18 03:34:50', status: 0 },
  ],
  '/summary/gender': () => [
    { x: 'Jan', y: Random.integer(0, 50) },
    { x: 'Feb', y: Random.integer(0, 50) },
    { x: 'Mar', y: Random.integer(0, 150) },
  ],
  '/summary/region': () =>
    new Array(Random.integer(1, 10)).fill({}).map(() => ({
      text: genBP(),
      count: Random.integer(0, 500),
    })),
  '/summary/healthy': () => ({
    service_count: Random.integer(50, 60),
    err_count: Random.integer(0, 2),
    cpu_usage: +Random.float(0, 100).toFixed(2),
    disk_usage: +Random.float(0, 100).toFixed(2),
    memory_usage: +Random.float(10, 100).toFixed(2),
    network_usage: +Random.float(15, 100).toFixed(2),
  }),
  '/help/categories': () => helpCategories(),
  '/help/categories/:id': (req: MockRequest) => helpCategoriesGet(+req.params.id),
  '/help/menu': () => helpMenu(),
  '/message/inner': (req: MockRequest) => messageInnerList(req.queryString),
  '/message/count': () => messageInnerCount(),
  '/message/inner/:id': (req: MockRequest) => messageInnerGet(+req.params.id),
  '/user-ms': () => user(),
  '/user-ms/default-avatar': () => userDefaultAvatar(),
  'POST /user-ms': (req: MockRequest) => (DATA.user = req.body),
  '/finance': (req: MockRequest) => financeList(req.queryString),
  '/finance/:id': (req: MockRequest) => financeGet(+req.params.id),
  '/organisation': (req: MockRequest) => deepCopy(orgList(req.queryString)),
  '/organisation/:id': (req: MockRequest) => orgGet(+req.params.id),
};
