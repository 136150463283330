import * as moment from 'moment';

export const MEDICALS = [
  {
    id: '5725a680b3249760ea21de52',
    name: 'Abbott',
    clinicName: 'Royalguard',
    company: 'Saois',
    treatment: 'outpatient',
    claimAmount: 55.56,
    claimableAmount: 20.0,
    claimStatus: 'New',
    remark: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt porro in hic. Necessitatibus ab nulla optio qu.',
    claimDate: moment('2019-01-30', 'YYYY-MM-DD').toDate(),
    createdAt: moment('2019-01-30', 'YYYY-MM-DD').toDate(),
    updatedAt: moment('2019-01-30', 'YYYY-MM-DD').toDate(),
  },
  {
    id: '5725a680606588342058356d',
    name: 'Arnold',
    clinicName: 'Wanderer',
    company: 'Laotcone',
    treatment: 'outpatient',
    claimAmount: 55.56,
    claimableAmount: 20.0,
    claimStatus: 'Approved',
    remark: '',
    claimDate: moment('2019-02-13', 'YYYY-MM-DD').toDate(),
    createdAt: moment('2019-02-13', 'YYYY-MM-DD').toDate(),
    updatedAt: moment('2019-02-13', 'YYYY-MM-DD').toDate(),
  },
  {
    id: '5725a68009e20d0a9e9acf2a',
    name: 'Barrera',
    clinicName: 'Jackal',
    company: 'Unizim',
    treatment: 'outpatient',
    claimAmount: 55.56,
    claimableAmount: 20.0,
    claimStatus: 'Rejected',
    remark: '',
    claimDate: moment('2019-03-03', 'YYYY-MM-DD').toDate(),
    createdAt: moment('2019-03-03', 'YYYY-MM-DD').toDate(),
    updatedAt: moment('2019-03-03', 'YYYY-MM-DD').toDate(),
  },
  {
    id: '5725a6809fdd915739187ed5',
    name: 'Blair',
    clinicName: 'Knight',
    company: 'Conedubdax',
    treatment: 'outpatient',
    claimAmount: 55.56,
    claimableAmount: 20.0,
    claimStatus: 'Settled',
    remark: '',
    claimDate: moment('2019-04-30', 'YYYY-MM-DD').toDate(),
    createdAt: moment('2019-04-30', 'YYYY-MM-DD').toDate(),
    updatedAt: moment('2019-04-30', 'YYYY-MM-DD').toDate(),
  },
  {
    id: '5725a68007920cf75051da64',
    name: 'Boyle',
    clinicName: 'Jester',
    company: 'Newo',
    treatment: 'outpatient',
    claimAmount: 55.56,
    claimableAmount: 20.0,
    claimStatus: 'Withdraw',
    remark: '',
    claimDate: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
    createdAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
    updatedAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  },
  {
    id: '5725a68031fdbb1db2c1af47',
    name: 'Christy',
    clinicName: 'Jester',
    company: 'uniway',
    treatment: 'outpatient',
    claimAmount: 20.0,
    claimableAmount: 20.0,
    claimStatus: 'New',
    remark: '',
    claimDate: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
    createdAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
    updatedAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  },
  {
    id: '5725a680bc670af746c435e2',
    name: 'Copeland',
    clinicName: 'Jester',
    company: 'Tempron',
    treatment: 'outpatient',
    claimAmount: 40.0,
    claimableAmount: 20.0,
    claimStatus: 'New',
    remark: '',
    claimDate: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
    createdAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
    updatedAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  },
  {
    id: '5725a680e7eb988a58ddf303',
    name: 'Estes',
    clinicName: 'Jester',
    company: 'nam-dex',
    treatment: 'outpatient',
    claimAmount: 80.0,
    claimableAmount: 20.0,
    claimStatus: 'New',
    remark: '',
    claimDate: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
    createdAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
    updatedAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  },
  {
    id: '5725a680dcb077889f758961',
    name: 'Harper',
    clinicName: 'Jester',
    company: 'runcane',
    treatment: 'outpatient',
    claimAmount: 100.0,
    claimableAmount: 20.0,
    claimStatus: 'New',
    remark: '',
    claimDate: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
    createdAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
    updatedAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  },
  {
    id: '5725a6806acf030f9341e925',
    name: 'Helen',
    clinicName: 'Jester',
    company: 'Subhow',
    treatment: 'outpatient',
    claimAmount: 120.0,
    claimableAmount: 20.0,
    claimStatus: 'New',
    remark: '',
    claimDate: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
    createdAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
    updatedAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  },
  {
    id: '5725a680ae1ae9a3c960d487',
    name: 'Henderson',
    clinicName: 'Jester',
    company: 'Howcom',
    treatment: 'outpatient',
    claimAmount: 50.0,
    claimableAmount: 20.0,
    claimStatus: 'New',
    remark: '',
    claimDate: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
    createdAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
    updatedAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  },
  {
    id: '5725a680b8d240c011dd224b',
    name: 'Josefina',
    clinicName: 'Jester',
    company: 'Gecko',
    treatment: 'outpatient',
    claimAmount: 30.0,
    claimableAmount: 20.0,
    claimStatus: 'New',
    remark: '',
    claimDate: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
    createdAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
    updatedAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  },
  {
    id: '5725a68034cb3968e1f79eac',
    name: 'Katina',
    clinicName: 'Jester',
    company: 'Lexicom',
    treatment: 'outpatient',
    claimAmount: 45.0,
    claimableAmount: 20.0,
    claimStatus: 'New',
    remark: '',
    claimDate: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
    createdAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
    updatedAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  },
  {
    id: '5725a6801146cce777df2a08',
    name: 'Lily',
    clinicName: 'Jester',
    company: 'zooflex',
    treatment: 'outpatient',
    claimAmount: 80.0,
    claimableAmount: 20.0,
    claimStatus: 'New',
    remark: '',
    claimDate: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
    createdAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
    updatedAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  },
  {
    id: '5725a6808a178bfd034d6ecf',
    name: 'Mai',
    clinicName: 'Jester',
    company: 'quadzone',
    treatment: 'outpatient',
    claimAmount: 60.0,
    claimableAmount: 20.0,
    claimStatus: 'Approved',
    remark: '',
    claimDate: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
    createdAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
    updatedAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  },
  {
    id: '5725a680653c265f5c79b5a9',
    name: 'Nancy',
    clinicName: 'Jester',
    company: 'Opetamnix',
    treatment: 'outpatient',
    claimAmount: 20.0,
    claimableAmount: 20.0,
    claimStatus: 'New',
    remark: '',
    claimDate: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
    createdAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
    updatedAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  },
  {
    id: '5725a680bbcec3cc32a8488a',
    name: 'Nora',
    clinicName: 'Jester',
    company: 'Saoway',
    treatment: 'outpatient',
    claimAmount: 20.0,
    claimableAmount: 20.0,
    claimStatus: 'New',
    remark: '',
    claimDate: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
    createdAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
    updatedAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  },
  {
    id: '5725a6803d87f1b77e17b62b',
    name: 'Odessa',
    clinicName: 'Jester',
    company: 'transace',
    treatment: 'outpatient',
    claimAmount: 20.0,
    claimableAmount: 20.0,
    claimStatus: 'New',
    remark: '',
    claimDate: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
    createdAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
    updatedAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  },
  {
    id: '5725a680e87cb319bd9bd673',
    name: 'Reyna',
    clinicName: 'Jester',
    company: 'Dingex',
    treatment: 'outpatient',
    claimAmount: 20.0,
    claimableAmount: 20.0,
    claimStatus: 'New',
    remark: '',
    claimDate: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
    createdAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
    updatedAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  },
  {
    id: '5725a6802d10e277a0f35775',
    name: 'Shauna',
    clinicName: 'Jester',
    company: 'Vivaflex',
    treatment: 'outpatient',
    claimAmount: 20.0,
    claimableAmount: 20.0,
    claimStatus: 'New',
    remark: '',
    claimDate: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
    createdAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
    updatedAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  },
  {
    id: '5725a680aef1e5cf26dd3d1f',
    name: 'Shepard',
    clinicName: 'Jester',
    company: 'Goldenla',
    treatment: 'outpatient',
    claimAmount: 20.0,
    claimableAmount: 20.0,
    claimStatus: 'New',
    remark: '',
    claimDate: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
    createdAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
    updatedAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  },
  {
    id: '5725a680cd7efa56a45aea5d',
    name: 'Tillman',
    clinicName: 'Jester',
    company: 'K-techno',
    treatment: 'outpatient',
    claimAmount: 20.0,
    claimableAmount: 20.0,
    claimStatus: 'New',
    remark: '',
    claimDate: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
    createdAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
    updatedAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  },
  {
    id: '5725a680fb65c91a82cb35e2',
    name: 'Trevino',
    clinicName: 'Jester',
    company: 'Dalthex',
    treatment: 'outpatient',
    claimAmount: 20.0,
    claimableAmount: 20.0,
    claimStatus: 'New',
    remark: '',
    claimDate: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
    createdAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
    updatedAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  },
  {
    id: '5725a68018c663044be49cbf',
    name: 'Tyson',
    clinicName: 'Jester',
    company: 'Geocon',
    treatment: 'outpatient',
    claimAmount: 20.0,
    claimableAmount: 20.0,
    claimStatus: 'New',
    remark: '',
    claimDate: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
    createdAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
    updatedAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  },
  {
    id: '5725a6809413bf8a0a5272b1',
    name: 'Velazquez',
    clinicName: 'Jester',
    company: 'ranex',
    treatment: 'outpatient',
    claimAmount: 20.0,
    claimableAmount: 20.0,
    claimStatus: 'New',
    remark: '',
    claimDate: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
    createdAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
    updatedAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  },

  {
    id: '1',
    name: 'Shepard',
    clinicName: 'Jester',
    company: 'Goldenla',
    treatment: 'outpatient',
    claimAmount: 20.0,
    claimableAmount: 20.0,
    claimStatus: 'New',
    remark: '',
    claimDate: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
    createdAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
    updatedAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  },
  {
    id: '2',
    name: 'Tillman',
    clinicName: 'Jester',
    company: 'K-techno',
    treatment: 'outpatient',
    claimAmount: 20.0,
    claimableAmount: 20.0,
    claimStatus: 'New',
    remark: '',
    claimDate: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
    createdAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
    updatedAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  },
  {
    id: '3',
    name: 'Trevino',
    clinicName: 'Jester',
    company: 'Dalthex',
    treatment: 'outpatient',
    claimAmount: 25.0,
    claimableAmount: 20.0,
    claimStatus: 'New',
    remark: '',
    claimDate: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
    createdAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
    updatedAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  },
  {
    id: '4',
    name: 'Tyson',
    clinicName: 'Jester',
    company: 'Geocon',
    treatment: 'outpatient',
    claimAmount: 21.0,
    claimableAmount: 20.0,
    claimStatus: 'New',
    remark: '',
    claimDate: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
    createdAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
    updatedAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  },
  {
    id: '5',
    name: 'Velazquez',
    clinicName: 'Jester',
    company: 'ranex',
    treatment: 'outpatient',
    claimAmount: 20.0,
    claimableAmount: 20.0,
    claimStatus: 'New',
    remark: '',
    claimDate: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
    createdAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
    updatedAt: moment('2019-05-20', 'YYYY-MM-DD').toDate(),
  },
];
