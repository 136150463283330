import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';

import { ModalHelper, ModalHelperOptions } from '@delon/theme';

import { Subscription } from 'rxjs';
import { Announcement, Attachment, ModalContent, PdfViewerOptions } from '../../models';
import { Biz360PdfviewerScrollableModalComponent } from '../pdfviewer-scrollable-modal/pdfviewer-scrollable-modal.component';

@Component({
  selector: 'announcement-detail-modal',
  templateUrl: 'detail.component.html',
  styleUrls: ['detail.component.less']
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class Biz360AnnouncementDetailModalComponent implements OnDestroy {
  @Input()
  selectedData?: Announcement;

  private subscriptions: Subscription[] = [];

  constructor(private modal: ModalHelper) {}

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  openAttachement(item: Attachment): void {
    const content: ModalContent = {
      contentType: item.modalType!,
      src: item.url!
    };

    const pdfViewerOptions: PdfViewerOptions = {
      zoomConfig: { defaultZoomLevel: 1 },
      zoom: true
    };

    const options: ModalHelperOptions = {
      size: 'lg',
      modalOptions: {
        nzKeyboard: false,
        nzMaskClosable: false,
        nzTitle: item.name,
        nzWrapClassName: 'vertical-center-modal'
      }
    };

    this.subscriptions.push(this.modal.create(Biz360PdfviewerScrollableModalComponent, { content, pdfViewerOptions }, options).subscribe());
  }
}
