import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';

import { PDFDocumentProxy } from 'ng2-pdf-viewer';
import { ModalContent, PdfViewerOptions } from '../../models';
import { ScrollbarDirective } from '../scrollbar/scrollbar.directive';

@Component({
  selector: 'pdfviewer-scrollable-modal',
  templateUrl: 'pdfviewer-scrollable-modal.component.html',
  styleUrls: ['pdfviewer-scrollable-modal.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class Biz360PdfviewerScrollableModalComponent implements OnInit {
  @ViewChild(ScrollbarDirective) scrollbar!: ScrollbarDirective;

  @Input()
  content?: ModalContent;

  @Input()
  pdfViewerOptions?: PdfViewerOptions;

  isLoaded = false;
  page = 0;
  totalPages!: number;
  zoom!: number;

  private minZoom = 0.5;
  private maxZoom = 2;
  private defaultZoomLevel = 1;
  private zoomStep = 0.25;

  constructor() {}

  ngOnInit(): void {
    if (this.pdfViewerOptions?.zoomConfig) {
      const { minZoom, maxZoom, defaultZoomLevel, zoomStep } = this.pdfViewerOptions.zoomConfig;
      this.minZoom = minZoom ? minZoom : this.minZoom;
      this.maxZoom = maxZoom ? maxZoom : this.maxZoom;
      this.zoomStep = zoomStep ? zoomStep : this.zoomStep;
      this.defaultZoomLevel = defaultZoomLevel ? defaultZoomLevel : this.defaultZoomLevel;
    }
    this.zoom = this.defaultZoomLevel;
  }

  toggleModalFooter(contentType: number): void {
    setTimeout(() => {
      return contentType !== 0;
    }, 0);
  }

  afterLoadComplete(pdfData: PDFDocumentProxy): void {
    this.totalPages = pdfData.numPages;
    this.isLoaded = true;
  }

  pageRendered(): void {
    // if (this.scrollbar) {
    //   this.scrollbar.setWrapHeight();
    // }
    if (this.page < 1) {
      if (this.pdfViewerOptions?.pageConfig) {
        const { initialPage } = this.pdfViewerOptions.pageConfig;
        this.page = initialPage!;

        if (this.scrollbar) {
          this.scrollbar.setWrapHeight();
        }
      }
    }
  }

  onPdfViewerError(event: any): void {
    console.log(`ERROR, ${event}`);
  }

  nextPage(): void {
    if (this.pdfViewerOptions?.pageConfig) {
      const { initialPage, startPage, endPage } = this.pdfViewerOptions.pageConfig;
      console.log('BEFOR NEXT::', initialPage, startPage, endPage, this.page);
      if (endPage && endPage > this.page) {
        this.page++;
      }

      return;
    }

    this.page++;
  }

  prevPage(): void {
    if (this.pdfViewerOptions?.pageConfig) {
      const { initialPage, startPage, endPage } = this.pdfViewerOptions.pageConfig;
      console.log('BEFOR PREV::', initialPage, startPage, endPage, this.page);
      if (startPage && startPage < this.page) {
        this.page--;
      }
      // console.log('BEFOR PREV::', initialPage, startPage, endPage, this.page);
      return;
    }

    this.page--;
  }

  zoomIn(): void {
    if (this.zoom < this.maxZoom) {
      this.zoom += this.zoomStep;
    }
  }

  zoomOut(): void {
    if (this.zoom > this.minZoom) {
      this.zoom -= this.zoomStep;
    }
  }
}
