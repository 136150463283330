import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { BrandService } from '@brand';
import { SFSchemaEnum } from '@delon/form';
import { format } from 'date-fns';
import { NzSelectComponent } from 'ng-zorro-antd/select';

import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { OPERATOR_LIST } from '../../constants';

@Component({
  selector: 'filter-search',
  templateUrl: 'filter-search.component.html',
  styleUrls: ['filter-search.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DatePipe]
})
export class Biz360FilterSearchComponent implements OnInit {
  get isMobile(): boolean {
    return this.srv.isMobile;
  }

  @Input()
  set tags(value: any[]) {
    if (value && value.length > 0) {
      this._tags = value;
    }
  }
  get tags(): any[] {
    return this._tags;
  }

  @Input()
  set optionList(value: any[]) {
    if (value && value.length > 0) {
      this.listItems = value;
    }
  }
  get optionList(): any[] {
    return this.listItems;
  }

  @Output() readonly tagsChanged = new EventEmitter<any[]>();
  @Output() readonly selectSearchChanged = new EventEmitter<any>();
  @Output() readonly selectScrollToBottom = new EventEmitter<string>();
  @Output() readonly search = new EventEmitter<any>();

  selectedOption: any;

  operatorList = OPERATOR_LIST;
  selectedOperator: { label: string; value: string } | null = null;
  checkItHasOperators = false;
  selectedValue!: null;

  private _tags: any[] = [];
  private listItems: any[] = [];
  private subscriptions: Subscription[] = [];

  compareFn = (o1: any, o2: any) => (o1 && o2 ? o1 === o2 : o1 === o2);

  constructor(private datePipe: DatePipe, private srv: BrandService, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.subscriptions.push(this.srv.notify.pipe(filter(t => t === 'mobile')).subscribe(() => this.cdr.detectChanges()));
  }

  onSelectedValueChange(event: any): void {
    this.selectedValue = null;

    const { type, control } = event;
    if (type === 'number' || type === 'datePicker') {
      this.checkItHasOperators = true;
      // @ check if custom operators available
      if (control?.operatorList) {
        this.operatorList = control.operatorList;
      } else {
        this.operatorList = OPERATOR_LIST;
      }
      this.selectedOperator = this.operatorList[0];
    } else {
      this.checkItHasOperators = false;
    }
    console.log(type, this.checkItHasOperators, this.selectedOperator);
  }

  onDatePickerChange(event: any): void {
    console.log(event);
  }

  onTimePickerChange(event: any): void {
    console.log(event);
  }

  addTag(event: any): void {
    console.log(this.selectedValue);
    // @ validate empty value
    if (!this.selectedOption || !this.selectedValue) {
      event.preventDefault();
      event.stopPropagation();
      console.log('Not valid!');
      return;
    }

    const newTag = this.formatTag(this.selectedOption, this.selectedOperator?.label!, this.selectedValue);
    this.tags.push(newTag);
    console.log(this.tags);

    this.selectedOption = null;
    this.selectedValue = null;
    this.selectedOperator = null;
    this.tagsChanged.emit(this.tags);
  }

  removeTags(event: any): void {
    this.tags = [];
    this.tagsChanged.emit(undefined);
  }

  handleClose(event: any): void {
    const items = [...this.tags];
    const index = items.indexOf(event);
    if (index !== -1) {
      this.tags = [...items.splice(index, 1)];
    }
    console.log(this.tags);
  }

  handleSearch(event: any): void {
    console.log(this.tags);
    const items = [...this.tags];

    if (items.length === 0) {
      this.search.emit(null);
      return;
    }

    // const searchObject: any = {};
    // items.forEach((tag) => {
    //   searchObject[tag.text] = tag.queryParams;
    // });

    const queryString: string[] = [];
    items.forEach(tag => {
      const check = items.filter((t: any) => t.queryParams.fieldName === tag.queryParams.fieldName);
      console.log(check, tag);

      const query = check.findIndex(t => t === tag) > 0 ? `'${tag.query}` : tag.query;
      queryString.push(query);
    });
    if (items.length > 0) {
      this.search.emit(queryString.join(','));
    }
  }

  onSelectModelChange(component: NzSelectComponent, selectedControl: any): void {
    // console.log(component, selectedControl);
    const { listOfTopItem, activatedValue } = component;

    const selectedObject = listOfTopItem.find(item => item.nzValue === activatedValue);

    selectedControl.control.selectedOption = { label: selectedObject?.nzLabel, value: selectedObject?.nzValue };
  }

  onSelectSearch(fieldName: string, value: string): void {
    this.selectSearchChanged.emit({ fieldName, value });
  }

  onSelectScrollToBottom(fieldName: string): void {
    this.selectScrollToBottom.emit(fieldName);
  }

  private formatTag(option: any, operator: string, selectedValue: any): any {
    const { label, fieldName, type, control } = option;

    let text: string;
    let query: string;
    switch (type) {
      case 'datePicker':
        text = `${label} ${operator || ':'} ${this.datePipe.transform(selectedValue, control?.dateFormat)}`;
        // tslint:disable-next-line: quotemark
        query = `${fieldName}${operator}${format(new Date(selectedValue), "yyyy-MM-dd'T'HH:mm:ss")}`;
        break;

      case 'timePicker':
        text = `${label} ${operator || ':'} ${this.datePipe.transform(selectedValue, control?.timeFormat)}`;
        query = `${fieldName}${operator}${selectedValue}`;
        break;

      case 'select':
        text = `${label} ${operator || ':'} ${control?.selectedOption?.label}`;
        query = `${fieldName}${operator || ':'}${selectedValue}`;
        break;

      default:
        text = `${label} ${operator || ':'} ${selectedValue}`;
        query = `${fieldName}${operator || ':'}${selectedValue}`;
        break;
    }

    const newTag = { text, query, queryParams: { fieldName, operator, value: selectedValue } };

    return newTag;
  }
}
