import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { _HttpClient } from '@delon/theme';
import { BehaviorSubject, Observable } from 'rxjs';
import { DepartmentStructureService } from './department-structure.service';

@Injectable()
export class DepartmentStructureListService implements Resolve<any> {
  data: any[] = [];
  onDataChanged: BehaviorSubject<any>;

  constructor(private http: _HttpClient, private departmentStructureService: DepartmentStructureService) {
    this.onDataChanged = new BehaviorSubject({});
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return new Promise((resolve, reject) => {
      Promise.all([this.getListing()]).then(res => {
        resolve(res);
      }, reject);
    });
  }

  getListing(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get('/organisation').subscribe((response: any) => {
        this.data = response;
        this.onDataChanged.next(this.data);
        this.departmentStructureService.onItemChanged.next(this.data[0]);
        resolve(response);
      }, reject);
    });
  }
}
