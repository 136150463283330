import { getCountryCallingCode } from 'libphonenumber-js/min';

export const COUNTRIES = [
  { name: 'Singapore', country: 'SG', countryCode: getCountryCallingCode('SG').toString() },
  { name: 'Malaysia', country: 'MY', countryCode: getCountryCallingCode('MY').toString() }
];

export const COMPANY_LIST = [
  { value: '001', label: 'Shaw Theatres Pte Ltd' },
  { value: '002', label: 'Shaw Foundation Pte Ltd' },
  { value: '003', label: 'Shaw Renters (Singapore) Pte Ltd' },
  { value: '004', label: 'Shaw House Pte Ltd' },
  { value: '005', label: 'Shaw Brothers Pte Ltd' },
  { value: '006', label: 'Shaw Concessions Pte Ltd' },
  { value: '007', label: 'Management Corp. Strata Titl No. 617' },
  { value: '008', label: 'Shaw Services Pte Ltd' },
  { value: '009', label: 'Management Corp. Strata Titl No. 2924' }
];
