import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AlainThemeModule } from '@delon/theme';
import { NzButtonModule } from 'ng-zorro-antd/button';

import { CaptchaBtnComponent } from './captcha-btn.component';

const COMPONENTS = [CaptchaBtnComponent];

@NgModule({
  imports: [CommonModule, AlainThemeModule.forChild(), NzButtonModule],
  declarations: COMPONENTS,
  exports: COMPONENTS
})
export class CaptchaBtnModule {}
