import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { UserIdleService } from 'angular-user-idle';
import { AuthenticationDetail, AuthenticationService, AuthenticationStatusDetail, AuthorizationService } from 'build/api/api-gateway';
import { Observable, of, Subject } from 'rxjs';
import { catchError, filter, map, shareReplay, tap } from 'rxjs/operators';
import { StateStorageService } from './state-storage.service';

interface Event {
  key: string;
  value: any;
}

@Injectable({ providedIn: 'root' })
export class AuthService implements OnDestroy {
  private authStatus!: AuthenticationStatusDetail;
  private userIdentity!: AuthenticationDetail;
  private authenticated: boolean = false;
  private authenticationState = new Subject<any>();
  private authStatusCache$!: Observable<AuthenticationStatusDetail>;
  private unsubscribe$: Subject<any> = new Subject();
  protected eventsSubject = new Subject<Event>();

  constructor(
    private http: HttpClient,
    private stateStorageService: StateStorageService,
    private userIdle: UserIdleService,
    private location: Location,
    private router: Router,
    protected authenticationService: AuthenticationService,
    protected authorizationService: AuthorizationService
  ) {}

  fetchStatus(): Observable<AuthenticationStatusDetail> {
    return this.authenticationService.getStatus();
  }

  fetchAccount(): Observable<AuthenticationDetail> {
    return this.authorizationService.getCurrent();
  }

  identity(force?: boolean): Observable<AuthenticationStatusDetail> {
    if (force || !this.authenticated) {
      this.authStatusCache$ = null!;
    } else {
      this.userIdle.resetTimer();
    }

    if (!this.authStatusCache$) {
      this.authStatusCache$ = this.fetchStatus().pipe(
        catchError(() => of(null!)),
        tap((authStatusDetail: AuthenticationStatusDetail) => {
          this.authStatus = authStatusDetail;
          if (authStatusDetail) {
            this.stateStorageService.storeAuthStatus(authStatusDetail);

            this.authenticated = authStatusDetail.status === 'AUTHENTICATED' ? true : false;

            if (this.authenticated) {
              this.fetchAccount().subscribe((account: AuthenticationDetail) => {
                this.userIdentity = account;
                this.authenticationState.next(this.userIdentity);
              });
            }
          }
        }),
        shareReplay()
      );
    }
    return this.authStatusCache$;
  }

  isAuthenticated(): boolean {
    return this.authenticated;
  }

  isIdentityResolved(): boolean {
    return this.userIdentity !== undefined;
  }

  getUserIdentity(): AuthenticationDetail {
    return this.userIdentity;
  }

  getAuthStatus(): AuthenticationStatusDetail {
    return this.authStatus;
  }

  getAuthenticationState(): Observable<any> {
    return this.authenticationState.asObservable();
  }

  routeToLoginPage(): void {
    this.router.navigate(['/account/login'], { queryParams: { next: `${this.stateStorageService.getStateUrl()}` } });
  }

  loginPageUrl(): string {
    return `${location.origin}${this.location.prepareExternalUrl('/account/login')}`;
  }

  getCookie(name: string) {
    if (!document.cookie) {
      return null;
    }

    const xsrfCookies = document.cookie
      .split(';')
      .map(c => c.trim())
      .filter(c => c.startsWith(`${name}=`));

    if (xsrfCookies.length === 0) {
      return null;
    }
    return decodeURIComponent(xsrfCookies[0].split('=')[1]);
  }

  public BroadcastEvent(key: string, value: any) {
    this.eventsSubject.next({ key, value });
  }

  public GetEvent(key: string): Observable<any> {
    return this.eventsSubject.asObservable().pipe(
      filter(e => e.key === key),
      map(e => e.value)
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
