import { Injectable, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MSLayoutComponent } from '@brand';
import { SimpleGuard } from '@delon/auth';
import { UserRouteAccessService } from '@oauth';

import { LeaveService } from 'src/app/shared/services';
import { OrganisationLayoutComponent } from './_layout/layout.component';
import { LeaveCalendarComponent } from './leave-calendar/leave-calendar.component';
import { OrganisationStructureComponent } from './organisation-structure/organisation-structure.component';
import { PoliciesComponent } from './policies/policies.component';
import { DepartmentStructureListService, DepartmentStructureService } from './shared/services';

const routes: Routes = [
  {
    path: 'organisation',
    component: MSLayoutComponent,
    canActivate: [UserRouteAccessService],
    children: [
      {
        path: '',
        component: OrganisationLayoutComponent,
        children: [
          { path: '', redirectTo: 'organisation-structure', pathMatch: 'full' },
          { path: 'policies', component: PoliciesComponent },
          {
            path: 'organisation-structure',
            component: OrganisationStructureComponent,
            resolve: { data: DepartmentStructureListService },
            children: [
              {
                path: ':id',
                component: OrganisationStructureComponent,
                resolve: { data: DepartmentStructureService }
              }
            ]
          },
          {
            path: 'leave-calendar',
            component: LeaveCalendarComponent,
            resolve: { data: LeaveService },
            data: { weekly: true, monthly: false }
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OrganisationRoutingModule {}
