import {
  FilmRentalExpenseInvoiceDataDetail,
  FilmRentalExpenseInvoiceDataLineItemDetail,
  FilmRentalExpenseInvoiceDataLocationScreensDetail,
  FilmRentalExpenseInvoiceDataScreenDetail
} from 'build/api/film-rental';
import moment from 'moment';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { Option } from '../models';

export class Biz360Utils {
  public static hasErrorInInvoice(data: FilmRentalExpenseInvoiceDataDetail): boolean {
    return data?.exhibitor === null || data?.lines?.filter(this.hasErrorInInvoiceLineItem.bind(this)).length > 0;
  }

  public static hasErrorInInvoiceLineItem(data: FilmRentalExpenseInvoiceDataLineItemDetail): boolean {
    return this.hasFilmErrorInInvoiceLineItem(data) || data?.locationScreens?.filter(this.hasErrorInLocationScreens.bind(this)).length > 0;
  }

  public static hasFilmErrorInInvoiceLineItem(data?: FilmRentalExpenseInvoiceDataLineItemDetail): boolean {
    return data?.film === null;
  }

  public static hasErrorInLocationScreens(data: FilmRentalExpenseInvoiceDataLocationScreensDetail): boolean {
    return data?.location === null || data.screens?.filter(this.hasErrorInScreen.bind(this)).length > 0;
  }

  public static hasErrorInScreen(data: FilmRentalExpenseInvoiceDataScreenDetail): boolean {
    return data?.screen === null;
  }

  public static beforeUpload(file: NzUploadFile, fileList: NzUploadFile[]): boolean {
    console.log(file);
    console.log(fileList);

    return false;
  }

  public static enumToOptionList(values: any): Option[] {
    return Object.keys(values)
      .map(key => ({ label: values[key], value: values[key] }))
      .sort((a, b) => (a.value > b.value ? 1 : -1));
  }

  public static objArrayToOptionList(objArray: any[], prop: string | string[]): Option[];
  public static objArrayToOptionList(objArray: any[], prop: any): Option[] {
    if (Array.isArray(prop)) {
      return objArray.map(o => ({ label: o[prop[0]], value: prop[1] === '.' ? o : o[prop[1]] }));
    }
    return objArray.map(o => ({ label: o[prop], value: o[prop] }));
  }

  public static compareStringFn(s1: string, s2: string, returnBoolean: boolean = true): number | boolean {
    if (returnBoolean) {
      return s1 && s2 ? s1.toLowerCase() === s2.toLowerCase() : s1 === s2;
    }
    return s1.toLowerCase().localeCompare(s2.toLowerCase());
  }

  public static compareNumberFn(n1: number, n2: number): number {
    return n1 > n2 ? 1 : n1 === n2 ? 0 : -1;
  }

  public static filterArrayByStringByProperty(mainArr: any[], searchText: string, prop: string): any[] {
    if (searchText === '') {
      return mainArr;
    }

    searchText = searchText.toLowerCase();

    return mainArr.filter((itemObj: any) => this.searchInObjByProperty(itemObj, searchText, prop));
  }

  public static searchInObjByProperty(
    itemObj: { [x: string]: any; hasOwnProperty: (arg0: any) => any },
    searchText: any,
    prop: string
  ): any {
    if (!itemObj.hasOwnProperty(prop)) {
      return false;
    }

    const value = itemObj[prop];

    if (typeof value === 'string') {
      if (this.searchInString(value, searchText)) {
        return true;
      }
    } else if (Array.isArray(value)) {
      if (this.filterArrayByStringByProperty(value, searchText, prop)) {
        return true;
      }
    }

    if (typeof value === 'object') {
      if (this.searchInObjByProperty(value, searchText, prop)) {
        return true;
      }
    }
  }

  public static filterArrayByString(mainArr: any[], searchText: string): any {
    if (searchText === '') {
      return mainArr;
    }

    searchText = searchText.toLowerCase();

    return mainArr.filter((itemObj: any) => this.searchInObj(itemObj, searchText));
  }

  public static searchInObj(itemObj: { [x: string]: any; hasOwnProperty: (arg0: string) => any }, searchText: any): any {
    for (const prop in itemObj) {
      if (!itemObj.hasOwnProperty(prop)) {
        continue;
      }

      const value = itemObj[prop];

      if (typeof value === 'string') {
        if (this.searchInString(value, searchText)) {
          return true;
        }
      } else if (Array.isArray(value)) {
        if (this.searchInArray(value, searchText)) {
          return true;
        }
      }

      if (typeof value === 'object') {
        if (this.searchInObj(value, searchText)) {
          return true;
        }
      }
    }
  }

  public static searchInArray(arr: any[], searchText: any): any {
    for (const value of arr) {
      if (typeof value === 'string') {
        if (this.searchInString(value, searchText)) {
          return true;
        }
      }

      if (typeof value === 'object') {
        if (this.searchInObj(value, searchText)) {
          return true;
        }
      }
    }
  }

  public static searchInArrayById(arr: any, id: any): any {
    for (const value of arr) {
      if (value.id === id) {
        return value;
      }
    }
    return null;
  }

  public static getRootFromUrl(url: string): string {
    const root = url.startsWith('/') ? url.split('/')[1] : url.split('/')[0];

    return root;
  }

  public static searchInString(value: string, searchText: any): any {
    return value.toLowerCase().includes(searchText);
  }

  public static generateGUID(): string {
    function S4(): string {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }

    return S4() + S4() + S4() + S4();
  }

  public static toggleInArray(item: any, array: any[]): void {
    if (array.indexOf(item) === -1) {
      array.push(item);
    } else {
      array.splice(array.indexOf(item), 1);
    }
  }

  public static handleize(text: { toString: () => string }): string {
    return text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(/[^\w\-]+/g, '') // Remove all non-word chars
      .replace(/\-\-+/g, '-') // Replace multiple - with single -
      .replace(/^-+/, '') // Trim - from start of text
      .replace(/-+$/, ''); // Trim - from end of text
  }

  public static daysInMonth(month: number, year: number): number {
    return new Date(year, month, 0).getDate();
  }

  public static flatten(array: any[]): any[] {
    let result: any[] = [];
    array.map((x: { childs: any }) => {
      result.push(x);
      if (Array.isArray(x.childs)) {
        result = result.concat(this.flatten(x.childs));
      }
    });

    return result;
  }

  public static pad(num: number, size: number): string {
    let s = `${num}`;
    while (s.length < size) {
      s = `0${s}`;
    }
    return s;
  }

  public static getMonthName(monthNumber: number): string {
    return moment(this.pad(monthNumber, 2), 'MM').format('MMMM');
  }

  public static generateRowIds(rows: any[]): any[] {
    const data: any[] = [];
    rows.forEach((row, index) => {
      const keys = Object.keys(row);
      if (!keys.includes('id')) {
        const id = index + 1;
        data.push({ ...row, id });
      } else {
        data.push(row);
      }
    });

    return data;
  }

  public static getKeys(value: any): any[] {
    return Object.keys(value);
  }

  public static getValues(value: any): any[] {
    const keys = Object.keys(value);
    return keys.map(k => value[k]);
  }
}
