import { Injectable } from '@angular/core';
import { AuthenticationStatusDetail } from 'build/api/api-gateway';
import { SessionStorageService } from 'ngx-webstorage';

@Injectable({ providedIn: 'root' })
export class StateStorageService {
  constructor(private $sessionStorage: SessionStorageService) {}

  storeStateUrl(url: string): void {
    this.$sessionStorage.store('stateUrl', url);
  }

  getStateUrl(): string {
    return this.$sessionStorage.retrieve('stateUrl');
  }

  storeAuthStatus(authStatus: AuthenticationStatusDetail): void {
    this.$sessionStorage.store('authStatus', authStatus);
  }

  getAuthStatus(): AuthenticationStatusDetail {
    return this.$sessionStorage.retrieve('authStatus');
  }
}
